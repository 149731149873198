import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const CrudContext = createContext();

function CrudContextProvider({ children }) {
  const [crudLoading, setCrudLoading] = useState(false);

  /* CREATE METHOD */
  async function createMethod(url, data, token) {
    try {
      setCrudLoading(true);

      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.isError) {
        toast.error(response.data.errorMessageList[0], {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      } else {
        toast.success(response.data.data, {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      }

      return response.data;
    } catch (err) {
      toast.error(err.message, {
        autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
      });

      setCrudLoading(false);

      return { catchError: err };
    }
  }

  /* DELETE METHOD */
  async function deleteMethod(url, token) {
    try {
      setCrudLoading(true);

      const response = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          withCredentials: true,
        },
      });

      if (response.data.isError) {
        toast.error(response.data.errorMessageList[0], {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      } else {
        toast.success(response.data.data, {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      }

      return response.data;
    } catch (err) {
      toast.error(err.message, {
        autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
      });

      setCrudLoading(false);

      return { catchError: err };
    }
  }

  /* UPDATE METHOD */
  async function updateMethod(url, data, token) {
    try {
      setCrudLoading(true);

      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token} `,
          withCredentials: true,
        },
      });

      if (response.data.isError) {
        toast.error(response.data.errorMessageList[0], {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      } else {
        toast.success(response.data.data, {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });

        setCrudLoading(false);
      }

      return response.data;
    } catch (err) {
      toast.error(err.message, {
        autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
      });

      setCrudLoading(false);

      return { catchError: err };
    }
  }

  const values = {
    /* crud methods*/
    createMethod,
    deleteMethod,
    updateMethod,

    /* loading */
    crudLoading,
  };

  return <CrudContext.Provider value={values}>{children}</CrudContext.Provider>;
}

export const CrudContextValue = () => useContext(CrudContext);

export default CrudContextProvider;
