function NotificationIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18 14.2857H20V16.1905H0V14.2857H2V7.61905C2 5.59835 2.84285 3.66042 4.34315 2.23157C5.84344 0.802719 7.87827 0 10 0C12.1217 0 14.1566 0.802719 15.6569 2.23157C17.1571 3.66042 18 5.59835 18 7.61905V14.2857ZM7 18.0952H13V20H7V18.0952Z"
            />
        </svg>

    )
}

export default NotificationIcon;
