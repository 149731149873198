import React from "react";
import { AuthContextValue } from "../../../context/AuthContext";
import useFetch from "../../../hooks/UseFetch";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";

function CompanyApplications() {
  const { token } = AuthContextValue();

  const {
    setUseFetchRender: setCompaniesApplyingUseFetchRender,
    useFetchRender: companiesApplyingUseFetchRender,
    error: companiesApplyingError,
    loading: companiesApplyingLoading,
    data: companiesApplying,
    total: companiesApplyingTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_COMPANIES_APPLYING_URL
    }?page=${1}&pageSize=${5}`,
    token
  );

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-calendar-check"></i>
          <h5>Başvuran Firmalar</h5>
        </div>
        <div className="card-buttons">
          <Link to={"/companies-applying"}>
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {companiesApplyingError ? (
            <ErrorComponent error={companiesApplyingError} />
          ) : companiesApplyingLoading ? (
            <Loading />
          ) : companiesApplying !== null && companiesApplying.length > 0 ? (
            companiesApplying.map((company) => {
              return (
                <li key={company.invitedId}>
                  <p>{company.invitedCompanyName}</p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">Başvuru yapan firma yok!</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default CompanyApplications;
