import "./styles/tenderAdd.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import TenderAddStepOne from "./TenderAddStepOne";
import TenderAddStepTwo from "./TenderAddStepTwo";
import { CrudContextValue } from "../../../context/CrudContext";
import { AuthContextValue } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { GetContextValue } from "../../../context/GetContext";
import { DemoContextValue } from "../../../context/DemoContext";

function TenderAdd() {
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { token } = AuthContextValue();
  const { mySuppliers } = GetContextValue();
  const { createMethod } = CrudContextValue();
  const { demoSuppliers, demoTenders, setDemoTenders } = DemoContextValue();

  const [addTenderLoading, setAddTenderLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handlePrev = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    setFormData({ ...formData, ...data });

    console.log({ ...formData, ...data });

    setAddTenderLoading(true);

    const newTender = { ...formData, ...data };

    const response = await createMethod(
      process.env.REACT_APP_POST_ADD_TENDER_URL,
      JSON.stringify(newTender),
      token
    );

    if (response) {
      toast.info("İhale sayfasına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/tenders");
      }, 3000);
    }

    setAddTenderLoading(false);

    setStep(1);
    setFormData({});
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function demoAddTender(data) {
    console.log({ ...formData, ...data });

    setAddTenderLoading(true);

    const newTender = {
      ...formData,
      ...data,
      tenderId: demoTenders !== null ? demoTenders.length + 1 : 1,
      status: "1",
      images: [
        "https://avatars.mds.yandex.net/i?id=933035d50285716d6fdbbfd6a3d66fb4937e242a-9044992-images-thumbs&n=13",
      ],
      tenderNumber: "1000012",
      categories: [{ categoryId: 139, categoryName: "Afiş" }],
      createdAt: new Date(),
    };

    if (demoTenders) {
      setDemoTenders([...demoTenders, newTender]);
    } else {
      setDemoTenders([newTender]);
    }

    toast.success("İhale oluşturuldu", {
      autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
    });
    toast.info("İhale sayfasına yönlendiriliyorsunuz", {
      autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
    });

    setTimeout(() => {
      navigate("/tenders");
    }, 3000);

    setAddTenderLoading(false);

    setStep(1);
    setFormData({});
  }

  return (
    <>
      {mySuppliers === null && (
        <div className="no-supplier">
          <p className="no-supplier-text">
            Hiç tedarikçiniz yok! İhale oluşturabilmek için lütfen
            tedarikçilerinizi ekleyiniz.
          </p>

          <div className="no-supplier-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-store"></i>}
              text={"Tedarikçiler"}
              onClick={() => navigate("/suppliers")}
            />
            <WhiteButton
              icon={<i className="fa-solid fa-xmark"></i>}
              text={"Vazgeç"}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      )}
      <div
        className={`tender-add  ${
          mySuppliers === null ? "no-supplier-tender-add" : null
        }`}
      >
        {windowWidth < 1028 ? (
          <>
            {step === 1 ? (
              <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                <TenderAddStepOne
                  onNext={handleNext}
                  formData={formData}
                  step={step}
                />
              </div>
            ) : step === 2 ? (
              <div
                className={`step-two ${
                  step !== 2 || addTenderLoading ? "disabled" : ""
                }`}
              >
                <TenderAddStepTwo
                  onPrev={handlePrev}
                  onSubmit={handleSubmit}
                  formData={formData}
                  setFormData={setFormData}
                  step={step}
                  setStep={setStep}
                />
              </div>
            ) : (
              "null"
            )}
          </>
        ) : (
          <>
            <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
              <TenderAddStepOne
                onNext={handleNext}
                formData={formData}
                step={step}
              />
            </div>
            <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
              <TenderAddStepTwo
                onPrev={handlePrev}
                onSubmit={handleSubmit}
                // onSubmit={demoAddTender}
                formData={formData}
                setFormData={setFormData}
                step={step}
                setStep={setStep}
                addTenderLoading={addTenderLoading}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TenderAdd;
