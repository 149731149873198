import "./styles/editBox.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import Select from "../form_components/Select/Select";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { tenderTimeOverCalcFunc } from "../../hooks/tenderTimeOver";
// import { Select } from "antd";

function OfferEditBox({
  t,

  type,

  pageLink,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenConfirmModal,
  handleOpenRevisedModal,
  handleOpenResultRevisedNoteModal,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */

  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* DROPDOWN-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "revised":
        console.log("Revize ye tıklandı!");

        handleOpenRevisedModal();

        break;

      case "confirm":
        console.log("Onayla ya tıklandı!");

        handleOpenConfirmModal();

        setSearchOpen(false);

        break;
      case "result-revised-note":
        console.log("result-revised-note a tıklandı!");

        handleOpenResultRevisedNoteModal();

        setSearchOpen(false);

        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: 1, text: "Artan Fiyat" },
    { key: 2, text: "Azalan Fiyat" },
    { key: 3, text: "En Yeniden Eskiye" },
    { key: 4, text: "En Eskiden Yeniye" },
  ];

  const selectOptionsAntD = [
    { value: 1, label: "Artan Fiyat" },
    { value: 2, label: "Azalan Fiyat" },
    { value: 3, label: "En Yeniden Eskiye" },
    { value: 4, label: "En Eskiden Yeniye" },
  ];
  
  return (
    <div className="edit-container">
      <div className="edit-box">
        <div className="edit-container-title">
          <h4 className="title">
            {" "}
            <i className="fa-solid fa-star" /> Fırsatlar
          </h4>
        </div>

        <div className="edit-container-button-group">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-right" />}
            tooltip={"Yenile"}
            onClick={() => handleClick("render")}
          />

          <WhiteButton
            icon={
              isSearchOpen ? (
                <div ref={searchRef} className="search-container">
                  <i className="fa-solid fa-magnifying-glass" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              ) : (
                <i className="fa-solid fa-magnifying-glass" />
              )
            }
            tooltip={"Ara"}
            onClick={() => handleClick("search")}
          />

          <Select
            name="filter"
            options={selectOptions}
            select={tableFilter}
            setSelect={setTableFilter}
            className="select-filter-sort"
          />

          {/* <Select
            defaultValue={tableFilter}
            style={{ width: 120}}

            onChange={setTableFilter}
            options={selectOptionsAntD}
          /> */}

          <Link
            to={
              selectValueId || selectValue
                ? pageLink === "Onaylandı"
                  ? `tender-detail-supplier/${selectValueId}`
                  : pageLink === "offers"
                  ? `tender-detail-supplier/${selectValueId}`
                  : `tender-detail-offer/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              icon={<i className="fa-solid fa-info" />}
              tooltip={"Detay"}
              disabled={selectValueId || selectValue ? false : true}
            />
          </Link>

          {pageLink === "offers" && (
            <WhiteButton
              icon={<i className="fa-solid fa-note-sticky"></i>}
              tooltip={"Revize Notu"}
              onClick={() => handleClick("result-revised-note")}
              disabled={selectValueId || selectValue ? false : true}
            />
          )}

          {pageLink !== "Revize" &&
            pageLink !== "offers" &&
            (tenderTimeOverCalcFunc(
              new Date(selectValue?.expireDate) - new Date()
            ) === "overTime" ? null : (
              <WhiteButton
                icon={<i className="fa-solid fa-arrows-rotate"></i>}
                tooltip={"Revize İste"}
                onClick={() => handleClick("revised")}
                disabled={selectValueId || selectValue ? false : true}
              />
            ))}

          {pageLink === "OnayBekliyor" &&
            (tenderTimeOverCalcFunc(
              new Date(selectValue?.expireDate) - new Date()
            ) === "overTime" ? null : (
              <WhiteButton
                icon={<i className="fa-solid fa-check"></i>}
                tooltip={"İhaleye Katıl"}
                onClick={() => handleClick("confirm")}
                disabled={selectValueId || selectValue ? false : true}
              />
            ))}
          {pageLink === "Onaylandı" &&
            (tenderTimeOverCalcFunc(
              new Date(selectValue?.expireDate) - new Date()
            ) === "overTime" ? null : (
              <Link
                to={
                  selectValue || selectValueId
                    ? `tender-detail-offer/${selectValueId}`
                    : false
                }
              >
                <WhiteButton
                  icon={<i className="fa-solid fa-check"></i>}
                  tooltip={"Teklif Gönder"}
                  onClick={() => handleClick("confirm")}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(OfferEditBox);
