function StarIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.89016 17.514L5.68009 19.7711L5.58109 19.8151C4.86608 20.0851 4.19107 20.0311 3.67806 19.5731C3.19605 19.1431 3.02105 18.5271 3.12105 17.818L3.82506 12.9579L0.64501 9.61584C0.0950007 9.05583 -0.120003 8.36781 0.0650003 7.6478C0.270004 6.84878 0.945014 6.38976 1.91603 6.23576L6.1431 5.59774L8.35613 1.01263C8.70014 0.365617 9.23615 -0.0173929 9.91116 0.000607558C10.5712 0.017608 11.0942 0.422618 11.5042 1.14364L13.6442 5.62975L18.3843 6.28776C19.1373 6.41777 19.6923 6.80977 19.9143 7.46379C20.1343 8.11681 19.9243 8.77682 19.3573 9.45084L15.9173 12.9609L16.6893 17.817C16.8113 18.6571 16.6643 19.3221 16.1033 19.7171C15.5972 20.0741 14.9642 20.0741 14.2362 19.8241L14.1162 19.7711L9.89016 17.514Z"
            />
        </svg>

    )
}

export default StarIcon;
