import "./styles/tenderEdit.css";

import React, { useEffect, useState } from "react";
import { CrudContextValue } from "../../../context/CrudContext";
import { AuthContextValue } from "../../../context/AuthContext";
import TenderEditStepOne from "./TenderEditStepOne";
import TenderEditStepTwo from "./TenderEditStepTwo";
import useFetch from "../../../hooks/UseFetch";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}

function formatDate(dateValue) {
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return [year, month, day].join("-");
}

function TenderEdit() {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();

  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(1);

  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState();

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
    total: tenderDetailTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  useEffect(() => {
    if (tenderDetail && tenderDetail.minPrice) {
      setFormData({
        tenderId: parseInt(tenderId),
        categoryId: tenderDetail.categories,
        description: tenderDetail.description,
        productName: tenderDetail.productName,
        amount: tenderDetail.amount,
        minPrice: tenderDetail.minPrice.toLocaleString(),
        maxPrice: tenderDetail.maxPrice.toLocaleString(),
        currency: tenderDetail.currency,
        expireDate: formatDate(tenderDetail.expireDate),
        deadline: formatDate(tenderDetail.deadline),
        customer: tenderDetail.customer,
        unitType: tenderDetail.unitType,
        tenderCompanyId: tenderDetail.companies,
        images: tenderDetail.imagePaths,
        note: tenderDetail.note,
      });

      if (formData.categoryId) {
        const newCategoryId = formData.categoryId.map((category) => ({
          label: category.categoryName,
          value: category.categoryId,
        }));
        setSelectedCategories(newCategoryId);
      }

      /* images */
      if (formData.images) {
        let filterImg = [];
        let filterDocument = [];

        formData.images.map((img) => {
          if (img.includes("pdf")) {
            filterDocument.push(img);
          } else {
            filterImg.push(img);
          }
        });

        setImages(filterImg.map((img) => ({ id: generateId(), url: img })));
        setDocuments(
          filterDocument.length > 0
            ? filterDocument.map((doc) => ({ id: generateId(), fileName: doc }))
            : []
        );
      }
    }
  }, [tenderDetail]);

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handlePrev = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    setFormData({ ...formData, ...data });

    console.log(data);

    // kategorilerin sadece idlerini göndermek için
    const newCategories = formData.categoryId.map((category) => {
      if (category.categoryId) {
        return category.categoryId;
      } else {
        return category;
      }
    });

    // firmaları sadece idlerini göndermek için
    // const newCompanies = formData.tenderCompanyId.map(
    //   (company) => company.companyId
    // );
    // console.log("newCompanies : ", newCompanies);
    const newTender = {
      ...formData,
      ...data,
      categoryId: newCategories,
      // tenderCompanyId: newCompanies,
    };

    console.log("Güncellenen ihale : ", newTender);

    const response = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_TENDER_URL,
      JSON.stringify(newTender),
      token
    );

    if (response && !response.catchError && !response.isError) {
      toast.info("İhale detayına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        // navigate(`/tenders/tender-detail/${tenderId}`);
        navigate(`/tenders`);
      }, 3000);
    }

    setStep(1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tender-add">
      {formData && formData.description && (
        <>
          {windowWidth < 1028 ? (
            <>
              {step === 1 ? (
                <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                  <TenderEditStepOne
                    onNext={handleNext}
                    formData={formData}
                    step={step}
                    images={images}
                    setImages={setImages}
                    documents={documents}
                    setDocuments={setDocuments}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                  />
                </div>
              ) : step === 2 ? (
                <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                  <TenderEditStepTwo
                    onPrev={handlePrev}
                    onSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                  />
                </div>
              ) : (
                "null"
              )}
            </>
          ) : (
            <>
              <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                <TenderEditStepOne
                  onNext={handleNext}
                  formData={formData}
                  step={step}
                  images={images}
                  setImages={setImages}
                  documents={documents}
                  setDocuments={setDocuments}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              </div>
              <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                <TenderEditStepTwo
                  onPrev={handlePrev}
                  onSubmit={handleSubmit}
                  formData={formData}
                  setFormData={setFormData}
                  step={step}
                  setStep={setStep}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TenderEdit;