import "../../styles/materiel/materiel.css";
import "./styles/opportunities.css";

import { Link, Outlet, useLocation } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import { useEffect, useState } from "react";
import OpportunitiesEditBox from "../../components/EditBox/OpportunitiesEditBox";
import { GetContextValue } from "../../context/GetContext";


function OpportunitiesLayout({ t }) {

    return (

        <div className="opportunities-layout">
                {/* Tablolar buraya gelicek */}
                <Outlet />
        </div>
    )
}

export default withNamespaces()(OpportunitiesLayout);
