import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function UserOffers({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [userOfferPage, setUserOfferPage] = useState(1);
  const [userOfferRowsPerPage, setUserOfferRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setUserOffersUseFetchRender,
    useFetchRender: userOffersUseFetchRender,
    error: userOfferError,
    loading: userOfferLoading,
    data: userOffer,
    total: userOfferTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferUserId=${id}&Last=false&SortType=1&Page=${userOfferPage}&PageSize=${userOfferRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: "",
    },
    {
      key: "offerCompanyCategoryName",
      header: t("table_category"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "materialPrice",
      header: t("table_materiel_price"),
    },
    {
      key: "laborPrice",
      header: t("table_labor_price"),
    },
    {
      key: "unitPrice",
      header: t("table_unit_price"),
    },
    {
      key: "totalPrice",
      header: "Toplam",
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setUserOffersUseFetchRender(!userOffersUseFetchRender);
  }, [tableRender, notificationRender]);

  console.log(userOffer);

  return (
    <>
      {userOfferError ? (
        <ErrorComponent error={userOfferError} />
      ) : userOfferLoading ? (
        <Loading />
      ) : userOffer !== null ? (
        <StandartTable
          type="offer"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={userOffersUseFetchRender}
          setUseFetchRender={setUserOffersUseFetchRender}
          total={userOfferTotal}
          page={userOfferPage}
          setPage={setUserOfferPage}
          rowsPerPage={userOfferRowsPerPage}
          setRowsPerPage={setUserOfferRowsPerPage}
          columns={columns}
          data={userOffer}
        />
      ) : (
        <div className="table-null-text">{t("message_user_no_offer")}</div>
      )}
    </>
  );
}

export default withNamespaces()(UserOffers);
