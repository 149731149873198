import React, { useState } from "react";
import InputStandart from "../../../components/form_components/Inputs/InputStandart/InputStandart";
import { GetContextValue } from "../../../context/GetContext";
import Select from "react-select";
import UploadImageBox from "../../../components/UploadImageBox/UploadImageBox";
import DocumentUploader from "../../../components/DocumanUploder/DocumanUploader";
import InputPrice from "../../../components/form_components/Inputs/InputPrice/InputPrice";

import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { useNavigate } from "react-router-dom";

function formatDate(date) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(date)
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");
  const [month, day, year] = formattedDate.split("-");
  const formattedDateString = `${year}-${month}-${day}T00:00:00.000Z`;
  return formattedDateString;
}

function TenderEditStepOne({
  onNext,
  formData,
  step,
  images,
  setImages,
  documents,
  setDocuments,
  selectedCategories,
  setSelectedCategories,
}) {
  const navigate = useNavigate();

  const { categoryList, categoryListLoading, categoryListError } =
    GetContextValue();

  const [stepOneForm, setStepOneForm] = useState({
    description: formData.description ? formData.description : "",
    productName: formData.productName ? formData.productName : "",
    currency: formData.currency ? formData.currency : "",
    amount: formData.amount ? formData.amount : 0,
    maxPrice: formData.maxPrice ? formData.maxPrice : 0,
    minPrice: formData.minPrice ? formData.minPrice : 0,
    expireDate: formData.expireDate ? formData.expireDate : "",
    deadline: formData.deadline ? formData.deadline : "",
    categoryId: formData.categoryId ? formData.categoryId : [0],
    unitType: formData.unitType ? formData.unitType : 1,
    customer: formData.customer ? formData.customer : "string",
    images: [],
    note: formData.note ? formData.note : "",
  });

  const categoryOptions = categoryList.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));

  const handleChangeCategory = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setStepOneForm({
      ...stepOneForm,
      categoryId: selectedOptions.map((opt) => opt.value),
    });
  };

  const unitTypeOptions = [
    { label: "Adet", value: 1 },
    { label: "Ton", value: 2 },
    { label: "Kilo", value: 3 },
    { label: "Gram", value: 4 },
    { label: "Metre", value: 5 },
    { label: "Santimetre", value: 6 },
    { label: "Milimetre", value: 7 },
    { label: "Litre", value: 8 },
    { label: "Inc", value: 9 },
  ];

  const [selectedUnitType, setSelectedUnitType] = useState(
    unitTypeOptions.filter((type) => type.value === stepOneForm.unitType)
  );

  const handleChangeUnitType = (selectedOptions) => {
    setSelectedUnitType(selectedOptions);
    setStepOneForm({
      ...stepOneForm,
      unitType: selectedOptions.value,
    });
  };

  const [currency, setCurrency] = useState(stepOneForm.currency); // default currency
  const [currencySymbol, setCurrencySymbol] = useState(
    stepOneForm.currency === "TRY"
      ? "₺"
      : stepOneForm.currency === "USD"
      ? "$"
      : stepOneForm.currency === "EUR"
      ? "£"
      : ""
  ); // default currency symbol

  const handleCurrencyChange = (e) => {
    const { value } = e.target;

    setCurrency(value);
    setStepOneForm({
      ...stepOneForm,
      currency: value,
    });

    if (value === "TRY") {
      setCurrencySymbol("₺");
    } else if (value === "USD") {
      setCurrencySymbol("$");
    } else if (value === "EUR") {
      setCurrencySymbol("£");
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "minPrice" || name === "maxPrice") {
      const formattedVal = value.replace(/[^\d]/g, ""); // Sadece sayı karakterlerini al
      const numVal = Number(formattedVal);

      setStepOneForm({
        ...stepOneForm,
        [name]: Number(numVal).toLocaleString(),
      });
    } else {
      setStepOneForm({
        ...stepOneForm,
        [name]: value,
      });
    }
  }

  const handleNext = () => {
    // parseInt(priceString.replace(/[,.]/g, "")); // virgül ve nokta işaretleri kaldırıldıktan sonra integer'a dönüştürüldü
    console.log(stepOneForm);
    const offerData = {
      ...stepOneForm,
      // currency: currency,
      amount: parseInt(stepOneForm.amount),
      minPrice: Number(stepOneForm.minPrice.replace(/[,.]/g, "")),
      maxPrice: Number(stepOneForm.maxPrice.replace(/[,.]/g, "")),
      expireDate: formatDate(stepOneForm.expireDate),
      deadline: formatDate(stepOneForm.deadline),
      unitType: Number(stepOneForm.unitType),
    };

    console.log("offerData : ", offerData);
    onNext(offerData);
  };

  return (
    <div className="step-one-container">
      <div className="step-one-top">
        <div className="title-box">
          <WhiteButton icon={<i className="fa-solid fa-chevron-left"></i>} text={"Geri"} onClick={() => navigate(-1)} />
          <div className="title">
            <i className="fa-solid fa-plus"></i>
            <h4>İhaleyi Düzenle</h4>
          </div>
        </div>

        <WhiteButton
          className={`${step !== 1 ? "display-none" : ""}`}
          icon={<i className="fa-solid fa-chevron-right"></i>}
          text={"İleri"}
          onClick={handleNext}
          disabled={step !== 1}
        />
      </div>

      <div className="step-one-form-container">
        <div className="input-standart">
          <label htmlFor="category">Kategori : </label>
          <Select
            isMulti
            options={categoryOptions}
            value={selectedCategories}
            onChange={handleChangeCategory}
            name="category"
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Kategori..."
            menuPlacement="bottom"
            menuPosition="fixed"
            menuShouldScrollIntoView={true}
            styles={{ menu: (base) => ({ ...base, height: "300px" }) }}
          />
        </div>

        <InputStandart
          type={"text"}
          title={"Ürün/Hizmet"}
          name={"productName"}
          placeholder={"Ürün/Hizmet..."}
          onChange={handleChange}
          value={stepOneForm.productName}
        />

        <div className="input-standart">
          <label htmlFor={"description"}>Açıklama : </label>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="2"
            placeholder={"Açıklama..."}
            onChange={handleChange}
            value={stepOneForm.description}
          />
        </div>

        <div className="input-two">
          <div className="input-standart">
            <label htmlFor={"amount"}>{"Miktar"} : </label>
            <div className="input-select-box">
              <input
                type={"number"}
                title={"Miktar"}
                name={"amount"}
                placeholder={"Miktar..."}
                onChange={handleChange}
                value={stepOneForm.amount}
              />

              <select
                name="unitType"
                value={stepOneForm.unitType}
                onChange={handleChange}
              >
                {unitTypeOptions.map((unitType) => {
                  return (
                    <option key={unitType.value} value={unitType.value}>
                      {unitType.label}
                    </option>
                  );
                })}
              </select>
              {/* <Select
                options={unitTypeOptions}
                value={selectedUnitType}
                onChange={handleChangeUnitType}
                name="unitType"
                className="basic-multi-select"
                classNamePrefix="select"
                // placeholder="Unit Type..."
              /> */}
            </div>
          </div>
        </div>

        <div className="input-standart">
          <label>Para birimi: </label>
          <select value={currency} onChange={handleCurrencyChange}>
            <option value="TRY">TRY</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>

        <InputPrice
          type={"text"}
          title={"Min.Bütçe"}
          name={"minPrice"}
          placeholder={"Min.Bütçe..."}
          currencySymbol={currencySymbol}
          onChange={handleChange}
          value={stepOneForm.minPrice}
        />

        <InputPrice
          type={"text"}
          title={"Max.Bütçe"}
          name={"maxPrice"}
          placeholder={"Max.Bütçe..."}
          currencySymbol={currencySymbol}
          onChange={handleChange}
          value={stepOneForm.maxPrice}
        />

        <InputStandart
          type={"date"}
          title={"Bitiş Tarihi"}
          name={"expireDate"}
          placeholder={"Bitiş Tarihi..."}
          onChange={handleChange}
          value={stepOneForm.expireDate}
        />

        <InputStandart
          type={"date"}
          title={"Son Teslim Tarihi"}
          name={"deadline"}
          placeholder={"Son Teslim Tarihi..."}
          onChange={handleChange}
          value={stepOneForm.deadline}
        />

        <div className="input-standart documan">
          <label>Fotoğraf : </label>
          <UploadImageBox
            images={images}
            setImages={setImages}
            formData={stepOneForm}
            setFormData={setStepOneForm}
          />
        </div>

        <div className="input-standart documan">
          <label>Dökümanlar : </label>
          <DocumentUploader
            documents={documents}
            setDocuments={setDocuments}
            formData={stepOneForm}
            setFormData={setStepOneForm}
          />
        </div>

        <InputStandart
          type={"text"}
          title={"*Not"}
          name={"note"}
          placeholder={"*Not..."}
          onChange={handleChange}
          value={stepOneForm.note}
        />
      </div>
    </div>
  );
}

export default TenderEditStepOne;
