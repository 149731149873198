function BuildingIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.1818 18H20V20H0V18H1.81818V1C1.81818 0.734784 1.91396 0.48043 2.08445 0.292893C2.25494 0.105357 2.48617 0 2.72727 0H17.2727C17.5138 0 17.7451 0.105357 17.9156 0.292893C18.086 0.48043 18.1818 0.734784 18.1818 1V18ZM6.36364 9V11H9.09091V9H6.36364ZM6.36364 5V7H9.09091V5H6.36364ZM6.36364 13V15H9.09091V13H6.36364ZM10.9091 13V15H13.6364V13H10.9091ZM10.9091 9V11H13.6364V9H10.9091ZM10.9091 5V7H13.6364V5H10.9091Z"
            />
        </svg>

    )
}

export default BuildingIcon;
