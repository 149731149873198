import { useState } from "react";
import Pagination from "../Pagination/Pagination";
import "./selectTable.css";
import { useEffect } from "react";

function SelectTable({
  selectedRows,
  setSelectedRows,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  columns,
  data,
}) {
  
  const [selectedRowsIds, setSelectedRowsIds] = useState(
    selectedRows ? selectedRows.map((item) => item.companyId) : []
  );

  useEffect(() => {
    const selectedRowsIds = selectedRows.map((item) => item.companyId);
    setSelectedRowsIds(selectedRowsIds);
  }, [selectedRows]);

  function handleSelect(companyId, row) {
    if (companyId || row) {
      if (selectedRowsIds.includes(row.companyId)) {
        // Aksi takdirde, seçili satırların listesinden kaldırın
        setSelectedRows(
          selectedRows.filter(
            (selected) => selected.companyId !== row.companyId
          )
        );
      } else {
        // Eğer onay kutusu işaretlenirse, seçili satırların listesine ekleyin
        setSelectedRows([
          ...selectedRows,
          { companyId: row.companyId, companyName: row.companyName },
        ]);
      }
    }
  }

  function handleChange(e, row) {
    const { checked } = e.target;

    if (checked) {
      // Eğer onay kutusu işaretlenirse, seçili satırların listesine ekleyin
      setSelectedRows([
        ...selectedRows,
        { companyId: row.companyId, companyName: row.companyName },
      ]);
    } else {
      // Aksi takdirde, seçili satırların listesinden kaldırın
      setSelectedRows(
        selectedRows.filter((selected) => selected.companyId !== row.companyId)
      );
    }
  }

  return (
    <div className="select-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              <th>seç</th>
              <th>logo</th>
              <th>companyName</th>
              <th>companyCategory</th>
              <th>email</th>
              <th>phoneNumber</th>
              <th>address</th>
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              return (
                <tr
                  key={row.companyId}
                  onClick={() => handleSelect(row.companyId, row)}
                  style={{
                    height: "50px",
                  }}
                  className={
                    selectedRowsIds.includes(row.companyId)
                      ? "table-row-select"
                      : ""
                  }
                >
                  <td>
                    <input
                      name="company"
                      type="checkbox"
                      checked={selectedRowsIds.includes(row.companyId)}
                      onChange={(e) => handleChange(e, row)}
                    />
                  </td>
                  <td>
                    <div className="image-box">
                      <img
                        src={row.logo}
                        onError={({ currentTarget }) => {
                          currentTarget.src =
                            "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png";
                        }}
                        alt={row.logo}
                        title={row.logo}
                      />
                    </div>
                  </td>
                  <td>{row.companyName}</td>
                  <td>{row.companyCategory}</td>
                  <td>{row.email}</td>
                  <td>{row.phoneNumber}</td>
                  <td>{row.address}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default SelectTable;
{
  /* <div className="select-table-main">
      <div className="table-container">
        <div className="table-box">
          <table>
            <thead>
              <tr>
                <th>seç</th>
                <th>logo</th>
                <th>companyName</th>
                <th>companyCategory</th>
                <th>email</th>
                <th>phoneNumber</th>
                <th>address</th>
              </tr>
            </thead>

            <tbody>
              {data.map((row) => {
                return (
                  <tr
                    key={row.companyId}
                    onClick={() => handleSelect(row.companyId, row)}
                    style={{
                      height: "50px",
                    }}
                    className={
                      selectedRowsIds.includes(row.companyId)
                        ? "table-row-select"
                        : ""
                    }
                  >
                    <th>
                      <input
                        name="company"
                        type="checkbox"
                        checked={selectedRowsIds.includes(row.companyId)}
                        onChange={(e) => handleChange(e, row)}
                      />
                    </th>
                    <th>
                      <img
                        src={`http://sosocrm.oetech.online/api/Images/images/${row.logo}}`}
                        onError={({ currentTarget }) => {
                          currentTarget.src =
                            "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png";
                        }}
                        alt={row.logo}
                        title={row.logo}
                      />
                    </th>
                    <th>{row.companyName}</th>
                    <th>{row.companyCategory}</th>
                    <th>{row.email}</th>
                    <th>{row.phoneNumber}</th>
                    <th>{row.address}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          total={total}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div> */
}
