import "./allOffers.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import AllOffersEditBox from "../../components/EditBox/AllOffersEditBox";

function AllallOffers({ t }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [searchText, setSearchText] = useState("");
  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);

  const [allOffersPage, setAllOffersPage] = useState(1);
  const [allOffersRowsPerPage, setAllOffersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setAllOffersUseFetchRender,
    useFetchRender: allOffersUseFetchRender,
    error: allOffersError,
    loading: allOffersLoading,
    data: allOffers,
    total: allOffersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?Last=false&SortType=${tableFilter}&Page=${allOffersPage}&PageSize=${allOffersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: "Foto",
    },
    {
      key: "offerCompanyCategoryName",
      header: t("table_category"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "materialPrice",
      header: t("table_materiel_price"),
    },
    {
      key: "laborPrice",
      header: t("table_labor_price"),
    },
    {
      key: "unitPrice",
      header: t("table_unit_price"),
    },
    {
      key: "totalPrice",
      header: "Toplam Fiyat",
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setAllOffersUseFetchRender(!allOffersUseFetchRender);
  }, [notificationRender]);

  return (
    <div className="alloffers-container">
      <div className="alloffers-edit-box">
        <AllOffersEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          useFetchRender={allOffersUseFetchRender}
          setUseFetchRender={setAllOffersUseFetchRender}
          selectValue={selectValue}
          selectValueId={selectValueId}
        />
      </div>
      <div className="alloffers-main">
        {allOffersError ? (
          <ErrorComponent error={allOffersError} />
        ) : allOffersLoading ? (
          <Loading />
        ) : allOffers !== null ? (
          <StandartTable
            type="offer"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={allOffersUseFetchRender}
            setUseFetchRender={setAllOffersUseFetchRender}
            total={allOffersTotal}
            page={allOffersPage}
            setPage={setAllOffersPage}
            rowsPerPage={allOffersRowsPerPage}
            setRowsPerPage={setAllOffersRowsPerPage}
            columns={columns}
            data={allOffers}
          />
        ) : (
          <div className="table-null-text">{t("message_no_all_tender")}</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(AllallOffers);
