import React, { useState } from "react";

import { withNamespaces } from "react-i18next";

import SelectTable from "../../../components/Table/SelectTable/SelectTable";
import { AuthContextValue } from "../../../context/AuthContext";
import useFetch from "../../../hooks/UseFetch";
import Loading from "../../../components/Loading/Loading";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../../components/LoadingCircular/LoadingCircular";

function TenderEditStepTwo({
  t,
  onPrev,
  onSubmit,
  formData,
  setFormData,
  step,
  setStep,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [tenderCompanyId, setTenderCompanyId] = useState(
    formData.tenderCompanyId ? formData.tenderCompanyId : []
  );

  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setMySuppliersUseFetchRender,
    useFetchRender: mySuppliersUseFetchRender,
    error: mySuppliersError,
    loading: mySuppliersLoading,
    data: mySuppliers,
    total: mySuppliersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_MY_SUPPLIER_URL}?id=${loginUserCompanyId}&page=${mySuppliersPage}&pageSize=${mySuppliersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "",
      header: "Seç",
    },
    {
      key: "logo",
      header: "",
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "companyCategory",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "address",
      header: t("table_address"),
    },
  ];

  const handlePrev = () => {
    const tenderCompanyIds = tenderCompanyId.map((comp) => {
      if (comp.id) {
        return comp.companyId;
      } else {
        return comp;
      }
    });
    onPrev({ tenderCompanyId: tenderCompanyIds });
  };

  const handleSubmit = async () => {
    if (tenderCompanyId.length <= 0) {
      alert("Lütfen form verilerini doldurunuz");
      return;
    } else {
      const tenderCompanyIds = tenderCompanyId.map((comp) => comp.companyId);

      setUpdateLoading(true);

      await onSubmit({ tenderCompanyId: tenderCompanyIds });

      setUpdateLoading(false);
    }

    handleCloseConfirmModal();
  };

  return (
    <div className="step-two-container">
      <div className="step-two-top">
        {updateLoading ? (
          <LoadingCircular />
        ) : confirmModalOpen ? (
          <ConfirmModal
            text={"İhaleyi güncellemek istediğinize emin misiniz?"}
            cancelButtonText={"İptal"}
            confirmButtonText={"Güncelle"}
            confirmFunc={handleSubmit}
            handleCloseModal={handleCloseConfirmModal}
          />
        ) : (
          <>
            <div className="step-two-top-left">
              <div className="title-box">
                <WhiteButton
                  className={`${step !== 2 ? "display-none" : ""}`}
                  icon={<i className="fa-solid fa-chevron-left"></i>}
                  text={"Geri"}
                  onClick={handlePrev}
                  disabled={step !== 2}
                />
                <div className="title">
                  <i className="fa-solid fa-cubes"></i>
                  <h4>Tedarikçi Seç</h4>
                </div>
              </div>
            </div>
            <div className="step-two-top-right">
              <WhiteButton
                icon={<i className="fa-solid fa-location-arrow"></i>}
                text={"Güncelle"}
                onClick={handleOpenConfirmModal}
              />
            </div>
          </>
        )}
      </div>

      <div
        className={`supplier-table ${
          confirmModalOpen || updateLoading ? "disabled" : ""
        }`}
      >
        {mySuppliersError ? (
          <ErrorComponent error={mySuppliersError} />
        ) : mySuppliersLoading ? (
          <Loading />
        ) : mySuppliers !== null && mySuppliers.length > 0 ? (
          <SelectTable
            total={mySuppliersTotal}
            page={mySuppliersPage}
            setPage={setMySuppliersPage}
            rowsPerPage={mySuppliersRowsPerPage}
            setRowsPerPage={setMySuppliersRowsPerPage}
            selectedRows={tenderCompanyId}
            setSelectedRows={setTenderCompanyId}
            columns={columns}
            data={mySuppliers}
          />
        ) : (
          <div className="table-null-text">Sistemde kayıtlı firma yok</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(TenderEditStepTwo);
