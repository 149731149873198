import "./bannersCompany.css";

import React, { useEffect, useState } from "react";
import Pagination from "../../components/Table/Pagination/Pagination";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import BannerPanelEditBox from "../../components/EditBox/BannerPanelEditBox";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { Modal } from "antd";
import { CrudContextValue } from "../../context/CrudContext";
import BannerAddForm from "../../components/BannerAddForm/BannerAddForm";
import { GetContextValue } from "../../context/GetContext";

// Format date to a more readable format
const formatReadableDate = (date) => {
  return new Date(date).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

function BannersCompany() {
  const { loginUserCompanyId, token } = AuthContextValue();
  const { createMethod } = CrudContextValue();
  const { notificationRender } = GetContextValue();

  const [loading, setLoading] = useState(false);

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [bannerFilter, setBannerFilter] = useState("");
  const [bannerPage, setBannerPage] = useState(1);
  const [bannerRowsPerPage, setBannerRowsPerPage] = useState(10);

  // "" = "Hepsi"
  // 1 = "Onay Bekleyen"
  // 2 = "Aktif"
  // 3 = "Pasif"
  const {
    setUseFetchRender: setBannersUseFetchRender,
    useFetchRender: bannersUseFetchRender,
    error: bannersError,
    loading: bannersLoading,
    data: banners,
    total: bannersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_BANNER_LIST_URL}?CompanyId=${loginUserCompanyId}&Status=${bannerFilter}&Page=${bannerPage}&PageSize=${bannerRowsPerPage}`,
    token
  );

  useEffect(() => {
    setBannersUseFetchRender(!bannersUseFetchRender);
  }, [notificationRender]);

  const { total: allBannersTotal } = useFetch(
    `${process.env.REACT_APP_GET_BANNER_LIST_URL}?Page=${1}&PageSize=${1}`,
    token
  );

  console.log(allBannersTotal);

  const handleOpenAddBannerModal = () => {
    setAddModalVisible(true);
  };
  const handleCloseAddBannerModal = () => {
    setAddModalVisible(false);
  };

  // YENİ REKLAM EKLEME İŞLEMİ
  const addBanner = async (addBannerData) => {
    console.log("addBannerData : ", addBannerData);
    setLoading(true);

    await createMethod(
      process.env.REACT_APP_POST_ADD_BANNER_URL,
      JSON.stringify(addBannerData),
      token
    );

    setLoading(false);

    handleCloseAddBannerModal();

    setBannersUseFetchRender(!bannersUseFetchRender);
  };

  return (
    <div className="banner-panel-container">
      <div className="banner-panel-edit-box">
        <BannerPanelEditBox
          tableFilter={bannerFilter}
          setTableFilter={setBannerFilter}
          useFetchRender={bannersUseFetchRender}
          setUseFetchRender={setBannersUseFetchRender}
          handleOpenAddBannerModal={handleOpenAddBannerModal}
        />
      </div>
      <div className="banner-panel-main">
        {bannersError ? (
          <ErrorComponent error={bannersError} />
        ) : bannersLoading ? (
          <LoadingCircular />
        ) : (
          banners &&
          banners.map((banner, index) => {
            return (
              <div key={index} className="banner-card">
                <div className="image-box">
                  <img src={banner.image} alt=" banner-resim" />
                </div>
                <div className="detail-box">
                  <span
                    className={`banner-status ${
                      Number(banner.status) === 1
                        ? "banner-status-orange"
                        : Number(banner.status) === 2
                        ? "banner-status-green"
                        : "banner-status-red"
                    }`}
                  >
                    {Number(banner.status) === 1
                      ? "Bekliyor"
                      : Number(banner.status) === 2
                      ? "Aktif"
                      : "Pasif"}
                  </span>
                  <h4>Firma : {banner.companyName}</h4>
                  <p>Sırası : {banner.index} </p>
                  <p>Süresi : {banner.displayTime} </p>
                  <p>
                    Başlangıç:
                    <small> {formatReadableDate(banner.startDate)}</small>
                  </p>
                  <p>
                    Bitiş:
                    <small> {formatReadableDate(banner.finishDate)}</small>
                  </p>
                </div>
              </div>
            );
          })
        )}

        {loading && <LoadingCircular />}
      </div>

      <div className="banner-panel-pagination">
        <Pagination
          page={bannerPage}
          setPage={setBannerPage}
          rowsPerPage={bannerRowsPerPage}
          setRowsPerPage={setBannerRowsPerPage}
          total={bannersTotal}
        />
      </div>

      <Modal
        style={{ top: 20 }}
        open={addModalVisible}
        onCancel={handleCloseAddBannerModal}
        footer={null}
      >
        <BannerAddForm
          onCancel={handleCloseAddBannerModal}
          onSubmit={addBanner}
          allBannersTotal={allBannersTotal}
        />
      </Modal>
    </div>
  );
}

export default BannersCompany;
