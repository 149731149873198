import "../StandartTable/standartTable.css";

import Pagination from "../Pagination/Pagination";

function WaitingOffersTable({
  type,

  selectValueId,
  selectValue,
  handleClickValue,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  data,
}) {
  function formatDuration(duration) {
    if (duration <= 0) {
      return "Bitti";
    }

    const days = Math.floor(duration / (24 * 60 * 60 * 1000));
    duration -= days * 24 * 60 * 60 * 1000;

    const hours = Math.floor(duration / (60 * 60 * 1000));
    duration -= hours * 60 * 60 * 1000;

    const minutes = Math.floor(duration / (60 * 1000));
    duration -= minutes * 60 * 1000;

    const seconds = Math.floor(duration / 1000);

    return `${days} gün, ${hours} saat, ${minutes} dakika, ${seconds} saniye`;
  }

  return (
    <div className="standart-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              <th title={"Foto"}>Foto</th>
              <th title={"Durum"}>Durum</th>
              <th title={"Teklif Yapan"}>Teklifi Oluşturan</th>
              <th title={"Yayınlayan"}>Yayınlayan</th>
              <th title={"Kategori"}>Kategori</th>
              <th title={"Ürün/Hizmet"}>Ürün/Hizmet</th>
              <th title={"Tarih"}>Tarih</th>
              <th title={"Kalan Süre"}>Kalan Süre</th>
              <th title={"Bütçe"}>Bütçe</th>
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              const id =
                type === "offer"
                  ? row.offerId
                  : type === "tender"
                  ? row.tenderId
                  : null;
              return (
                <tr
                  key={id}
                  onClick={() => handleClickValue(id, row)}
                  style={{
                    height: "50px",
                  }}
                  className={id === selectValueId ? "table-row-select" : ""}
                >
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={"Foto"}
                  >
                    {row.images && row.images.length > 0 && (
                      <div className="image-box">
                        <img
                          src={
                            row.images.filter((img) => !img.includes("pdf"))[0]
                          }
                          alt="offer-img"
                        />
                      </div>
                    )}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    style={{
                      color:
                        row.status === "1"
                          ? "orange"
                          : row.status === "3"
                          ? "red"
                          : row.status === "2"
                          ? "green"
                          : "",
                    }}
                    title= {row.status === "3"
                      ? "Düzenleme"
                      : row.status === "1"
                      ? "Onaylama"
                      : null}
                  >
                    {row.status === "3" ? (
                      <i className="fa-solid fa-pen-to-square" />
                    ) : row.status === "1" ? (
                      <i className="fa-solid fa-check" />
                    ) : null}{" "}
                    {row.status === "3"
                      ? "Düzenleme"
                      : row.status === "1"
                      ? "Onaylama"
                      : null}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={row.createdUser}
                  >
                    {row.createdUser}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={row.tenderDetail[0]?.companyName}
                  >
                    {row.tenderDetail[0]?.companyName}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={row.tenderDetail[0]?.categories
                      ?.map((category) => category.categoryName)
                      .join(", ")}
                  >
                    {row.tenderDetail[0]?.categories
                      ?.map((category) => category.categoryName)
                      .join(", ")}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={"Ürün/hizmet"}
                  >
                    {row.tenderDetail[0]?.productName}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title= {new Date(row.tenderDetail[0]?.createdAt).toLocaleString()}
                  >
                    {new Date(row.tenderDetail[0]?.createdAt).toLocaleString()}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={"Kalan Süre"}
                  >
                    {formatDuration(
                      new Date(row.tenderDetail[0]?.expireDate) - new Date()
                    )}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={"Bütçe"}
                  >
                    {row.currency && row.currency !== "string"
                      ? Number(row.totalPrice).toLocaleString(row.currency, {
                          style: "currency",
                          currency: row.currency,
                        })
                      : Number(row.totalPrice).toLocaleString()}{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default WaitingOffersTable;
