import "./styles/companyList.css";

import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import CompanyEditBox from "../../components/EditBox/CompanyEditBox";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { GetContextValue } from "../../context/GetContext";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function CompanyList({ t }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [searchText, setSearchText] = useState("");

  const [companyListPage, setCompanyListPage] = useState(1);
  const [companyListRowsPerPage, setCompanyListRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setCompanyListUseFetchRender,
    useFetchRender: companyListUseFetchRender,
    error: companyListError,
    loading: companyListLoading,
    data: companyList,
    total: companyListTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_ALL_COMPANY_URL}?searchedText=${searchText}&page=${companyListPage}&pageSize=${companyListRowsPerPage}`,
    token
  );

  useEffect(() => {
    setCompanyListUseFetchRender(!companyListUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  
  const columns = [
    {
      key: "logo",
      header: "",
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "categoryName",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "addresses",
      header: t("table_address"),
    },
  ];

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }
console.log(companyList);
  return (
    <div className="companylist-container">
      <div className="companylist-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        <CompanyEditBox
          type={"company"}
          searchText={searchText}
          setSearchText={setSearchText}
          // tableFilter={tableFilter}
          // setTableFilter={setTableFilter}

          useFetchRender={companyListUseFetchRender}
          setUseFetchRender={setCompanyListUseFetchRender}
          selectValueId={selectValueId}
          setSelectValueId={setSelectValueId}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
        />
      </div>

      <div className="companylist-table">
        {companyListError ? (
          <ErrorComponent error={companyListError} />
        ) : companyListLoading ? (
          <Loading />
        ) : companyList !== null ? (
          <StandartTable
            type="company"
            searchText={searchText}
            setSearchText={setSearchText}
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={companyListUseFetchRender}
            setUseFetchRender={setCompanyListUseFetchRender}
            total={companyListTotal}
            page={companyListPage}
            setPage={setCompanyListPage}
            rowsPerPage={companyListRowsPerPage}
            setRowsPerPage={setCompanyListRowsPerPage}
            columns={columns}
            data={companyList}
          />
        ) : (
          <div className="table-null-text">Sistemde kayıtlı firma yok</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(CompanyList);
