import "./standartTable.css";
import { ThemeContextValue } from "../../../context/ThemeContext";
import Pagination from "../Pagination/Pagination";

function StandartTable({
  type,

  selectValueId,
  selectValue,
  handleClickValue,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  columns,
  data,
}) {
  const { theme } = ThemeContextValue();
  function getRatingColor(rating) {
    // Rating değerini min 0, max 10 aralığına getiriyoruz.
    rating = Math.max(0, Math.min(10, rating));

    // Renklerin tonlarını belirliyoruz.
    var colors = ["#E74C3C", "#FFA500", "#FFC300", "#2ECC71"];

    // Rating değerine göre renk belirliyoruz.
    var colorIndex = Math.floor(rating / 2.5);
    var color = colors[colorIndex];

    // Yuvarlak içindeki arkaplan rengini döndürüyoruz.
    return {
      width: "max-content",
      padding: ".3rem 1rem",
      fontSize: ".8rem",
      backgroundColor: color,
      borderRadius: "1rem",
      color: "white",
      boxShadow:
        theme === "dark"
          ? "-2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7)"
          : "-1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5)",
    };
  }

  function formatDuration(duration) {
    if (duration <= 0) {
      return "Bitti";
    }

    const days = Math.floor(duration / (24 * 60 * 60 * 1000));
    duration -= days * 24 * 60 * 60 * 1000;

    const hours = Math.floor(duration / (60 * 60 * 1000));
    duration -= hours * 60 * 60 * 1000;

    const minutes = Math.floor(duration / (60 * 1000));
    duration -= minutes * 60 * 1000;

    const seconds = Math.floor(duration / 1000);

    return `${days} gün, ${hours} saat, ${minutes} dakika, ${seconds} saniye`;
  }

  return (
    <div className="standart-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              {columns.map((column, i) => {
                return (
                  <th key={i} title={column.header}>
                    {column.header}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((row, index) => {
              // verilen type değerine göre tabloya id ataması
              const id = row.id
                ? row.id
                : type === "user"
                ? row.userId
                : type === "company"
                ? row.companyId
                : type === "tender"
                ? row.tenderId
                : type === "offer"
                ? row.offerId
                : type === "ofCompany"
                ? row.ofCompanyId
                : type === "companies-app"
                ? row.invitedId
                : "";

              return (
                <tr
                  key={id}
                  onClick={() => handleClickValue(id, row)}
                  style={{
                    height: "50px",
                  }}
                  className={id === selectValueId ? "table-row-select" : ""}
                >
                  {columns.map((column, i) => {
                    //images içinde gelen pdf ve img dosyalarının filtrelenip dizilere gönderilme işlemi
                    let documents = [];
                    let images = [];
                    let categories = [];

                    if (column.key === "images") {
                      row[column.key].map((item) =>
                        item.includes("pdf")
                          ? documents.push(item)
                          : images.push(item)
                      );
                    } else if (column.key === "image") {
                      images.push(row[column.key]);
                    } else if (column.key === "categories") {
                      if (row[column.key]) {
                        let categoriesObj = row[column.key]
                          .map((category) => category.categoryName)
                          .join(", ");
                        categories.push(categoriesObj);
                      }
                    }

                    const offersCount = 0;
                    const revisedOffersCount = 0;

                    return (
                      <td
                        key={i}
                        className={
                          id === selectValueId ? "table-row-select-text" : ""
                        }
                        title={row[column.key]}
                      >
                        {column.key === "offerCreatedAt" ? (
                          new Date(row[column.key]).toLocaleString()
                        ) : column.key === "invitersCompanyName" &&
                          row[column.key] === null ? (
                          "-"
                        ) : column.key === "images" ||
                          column.key === "image" ||
                          column.key === "logo" ? (
                          <div className="image-box">
                            <img
                              src={
                                images[0]
                                  ? images[0]
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                              alt={images[0]}
                            />
                          </div>
                        ) : column.key === "logo" ? (
                          <img src={row[column.key]} alt={row[column.key]} />
                        ) : column.key === "averageScore" ? (
                          <code
                            style={
                              column.key === "averageScore"
                                ? getRatingColor(row[column.key])
                                : {}
                            }
                          >
                            {row[column.key]}
                          </code>
                        ) : column.key === "categories" ? (
                          <code title={categories}>{categories}</code>
                        ) : column.key === "createdAt" ? (
                          <code>
                            {new Date(row[column.key]).toLocaleString()}
                          </code>
                        ) : column.key === "remainingTime" ? (
                          <code>
                            {formatDuration(
                              new Date(row["expireDate"]) - new Date()
                            )}
                          </code>
                        ) : column.key === "offers" ? (
                          <code>
                            <i className="fa-solid fa-ticket-simple" />{" "}
                            {offersCount}{" "}
                            <i className="fa-solid fa-arrow-rotate-left" />{" "}
                            {revisedOffersCount}
                          </code>
                        ) : column.key === "minPrice" ||
                          column.key === "maxPrice" ||
                          column.key === "unitPrice" ||
                          column.key === "laborPrice" ||
                          column.key === "materialPrice" ||
                          column.key === "totalPrice" ? (
                          <code>
                            {row["currency"]
                              ? Number(row[column.key]).toLocaleString(
                                  row["currency"],
                                  {
                                    style: "currency",
                                    currency: row["currency"],
                                  }
                                )
                              : Number(row.minPrice).toLocaleString("TRY", {
                                  style: "currency",
                                  currency: "TRY",
                                })}
                          </code>
                        ) : column.key === "result" ||
                          column.key === "status" ? (
                          <code
                            style={{
                              color:
                                row[column.key] === "3" ||
                                row[column.key] === "4"
                                  ? "red"
                                  : row[column.key] === "1" ||
                                    row[column.key] === null
                                  ? "orange"
                                  : row[column.key] === "2" ||
                                    row[column.key] === "5"
                                  ? "green"
                                  : "",
                            }}
                          >
                            {row.result === "2" ? (
                              <i className="fa-solid fa-check" />
                            ) : row.result === "3" ? (
                              <i className="fa-solid fa-arrow-rotate-left" />
                            ) : row.result === "1" ||
                              row[column.key] === null ? (
                              <i className="fa-solid fa-clock" />
                            ) : row.result === "4" ? (
                              <i className="fa-solid fa-circle-exclamation"></i>
                            ) : row.result === "5" ? (
                              <i className="fa-solid fa-check" />
                            ) : null}{" "}
                            {row[column.key] === "3"
                              ? "Revize İstendi"
                              : row[column.key] === "1" ||
                                row[column.key] === null
                              ? "Bekliyor"
                              : row[column.key] === "2"
                              ? "Onaylandı"
                              : row[column.key] === "4"
                              ? "Reddedildi"
                              : row[column.key] === "5"
                              ? "Sonuçlandı"
                              : ""}
                          </code>
                        ) : column.key === "companyType" ? (
                          <code
                            title={
                              row[column.key] === "1"
                                ? "Ana Firma"
                                : row[column.key] === "2"
                                ? "Tedarikçi"
                                : row[column.key] === "3"
                                ? "Ana Firma - Tedarikçi"
                                : "null"
                            }
                          >
                            {row[column.key] === "1"
                              ? "Ana Firma"
                              : row[column.key] === "2"
                              ? "Tedarikçi"
                              : row[column.key] === "3"
                              ? "Ana Firma - Tedarikçi"
                              : "null"}
                          </code>
                        ) : column.key === "addresses" ? (
                          <code>
                            {row["addresses"][0].city}/
                            {row["addresses"][0].district}
                          </code>
                        ) : column.key === "invite-status" ? (
                          <code
                            style={{
                              color: row["status"] ? "green" : "orange",
                            }}
                          >
                            {row["status"] ? (
                              <i className="fa-solid fa-check" />
                            ) : (
                              <i className="fa-solid fa-clock" />
                            )}{" "}
                            {row["status"] ? "Onaylandı" : "Bekliyor"}
                          </code>
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default StandartTable;
{
  /* <div className="standart-table-main">
<div className="table-container">
  <div className="table-box">
    <table>
      <thead>
        <tr>
          {columns.map((column, i) => {
            return (
              <th key={i} title={column.header}>
                {column.header}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {data.map((row, index) => {
          // verilen type değerine göre tabloya id ataması
          const id = row.id
            ? row.id
            : type === "user"
            ? row.userId
            : type === "company"
            ? row.companyId
            : type === "tender"
            ? row.tenderId
            : type === "offer"
            ? row.offerId
            : type === "ofCompany"
            ? row.ofCompanyId
            : type === "companies-app"
            ? row.invitedId
            : "";
          return (
            <tr
              key={id}
              onClick={() => handleClickValue(id, row)}
              style={{
                height: "50px",
              }}
              className={id === selectValueId ? "table-row-select" : ""}
            >
              {columns.map((column, i) => {
                //images içinde gelen pdf ve img dosyalarının filtrelenip dizilere gönderilme işlemi
                let documents = [];
                let images = [];
                let categories = [];

                if (column.key === "images") {
                  row[column.key].map((item) =>
                    item.includes("pdf")
                      ? documents.push(item)
                      : images.push(item)
                  );
                } else if (column.key === "image") {
                  images.push(row[column.key]);
                } else if (column.key === "categories") {
                  if (row[column.key]) {
                    let categoriesObj = row[column.key]
                      .map((category) => category.categoryName)
                      .join(", ");
                    categories.push(categoriesObj);
                  }
                }

                const offersCount = 0;
                const revisedOffersCount = 0;

                return (
                  <td
                    key={i}
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={row[column.key]}
                    
                  >
                    {column.key === "offerCreatedAt" ? (
                      new Date(row[column.key]).toLocaleString()
                    ) : column.key === "invitersCompanyName" &&
                      row[column.key] === null ? (
                      "-"
                    ) : column.key === "images" ||
                      column.key === "image" ? (
                      <div className="image-box">
                        <img
                          src={
                            images[0]
                              ? images[0]
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt={images[0]}
                        />
                      </div>
                    ) : column.key === "logo" ? (
                      <img src={row[column.key]} alt={row[column.key]} />
                    ) : column.key === "averageScore" ? (
                      <code
                        style={
                          column.key === "averageScore"
                            ? getRatingColor(row[column.key])
                            : {}
                        }
                      >
                        {row[column.key]}
                      </code>
                    ) : column.key === "categories" ? (
                      <code title={categories}>{categories}</code>
                    ) : column.key === "createdAt" ? (
                      <code>
                        {new Date(row[column.key]).toLocaleString()}
                      </code>
                    ) : column.key === "remainingTime" ? (
                      <code>
                        {formatDuration(
                          new Date(row["expireDate"]) - new Date()
                        )}
                      </code>
                    ) : column.key === "offers" ? (
                      <code>
                        <i className="fa-solid fa-ticket-simple" />{" "}
                        {offersCount}{" "}
                        <i className="fa-solid fa-arrow-rotate-left" />{" "}
                        {revisedOffersCount}
                      </code>
                    ) : column.key === "minPrice" ||
                      column.key === "maxPrice" ||
                      column.key === "totalPrice" ? (
                      <code>
                        {row["currency"] && row["currency"] !== "string"
                          ? Number(row[column.key]).toLocaleString(
                              row["currency"],
                              {
                                style: "currency",
                                currency: row["currency"],
                              }
                            )
                          : Number(row[column.key]).toLocaleString()}
                      </code>
                    ) : column.key === "result" ? (
                      <code
                        style={{
                          color:
                            row[column.key] === "3" ||
                            row[column.key] === "4"
                              ? "red"
                              : row[column.key] === "1"
                              ? "orange"
                              : row[column.key] === "2"
                              ? "green"
                              : "",
                        }}
                      >
                        {row.result === "2" ? (
                          <i className="fa-solid fa-check" />
                        ) : row.result === "3" ? (
                          <i className="fa-solid fa-arrow-rotate-left" />
                        ) : row.result === "1" ? (
                          <i className="fa-solid fa-clock" />
                        ) : row.result === "4" ? (
                          <i className="fa-solid fa-circle-exclamation"></i>
                        ) : null}{" "}
                        {row[column.key] === "3"
                          ? "Revize İstendi"
                          : row[column.key] === "1"
                          ? "Bekliyor"
                          : row[column.key] === "2"
                          ? "Onaylandı"
                          : row[column.key] === "4"
                          ? "Reddedildi"
                          : ""}
                      </code>
                    ) : column.key === "companyType" ? (
                      <code
                        title={
                          row[column.key] === "1"
                            ? "Ana Firma"
                            : row[column.key] === "2"
                            ? "Tedarikçi"
                            : row[column.key] === "3"
                            ? "Ana Firma - Tedarikçi"
                            : "null"
                        }
                      >
                        {row[column.key] === "1"
                          ? "Ana Firma"
                          : row[column.key] === "2"
                          ? "Tedarikçi"
                          : row[column.key] === "3"
                          ? "Ana Firma - Tedarikçi"
                          : "null"}
                      </code>
                    ) : (
                      row[column.key]
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>

  <Pagination
    total={total}
    page={page}
    setPage={setPage}
    rowsPerPage={rowsPerPage}
    setRowsPerPage={setRowsPerPage}
  />
</div>
</div> */
}
