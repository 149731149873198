import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import Loading from "../../components/Loading/Loading";
import OpportunitiesOffersTable from "../../components/Table/OpportunitiesOffersTable/OpportunitiesOffersTable";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function SumbittedOffers({
  t,
  tableFilter,
  searchText,
  tableRender,
  setResultRevisedNote,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [offersPage, setOffersPage] = useState(1);
  const [offersRowsPerPage, setOffersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setOffersUseFetchRender,
    useFetchRender: offersUseFetchRender,
    error: offersError,
    loading: offersLoading,
    data: offers,
    total: offersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${loginUserCompanyId}&OfferStatus=2&Last=true&SortType=${tableFilter}&Page=${offersPage}&PageSize=${offersRowsPerPage}`,
    token
  );

  useEffect(() => {
    setOffersUseFetchRender(!offersUseFetchRender);
  }, [loginUserCompanyId]);

  useEffect(() => {
    setOffersUseFetchRender(!offersUseFetchRender);
  }, [tableRender,notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setResultRevisedNote(value.revisedDescription);
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <>
      {offersError ? (
        <ErrorComponent error={offersError} />
      ) : offersLoading ? (
        <Loading />
      ) : offers !== null && offers.length > 0 ? (
        <OpportunitiesOffersTable
          type="offer"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={offersUseFetchRender}
          setUseFetchRender={setOffersUseFetchRender}
          total={offersTotal}
          page={offersPage}
          setPage={setOffersPage}
          rowsPerPage={offersRowsPerPage}
          setRowsPerPage={setOffersRowsPerPage}
          data={offers}
        />
      ) : (
        <div className="table-null-text">Gönderilmiş bir teklif yok!</div>
      )}
    </>
  );
}

export default withNamespaces()(SumbittedOffers);
