import React, { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { getFetch } from "../../../hooks/getFetch";
import { AuthContextValue } from "../../../context/AuthContext";

const NeighbourhoodComponent = ({
  required,
  selectedDistrict,
  setSelectedNeighbourhood,
  selectedNeighbourhood,
}) => {
  const { token } = AuthContextValue();

  const [neighbourhoods, setNeighbourhoods] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFetch(
          `${process.env.REACT_APP_GET_NEIGHBOURHOOD_URL}?districtId=${selectedDistrict}`,
          token
        );
        if (response.data) {
          setNeighbourhoods(response.data.data);
          setSelectedNeighbourhood(response.data.data[0]?.id);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    if (selectedDistrict) {
      fetchData();
    }
  }, [selectedDistrict]);

  const handleNeighbourhoodChange = (event) => {
    const selectedNeighbourhoodId = event.target.value;
    setSelectedNeighbourhood(selectedNeighbourhoodId);
  };

  console.log(selectedNeighbourhood);

  return (
    <CustomSelect
      required={required}
      value={selectedNeighbourhood}
      options={neighbourhoods}
      onChange={handleNeighbourhoodChange}
    />
  );
};

export default NeighbourhoodComponent;
