import "./userSupportRequest.css";
import React, { useEffect, useState } from "react";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import Pagination from "../../components/Table/Pagination/Pagination";
import { GetContextValue } from "../../context/GetContext";
import SupportEditBox from "../../components/EditBox/SupportEditBox";
import UserTicketBox from "../../components/TicketBox/UserTicketBox/UserTicketBox";
import { Modal } from "antd";
import SupportRequestForm from "../../components/SupportRequestBox/SupportRequestForm";

function UserSupportRequest() {
  const { loginUserCompanyId, loginUserId, token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [addSupportModal, setAddSupportModal] = useState(false);
  const handleOpenAddSuportModal = () => setAddSupportModal(true);
  const handleCloseAddSuportModal = () => setAddSupportModal(false);

  const [searchText, setSearchText] = useState("");

  // tablo filtreleme
  // "" = "Hepsi"
  // 1 = "Bekliyor"
  // 2 = "İnceleniyor"
  // 3 = "Çözüldü"
  // 4 = "Çözülemedi"
  // 5 = "Sonuçlandı"
  const [tableFilter, setTableFilter] = useState("");
  const [supportRequestPage, setSupportRequestPage] = useState(1);
  const [supportRequestRowsPerPage, setSupportRequestRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setSupportRequestUseFetchRender,
    useFetchRender: supportRequestUseFetchRender,
    error: supportRequestError,
    loading: supportRequestLoading,
    data: supportRequest,
    total: supportRequestTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_SUPPORT_LIST_URL}?CretedUserId=${loginUserId}&CompanyId=${loginUserCompanyId}&Status=${tableFilter}&SupportNumber=${searchText}&Page=${supportRequestPage}&PageSize=${supportRequestRowsPerPage}`,
    token
  );

  useEffect(() => {
    setSupportRequestUseFetchRender(!supportRequestUseFetchRender);
  }, [notificationRender, tableFilter]);

  return (
    <div className="user-support-request-container">
      <div className="support-edit-box">
        <SupportEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          useFetchRender={supportRequestUseFetchRender}
          setUseFetchRender={setSupportRequestUseFetchRender}
          handleOpenAddSuportModal={handleOpenAddSuportModal}
        />
      </div>

      <div className="user-support-request-main">
        {supportRequestError ? (
          <ErrorComponent error={supportRequestError} />
        ) : supportRequestLoading ? (
          <LoadingCircular />
        ) : supportRequest !== null && supportRequest.length > 0 ? (
          supportRequest.map((ticket, index) => {
            return (
              <UserTicketBox key={index} supportNumber={ticket.supportNumber} />
            );
          })
        ) : null}
      </div>

      <div className="pagination">
        <Pagination
          page={supportRequestPage}
          setPage={setSupportRequestPage}
          rowsPerPage={supportRequestRowsPerPage}
          setRowsPerPage={setSupportRequestRowsPerPage}
          total={supportRequestTotal}
        />
      </div>

      <Modal
        title="Talep Oluştur"
        style={{ top: 20 }}
        open={addSupportModal}
        onCancel={handleCloseAddSuportModal}
        footer={null}
      >
        <SupportRequestForm
          setSupportRequestUseFetchRender={setSupportRequestUseFetchRender}
          supportRequestUseFetchRender={supportRequestUseFetchRender}
          handleCloseModal={handleCloseAddSuportModal}
        />
      </Modal>
    </div>
  );
}

export default UserSupportRequest;
