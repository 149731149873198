import "./styles/mediumTableDetailCard/mediumTableDetailCard.css";

import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import MediumTable from "../../components/Table/MediumTable/MediumTable";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function TendersCard({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  /* Tablodan seçilen değerleri tutan stateler */
  const [selectValue, setSelectValue] = useState(null);
  const [selectValueId, setSelectValueId] = useState(null);

  const status = 2;

  const {
    setUseFetchRender: setActiveTendersUseFetchRender,
    useFetchRender: activeTendersUseFetchRender,
    error: activeTendersError,
    loading: activeTendersLoading,
    data: activeTenders,
    total: activeTendersTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?Status=${status}&CompanyId=${loginUserCompanyId}&Last=true&SortType=${3}&Page=${1}&PageSize=${5}`,
    token
  );

  const columns = [
    {
      key: "status",
      header: "Durum",
    },
    {
      key: "createdUser",
      header: "Oluşturan",
    },
    {
      key: "productName",
      header: "Ürün",
    },
    {
      key: "passedDay",
      header: "Geçen Süre",
    },
  ];

  useEffect(() => {
    setActiveTendersUseFetchRender(!activeTendersUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <div className="medium-table-container">
      <div className="table-top">
        <div className="title-box">
          <i className="fa-solid fa-star"></i>
          <h5>İhaleler</h5>
        </div>
        <div className="buttons">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            tooltip={"Yenile"}
            onClick={() => {
              setActiveTendersUseFetchRender(!activeTendersUseFetchRender);
              setSelectValue(null);
              setSelectValueId(null);
            }}
          />
          <Link
            to={
              selectValue || selectValueId
                ? `/tenders/tender-detail/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              disabled={selectValue || selectValueId ? false : true}
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>

      <div className="table-main">
        {activeTendersError ? (
          <ErrorComponent error={activeTendersError} />
        ) : activeTendersLoading ? (
          <Loading />
        ) : activeTenders !== null && activeTenders.length > 0 ? (
          <MediumTable
            type="tender"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            columns={columns}
            data={activeTenders}
          />
        ) : (
          <p className="table-null-text">Açılmış İhale Yok!</p>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(TendersCard);
