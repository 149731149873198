import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function MainCompanyTenders({ t, id, tableRender }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [mainCompanyTendersPage, setMainCompanyTendersPage] = useState(1);
  const [mainCompanyTendersRowsPerPage, setMainCompanyTendersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setMainCompanyTenderssUseFetchRender,
    useFetchRender: mainCompanyTenderssUseFetchRender,
    error: mainCompanyTendersError,
    loading: mainCompanyTendersLoading,
    data: mainCompanyTenders,
    total: mainCompanyTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_SUPPLIER_LIST_URL}?CompanyId=${loginUserCompanyId}&TenderCompanyId=${id}&Page=${mainCompanyTendersPage}&PageSize=${mainCompanyTendersRowsPerPage}&SortType=3`,
    token
  );


  const columns = [
    {
      key: "image",
      header: "Foto",
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "categories",
      header: "Kategori",
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "expireDate",
      header: t("table_expire_date"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setMainCompanyTenderssUseFetchRender(!mainCompanyTenderssUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <>
      {mainCompanyTendersError ? (
        <ErrorComponent error={mainCompanyTendersError} />
      ) : mainCompanyTendersLoading ? (
        <Loading />
      ) : mainCompanyTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={mainCompanyTenderssUseFetchRender}
          setUseFetchRender={setMainCompanyTenderssUseFetchRender}
          total={mainCompanyTendersTotal}
          page={mainCompanyTendersPage}
          setPage={setMainCompanyTendersPage}
          rowsPerPage={mainCompanyTendersRowsPerPage}
          setRowsPerPage={setMainCompanyTendersRowsPerPage}
          columns={columns}
          data={mainCompanyTenders}
        />
      ) : (
        <div className="table-null-text">Firmanın oluşturduğu ihale yok!</div>
      )}
    </>
  );
}

export default withNamespaces()(MainCompanyTenders);
