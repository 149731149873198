import "./styles/tenderDetailSupplier/tenderDetailSupplier.css";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";

import useFetch from "../../hooks/UseFetch";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import Loading from "../../components/Loading/Loading";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { tenderTimeOverCalcFunc } from "../../hooks/tenderTimeOver";
import { Alert, Space } from "antd";

function TenderDetailSupplier({ t }) {
  const { tenderId } = useParams();
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
    total: tenderDetailTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  const [offersPage, setOffersPage] = useState(1);
  const [offersRowsPerPage, setOffersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setOffersUseFetchRender,
    useFetchRender: offersUseFetchRender,
    error: offersError,
    loading: offersLoading,
    data: offers,
    total: offersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${loginUserCompanyId}&TenderId=${tenderId}&OfferStatus=2&Last=false&SortType=3&Page=${offersPage}&PageSize=${offersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: "Foto",
    },
    {
      key: "result",
      header: "Durum",
    },
    {
      key: "createdUser",
      header: "Teklifi Oluşturan",
    },
    {
      key: "offerCreatedAt",
      header: "Tarih",
    },
    {
      key: "deadline",
      header: "Termin",
    },
    {
      key: "totalPrice",
      header: "Bütçe",
    },
  ];

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    setOffersUseFetchRender(!offersUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <div className="tender-detail-supplier-container">
      <div className="opportunities-tender-detail">
        {tenderDetailError ? (
          <ErrorComponent error={tenderDetailError} />
        ) : tenderDetailLoading ? (
          <Loading />
        ) : tenderDetail !== null ? (
          <TenderDetailCard tenderDetail={tenderDetail} />
        ) : null}
      </div>
      <div className="opportunities-offers-table">
        {tenderTimeOverCalcFunc(
          new Date(tenderDetail?.expireDate) - new Date()
        ) === "overTime" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message="İhale süresi doldu teklif gönderemezsiniz"
              type="error"
              showIcon={true}
            />
          </Space>
        ) : null}
        <div className="tender-detail-tables-links">
          <div className="table-title">
            <i className="fa-solid fa-star"></i>
            <h4>Teklifler</h4>
          </div>

          <div className="offer-edit-button">
            <WhiteButton
              icon={<i className="fa-solid fa-note-sticky"></i>}
              tooltip={"Revize Notu"}
              onClick={handleOpenRevisedNote}
              disabled={selectValue || selectValueId ? false : true}
            />
            <Link
              to={
                selectValueId || selectValue
                  ? `offer-detail/${selectValueId}`
                  : false
              }
            >
              <WhiteButton
                icon={<i className="fa-solid fa-info" />}
                tooltip={"Detay"}
                disabled={selectValueId || selectValue ? false : true}
              />
            </Link>

            <Link
              to={
                tenderTimeOverCalcFunc(
                  new Date(tenderDetail?.expireDate) - new Date()
                ) === "overTime"
                  ? false
                  : `/opportunities/tender-detail-offer/${tenderId}`
              }
            >
              <WhiteButton
                icon={<i className="fa-solid fa-cart-plus"></i>}
                tooltip={"Teklif Gönder"}
                disabled={
                  tenderTimeOverCalcFunc(
                    new Date(tenderDetail?.expireDate) - new Date()
                  ) === "overTime"
                    ? true
                    : false
                }
              />
            </Link>
          </div>

          {openRevisedNote && (
            <RevisedDescriptionModal
              handleCloseRevisedNote={handleCloseRevisedNote}
              openRevisedNote={openRevisedNote}
              revisedNote={selectValue?.revisedDescription} //Burayı api güncellendiğinde değiştir revize açıklamasını yazdır
            />
          )}
        </div>
        <div className="tender-detail-table">
          {offersError ? (
            <ErrorComponent error={offersError} />
          ) : offersLoading ? (
            <Loading />
          ) : offers !== null ? (
            <StandartTable
              type="offer"
              // searchText={searchText}
              // setSearchText={setSearchText}

              selectValueId={selectValueId}
              selectValue={selectValue}
              handleClickValue={handleClickValue}
              useFetchRender={offersUseFetchRender}
              setUseFetchRender={setOffersUseFetchRender}
              total={offersTotal}
              page={offersPage}
              setPage={setOffersPage}
              rowsPerPage={offersRowsPerPage}
              setRowsPerPage={setOffersRowsPerPage}
              columns={columns}
              data={offers}
            />
          ) : (
            <div className="table-null-text">Gönderilmiş bir teklif yok!</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderDetailSupplier);
