import "./bidOfferCard.css";
import React, { useEffect, useState } from "react";
import InputStandart from "../form_components/Inputs/InputStandart/InputStandart";
import InputPrice from "../form_components/Inputs/InputPrice/InputPrice";
import DocumentUploader from "../DocumanUploder/DocumanUploader";
import UploadImageBox from "../UploadImageBox/UploadImageBox";

function BidOfferCard({
  images,
  setImages,
  documents,
  setDocuments,
  offerFormData,
  setOfferFormData,
  offerFormDataErrors,
  currency,
  setCurrency,
  currencySymbol,
  setCurrencySymbol,
  tenderAmount,
}) {
  const [totalPrice, setTotalPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "laborPrice" || name === "materialPrice") {
      const formattedVal = value.replace(/[^\d]/g, ""); // Sadece sayı karakterlerini al
      const numVal = Number(formattedVal);

      setOfferFormData({
        ...offerFormData,
        [name]: Number(numVal).toLocaleString(),
      });
    } else {
      setOfferFormData({
        ...offerFormData,
        [name]: value,
      });
    }
  }

  const handleCurrencyChange = (e) => {
    const { value } = e.target;

    setCurrency(value);

    if (value === "TRY") {
      setCurrencySymbol("₺");
    } else if (value === "USD") {
      setCurrencySymbol("$");
    } else if (value === "EUR") {
      setCurrencySymbol("£");
    }
  };

  useEffect(() => {
    if (
      tenderAmount &&
      offerFormData.laborPrice &&
      offerFormData.materialPrice
    ) {
      const total = parseInt(
        (parseInt(offerFormData.laborPrice.replace(".", "")) +
          parseInt(offerFormData.materialPrice.replace(".", ""))) *
          parseInt(tenderAmount)
      ).toLocaleString(offerFormData.currency);

      const unit = parseInt(
        parseInt(offerFormData.laborPrice.replace(".", "")) +
          parseInt(offerFormData.materialPrice.replace(".", ""))
      ).toLocaleString(offerFormData.currency);

      setTotalPrice(total);
      setUnitPrice(unit);
    }
  }, [tenderAmount, offerFormData.laborPrice, offerFormData.materialPrice]);

  useEffect(() => {
    setOfferFormData({
      ...offerFormData,
      unitPrice: unitPrice,
      totalPrice: totalPrice,
    });
  }, [unitPrice, totalPrice]);

  return (
    <div className="tender-detail-offer-form">
      <div className={`input-standart image-align `}>
        <label>Fotoğraf : </label>
        <UploadImageBox
          images={images}
          setImages={setImages}
          formData={offerFormData}
          setFormData={setOfferFormData}
        />
      </div>

      <div className="input-standart documan">
        <label>Dökümanlar : </label>
        <DocumentUploader
          documents={documents}
          setDocuments={setDocuments}
          formData={offerFormData}
          setFormData={setOfferFormData}
        />
      </div>

      <div className="input-standart">
        <label>Para birimi: </label>
        <select
          style={{
            border: offerFormDataErrors.currencyError ? "2px solid red" : "",
          }}
          required
          value={currency}
          onChange={handleCurrencyChange}
        >
          <option value="TRY">TRY</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select>
      </div>

      <InputPrice
        required={true}
        errorBorder={offerFormDataErrors.laborPriceError}
        type={"text"}
        name={"laborPrice"}
        placeholder={"İşçilik Fiyatı"}
        title={"İşçilik Fiyatı"}
        currencySymbol={currencySymbol}
        value={offerFormData.laborPrice}
        onChange={handleChange}
      />

      <InputPrice
        required={true}
        errorBorder={offerFormDataErrors.materialPriceError}
        type={"text"}
        name={"materialPrice"}
        placeholder={"Malzeme Fiyatı"}
        title={"Malzeme Fiyatı"}
        currencySymbol={currencySymbol}
        value={offerFormData.materialPrice}
        onChange={handleChange}
      />

      {/* <InputPrice
        required={true}
        errorBorder={offerFormDataErrors.unitPriceError}
        type={"text"}
        name={"unitPrice"}
        placeholder={"Birim Fiyat"}
        title={"Birim Fiyat"}
        currencySymbol={currencySymbol}
        value={offerFormData.unitPrice}
        onChange={handleChange}
      /> */}

      <InputPrice
        type={"text"}
        placeholder={"Birim Fiyat"}
        title={"Birim Fiyat"}
        currencySymbol={currencySymbol}
        value={unitPrice}
        onChange={handleChange}
      />

      <InputPrice
        type={"text"}
        placeholder={"Toplam"}
        title={"Toplam"}
        currencySymbol={currencySymbol}
        value={totalPrice}
        onChange={handleChange}
      />

      <InputStandart
        required={true}
        errorBorder={offerFormDataErrors.deadlineError}
        type={"date"}
        name={"deadline"}
        placeholder={"Termin"}
        title={"Termin"}
        value={offerFormData.deadline}
        onChange={handleChange}
      />

      <div className="input-standart description-align">
        <label>Açıklama : </label>
        <textarea
          name="description"
          value={offerFormData.description}
          onChange={handleChange}
        />
      </div>

      <div className="input-standart note-align">
        <label>*Not : </label>
        <textarea
          name="note"
          value={offerFormData.note}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default BidOfferCard;
