import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function SupplierOffers({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [supplierOffersPage, setSupplierOffersPage] = useState(1);
  const [supplierOffersRowsPerPage, setSupplierOffersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setSupplierOfferssUseFetchRender,
    useFetchRender: supplierOfferssUseFetchRender,
    error: supplierOffersError,
    loading: supplierOffersLoading,
    data: supplierOffers,
    total: supplierOffersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${id}&Last=true&SortType=3&Page=${supplierOffersPage}&PageSize=${supplierOffersRowsPerPage}`,
    token
  );
  console.log(supplierOffers);

  const columns = [
    {
      key: "images",
      header: "Foto",
    },
    {
      key: "offerCompanyName",
      header: "Yayınlayan",
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "totalPrice",
      header: "Toplam Fiyat",
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setSupplierOfferssUseFetchRender(!supplierOfferssUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <>
      {supplierOffersError ? (
        <ErrorComponent error={supplierOffersError} />
      ) : supplierOffersLoading ? (
        <Loading />
      ) : supplierOffers !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={supplierOfferssUseFetchRender}
          setUseFetchRender={setSupplierOfferssUseFetchRender}
          total={supplierOffersTotal}
          page={supplierOffersPage}
          setPage={setSupplierOffersPage}
          rowsPerPage={supplierOffersRowsPerPage}
          setRowsPerPage={setSupplierOffersRowsPerPage}
          columns={columns}
          data={supplierOffers}
        />
      ) : (
        <div className="table-null-text">Firmanın oluşturduğu ihale yok!</div>
      )}
    </>
  );
}

export default withNamespaces()(SupplierOffers);
