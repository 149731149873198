import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import Select from "../form_components/Select/Select";
import { Input } from "antd";
import { AudioOutlined } from "@ant-design/icons";

const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

function SupportEditBox({
  t,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  handleOpenAddSuportModal,
}) {
  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);
        break;

      case "search":
        console.log("Bul a tıklandı!");
        setUseFetchRender(!useFetchRender);

        break;

      case "add":
        handleOpenAddSuportModal();

        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: "", text: "Hepsi" },
    { key: 1, text: "Bekliyor" },
    { key: 2, text: "İnceleniyor" },
    { key: 3, text: "Çözüldü" },
    { key: 4, text: "Çözülemedi" },
    { key: 5, text: "Sonuçlandı" },
  ];

  const onSearch = (value) => console.log(value);

  return (
    <div className="edit-container">
      <div className="edit-box">
        
          <div className="edit-container-title">
            <div className="title">
              <i className="fa-solid fa-star" />
              <h4 className="title">Destek Talep</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={"Yenile"}
              onClick={() => handleClick("render")}
            />

            <Select
              name="filter"
              options={selectOptions}
              select={tableFilter}
              setSelect={setTableFilter}
              className="select-filter-sort"
            />

            <WhiteButton
              icon={<i className="fa-solid fa-plus" />}
              tooltip={"Talep Oluştur"}
              onClick={() => handleClick("add")}
              className="button-white-active"
            />
            
            <Search
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={onSearch}
            />

          </div>
        
      </div>
    </div>
  );
}

export default withNamespaces()(SupportEditBox);
