import './styles/settingPage.css';
import { useEffect, useState } from 'react';

import i18n from '../../i18n/i18n';
import { withNamespaces } from 'react-i18next';

import InputRadioText from '../../components/form_components/Inputs/InputRadioText/InputRadioText';

import AppStarIcon from "../../components/Icons/AppStarIcon";
import ThemeEditIcon from "../../components/Icons/ThemeEditIcon";
import { ThemeContextValue } from '../../context/ThemeContext';

function Settings({ t }) {

  const { setTheme } = ThemeContextValue();

  const getSettingValues = JSON.parse(localStorage.getItem("setting-values",));

  const initialSettingValues = {
    currency: "",
    browserNotifications: "",
    allowSupplierComments: "",
    systemLanguage: i18n.language,
    theme: "",
    welcomePictures: "",
  }

  const [settingFormValues, setSettingFormValues] = useState(getSettingValues ? getSettingValues : initialSettingValues);


  useEffect(() => {
    if (getSettingValues) {

      setSettingFormValues({ ...settingFormValues, systemLanguage: getSettingValues.systemLanguage });

    } else {

      setSettingFormValues({ ...settingFormValues, systemLanguage: i18n.language });
    }
  }, [])


  /* dil değiştirme */
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  function handleChange(e) {

    const { name, value } = e.target;

    setSettingFormValues((preValue) => {
      return {
        ...preValue,
        [name]: value
      }
    });

    if (name === 'theme') {

      setTheme(value);

    }

    if (name === 'systemLanguage') {
      changeLanguage(value);
    }

  }

  useEffect(() => {

    localStorage.setItem("setting-values", JSON.stringify(settingFormValues));

  }, [settingFormValues])



  return (
    <div className="settings-container">

      <form onChange={handleChange}>

        <div className="app-settings">

          <h4>
            <AppStarIcon />
            Uygulama Tercihleri
          </h4>

          {/* PARA BİRİMİ */}
          <div className='settings-boxes'>

            <h5>Para birimi</h5>

            <div className='settings-inputs'>
              <InputRadioText
                id="euro"
                name="currency"
                label="Euro"
                value="Euro"

                formValue={settingFormValues.currency}
              />

              <hr />

              <InputRadioText
                id="dolar"
                name="currency"
                label="Dolar"
                value="Dolar"

                formValue={settingFormValues.currency}
              />

              <hr />

              <InputRadioText
                id="sterlin"
                name="currency"
                label="Sterlin"
                value="Sterlin"

                formValue={settingFormValues.currency}
              />

              <hr />

              <InputRadioText
                id="frank"
                name="currency"
                label="Frank"
                value="Frank"

                formValue={settingFormValues.currency}
              />

              <hr />

              <InputRadioText
                id="türk-lirası"
                name="currency"
                label="Türk Lirası"

                value="Türk Lirası"

                formValue={settingFormValues.currency}
              />
            </div>
          </div>

          {/* TARAYICI BİLDİRİMLERİ */}
          <div className='settings-boxes'>

            <h5>Tarayıcı bildirimleri</h5>

            <div className='settings-inputs'>

              <InputRadioText
                id="notification-open"
                name="browserNotifications"
                label="Açık"
                value="open"

                formValue={settingFormValues.browserNotifications}
              />

              <hr />

              <InputRadioText
                id="notification-close"
                name="browserNotifications"
                label="Kapalı"
                value="close"

                formValue={settingFormValues.browserNotifications}
              />

            </div>

          </div>

          {/* TEDARİKÇİ YORUMLARI İZNİ */}
          <div className='settings-boxes'>

            <h5>Tedarikçi yorumlarına izin ver</h5>

            <div className='settings-inputs'>

              <InputRadioText
                id="comments-open"
                name="allowSupplierComments"
                label="Açık"
                value="open"

                formValue={settingFormValues.allowSupplierComments}
              />

              <hr />

              <InputRadioText
                id="comments-close"
                name="allowSupplierComments"
                label="Kapalı"
                value="close"

                formValue={settingFormValues.allowSupplierComments}
              />

            </div>

          </div>

        </div>

        <div className="theme-settings">
          <h4>
            <ThemeEditIcon />
            Arayüz Tercihleri
          </h4>

          {/* SİSTEM DİLİ */}
          <div className='settings-boxes'>

            <h5>Sistem dili</h5>

            <div className='settings-inputs'>

              <InputRadioText
                id="language-tr"
                name="systemLanguage"
                label="Türkçe"
                value="tr"

                formValue={settingFormValues.systemLanguage}
              />

              <hr />

              <InputRadioText
                id="language-en"
                name="systemLanguage"
                label="English"
                value="en"

                formValue={settingFormValues.systemLanguage}
              />

              <hr />

              <InputRadioText
                id="language-de"
                name="systemLanguage"
                label="Deutsch"
                value="de"

                formValue={settingFormValues.systemLanguage}
              />

            </div>

          </div>

          {/* RENK TEMASI */}
          <div className='settings-boxes'>

            <h5>Renk teması</h5>

            <div className='settings-inputs'>

              <InputRadioText
                id="theme-open"
                name="theme"
                label="Açık"
                value="light"

                formValue={settingFormValues.theme}
              />

              <hr />

              <InputRadioText
                id="theme-close"
                name="theme"
                label="Koyu"
                value="dark"

                formValue={settingFormValues.theme}
              />

            </div>

          </div>

          {/* KARŞILAMA RESİMLERİ */}
          <div className='settings-boxes'>

            <h5>Karşılama resimleri</h5>

            <div className='settings-inputs'>

              <InputRadioText
                id="welcomePictures-open"
                name="welcomePictures"
                label="Açık"
                value="open"

                formValue={settingFormValues.welcomePictures}
              />

              <hr />

              <InputRadioText
                id="welcomePictures-close"
                name="welcomePictures"
                label="Kapalı"
                value="close"

                formValue={settingFormValues.welcomePictures}
              />

            </div>

          </div>

        </div>
      </form>

    </div>
  )
}

export default withNamespaces()(Settings);
