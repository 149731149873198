function UsersIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.56663 8.06084H4.92432C4.04781 8.02671 3.17413 8.18702 2.35752 8.53182C1.5409 8.87663 0.798772 9.39857 0.177187 10.0653L0 10.288V20H3.01218V13.1355L3.41823 12.6424L3.6028 12.4117C4.56399 11.3478 5.76063 10.565 7.0801 10.1368C6.41949 9.59528 5.89847 8.88061 5.56663 8.06084Z" />
            <path d="M19.845 10.0413C19.2234 9.37464 18.4813 8.8527 17.6646 8.5079C16.848 8.16309 15.9743 8.00278 15.0978 8.03691C14.829 8.03773 14.5604 8.05366 14.2931 8.08463C13.955 8.85371 13.4483 9.52256 12.8165 10.0334C14.2252 10.4532 15.5012 11.2781 16.5079 12.4196L16.6925 12.6423L17.0912 13.1355L17.0833 20H20V10.264L19.845 10.0413Z" />
            <path d="M4.90218 6.50963H5.13105C5.02471 5.52591 5.18492 4.53005 5.59235 3.64227C5.99977 2.75449 6.637 2.01273 7.4271 1.50653C7.14069 1.03514 6.74556 0.651956 6.28041 0.394515C5.81525 0.137074 5.296 0.0141979 4.77352 0.0379195C4.25104 0.061641 3.74324 0.231147 3.29983 0.529838C2.85643 0.82853 2.49262 1.24617 2.24404 1.74185C1.99547 2.23754 1.87064 2.79428 1.88178 3.35756C1.89292 3.92084 2.03966 4.47135 2.30761 4.95517C2.57557 5.439 2.95556 5.83956 3.41037 6.11763C3.86519 6.39569 4.37924 6.54173 4.90218 6.54145V6.50963Z" />
            <path d="M14.7435 5.9132C14.7524 6.09602 14.7524 6.27921 14.7435 6.46203C14.8851 6.48623 15.0282 6.49952 15.1717 6.5018H15.3119C15.8326 6.47189 16.3372 6.29725 16.7766 5.99487C17.216 5.6925 17.5752 5.2727 17.8193 4.77634C18.0634 4.27998 18.1841 3.72399 18.1695 3.16248C18.155 2.60097 18.0058 2.05308 17.7363 1.57216C17.4669 1.09124 17.0865 0.693673 16.6321 0.418165C16.1778 0.142657 15.6649 -0.00140279 15.1436 1.02964e-05C14.6222 0.00142338 14.11 0.148262 13.6569 0.426229C13.2039 0.704197 12.8253 1.10382 12.5581 1.5862C13.2263 2.05623 13.7758 2.69762 14.1572 3.45283C14.5386 4.20804 14.7401 5.05344 14.7435 5.9132Z" />
            <path d="M9.90034 9.47653C11.7229 9.47653 13.2004 7.88469 13.2004 5.92106C13.2004 3.95744 11.7229 2.3656 9.90034 2.3656C8.07774 2.3656 6.60023 3.95744 6.60023 5.92106C6.60023 7.88469 8.07774 9.47653 9.90034 9.47653Z" />
            <path d="M10.0775 11.3696C9.11336 11.3277 8.15129 11.4963 7.2492 11.8654C6.34711 12.2345 5.52364 12.7963 4.82835 13.5172L4.64378 13.7399V18.7748C4.64666 18.9388 4.67951 19.1006 4.74043 19.251C4.80136 19.4013 4.88918 19.5372 4.99888 19.6509C5.10857 19.7647 5.23799 19.8541 5.37975 19.9139C5.5215 19.9738 5.67281 20.0029 5.82503 19.9998H14.3079C14.4601 20.0029 14.6114 19.9738 14.7531 19.9139C14.8949 19.8541 15.0243 19.7647 15.134 19.6509C15.2437 19.5372 15.3315 19.4013 15.3925 19.251C15.4534 19.1006 15.4862 18.9388 15.4891 18.7748V13.7558L15.3119 13.5172C14.6211 12.7941 13.8005 12.2307 12.9005 11.8614C12.0004 11.4921 11.0398 11.3247 10.0775 11.3696Z" />
        </svg>

    )
}

export default UsersIcon;
