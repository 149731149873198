import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import "./bannerAddForm.css";
import { useEffect, useState } from "react";

// Function to format date in "yyyy-MM-dd" format
const formatDateToISODate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const BannerAddForm = ({ onSubmit, onCancel, allBannersTotal }) => {
  const { loginUserCompanyId, userRole } = AuthContextValue();
  const {
    companyDetail,
    allCompaniesError,
    allCompaniesLoading,
    allCompaniesData,
    allCompaniesTotalData,
  } = GetContextValue();

  const [bannerImage, setBannerImage] = useState("");
  const [addedBanner, setAddedBanner] = useState({
    companyId: loginUserCompanyId ? loginUserCompanyId : "",
    index: allBannersTotal + 1,
    displayTime: "",
    startDate: "",
    finishDate: "",
    images: [],
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    const formattedValue =
      name === "startDate" || name === "finishDate"
        ? formatDateToISODate(value)
        : value;

    setAddedBanner((prevBanner) => ({
      ...prevBanner,
      [name]: formattedValue,
    }));
  };

  // Function to handle image input changes
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    setBannerImage(URL.createObjectURL(imageFile));

    const reader = new FileReader();

    reader.onloadend = () => {
      setAddedBanner((prevBanner) => ({
        ...prevBanner,
        images: [reader.result.split(",")[1]], // Set the base64 encoded image data as the new image value
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  // Function to handle company selection
  const handleCompanySelect = (e) => {
    const selectedCompanyId = e.target.value;
    const selectedCompany = allCompaniesData.find(
      (company) => Number(company.companyId) === Number(selectedCompanyId)
    );

    setAddedBanner((prevBanner) => ({
      ...prevBanner,
      companyId: selectedCompanyId,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(addedBanner);
  };

  return (
    <form onSubmit={handleSubmit} className="banner-add-form-container">
      <div className="form-group">
        <label>Fotoğraf:</label>
        {bannerImage && (
          <div className="image-box">
            <img src={bannerImage} alt="videoImage" />
          </div>
        )}

        <label htmlFor="image" className="change-image-label">
          Fotoğrafı Ekle
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
        />
      </div>
      <div className="form-group">
        <label htmlFor="companyName">Firma:</label>
        {userRole === "Admin" ? (
          <select
            id="companyId"
            name="companyId"
            value={addedBanner.companyId}
            onChange={handleCompanySelect}
          >
            <option value="" disabled>
              Firma Seçin
            </option>
            {allCompaniesLoading ? (
              <option value="" disabled>
                Loading...
              </option>
            ) : (
              allCompaniesData.map((company) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </option>
              ))
            )}
          </select>
        ) : (
          <h4>{companyDetail?.companyName}</h4>
        )}
      </div>
      {userRole === "Admin" && (
        <div className="form-group">
          <label htmlFor="index">Sırası:</label>
          <input
            type="number"
            id="index"
            name="index"
            value={addedBanner.index}
            onChange={handleChange}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="displayTime">Süresi (Saniye):</label>
        <input
          type="text"
          id="displayTime"
          name="displayTime"
          value={addedBanner.displayTime}
          onChange={handleChange}
        />
        <p className="note">Lütfen süreyi saniye cinsinden giriniz.</p>
      </div>
      <div className="date-container">
        <div className="form-group">
          <label htmlFor="startDate">Başlangıç Tarihi:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formatDateToISODate(addedBanner.startDate)}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="finishDate">Bitiş Tarihi:</label>
          <input
            type="date"
            id="finishDate"
            name="finishDate"
            value={formatDateToISODate(addedBanner.finishDate)}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-buttons">
        <button type="submit">Kaydet</button>
        <button
          type="button"
          onClick={() => {
            onCancel();
            setBannerImage("");
          }}
        >
          İptal
        </button>
      </div>
    </form>
  );
};

export default BannerAddForm;
