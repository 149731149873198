import "./styles/App/app.css";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import i18n from "./i18n/i18n";

import { ThemeContextValue } from "./context/ThemeContext";

import Layout from "./Layout";

import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import Notifications from "./pages/Notifications/Notifications";
import Reports from "./pages/Reports/Reports";
import Settings from "./pages/Settings/Settings";

import OpportunitiesLayout from "./pages/Opportunities/OpportunitiesLayout";
import Opportunities from "./pages/Opportunities/Opportunities";
import ConfirmationOffers from "./pages/Offers/ConfirmationOffers";
import RevisedOffers from "./pages/Offers/RevisedOffers";

import MainCompaniesLayout from "./pages/MainCompanies/MainCompaniesLayout";
import MainCompanies from "./pages/MainCompanies/MainCompanies";

import TendersLayout from "./pages/Tenders/TendersLayout";
import Tenders from "./pages/Tenders/Tenders";
import TenderDetail from "./pages/Tenders/TenderDetail";

import SuppliersLayout from "./pages/Suppliers/SuppliersLayout";
import Suppliers from "./pages/Suppliers/Suppliers";

import Users from "./pages/Users/Users";
import UserProfile from "./pages/Users/UserProfile";
import UsersLayout from "./pages/Users/UsersLayout";
import UserAdd from "./pages/Users/UserAdd";
import UserEdit from "./pages/Users/UserEdit";

import Offers from "./pages/Offers/Offers";
import OfferDetail from "./pages/Offers/OfferDetail";
import TenderOfferDetail from "./pages/Tenders/TenderOfferDetail";

import ConfirmationTenders from "./pages/Tenders/ConfirmationTenders";
import RevisedTenders from "./pages/Tenders/RevisedTenders";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

import CompanyListLayout from "./pages/CompanyList/CompanyListLayout";
import CompanyList from "./pages/CompanyList/CompanyList";
import CompanyAdd from "./pages/CompanyList/CompanyAdd";
import CompanyProfile from "./pages/CompanyList/CompanyProfile";

import ProfileLayout from "./pages/Profile/ProfileLayout";
import ProfileEdit from "./pages/Profile/ProfileEdit";

import CompanyEdit from "./pages/CompanyList/CompanyEdit";
import CompanyProfileEdit from "./pages/CompanyProfileEdit/CompanyProfileEdit";

import TenderAdd from "./pages/Tenders/TenderAdd/TenderAdd";
import TenderDetailOffer from "./pages/Opportunities/TenderDetailOffer";
import TenderDetailManager from "./pages/Tenders/TenderDetailManager";
import TenderDetailSupplier from "./pages/Opportunities/TenderDetailSupplier";

import SupplierAdd from "./pages/Suppliers/SupplierAdd";

import CompanyApplicationForm from "./pages/CompanyApplicationForm/CompanyApplicationForm";
import CompaniesApplying from "./pages/CompaniesApplying/CompaniesApplying";
import TenderEdit from "./pages/Tenders/TenderEdit/TenderEdit";
import SumbittedOffers from "./pages/Opportunities/SumbittedOffers";
import OffersLayout from "./pages/Offers/OffersLayout";
import OfferEdit from "./pages/Offers/OfferEdit";
import TenderChange from "./pages/Tenders/TenderChange/TenderChange";
import HowItWork from "./pages/HowItWork/HowItWork";

import HelpButton from "./components/HelpButton/HelpButton";
import { AuthContextValue } from "./context/AuthContext";
import AdminSupport from "./pages/AdminSupport/AdminSupport";
import AllTenders from "./pages/AllTenders/AllTenders";
import AllTendersLayout from "./pages/AllTenders/AllTendersLayout";
import AllOffersLayout from "./pages/AllOffers/AllOffersLayout";
import AllOffers from "./pages/AllOffers/AllOffers";
import UserSupportRequest from "./pages/UserSupportRequest/UserSupportRequest";
import BannersPanel from "./pages/BannersPanel/BannersPanel";
import BannersCompany from "./pages/BannersCompany/BannersCompany";
import Page500 from "./pages/ErrorPages/Page500";
import Page404 from "./pages/ErrorPages/Page404";

const localStorageSettingValues = JSON.parse(
  localStorage.getItem("setting-values")
);

function App() {
  const { pathname } = useLocation();
  const routePath = pathname.split("/")[1];
  const { theme } = ThemeContextValue();
  const { token, userRole } = AuthContextValue();

  useEffect(() => {
    if (localStorageSettingValues && localStorageSettingValues.systemLanguage) {
      i18n.changeLanguage(localStorageSettingValues.systemLanguage);
    } else {
      i18n.changeLanguage("tr");
      localStorage.setItem(
        "setting-values",
        JSON.stringify({
          ...localStorageSettingValues,
          systemLanguage: "tr",
          theme: theme,
        })
      );
    }
  }, [token]);

  const selectTheme =
    theme === "light"
      ? "light-theme-styles"
      : theme === "dark"
      ? "dark-theme-styles"
      : "light-theme-styles";

  return (
    <div
      className={`app ${
        theme === "light"
          ? "bg-light-image"
          : theme === "dark"
          ? "bg-dark-image"
          : "bg-light-image"
      } ${selectTheme}`}
    >
      <Routes>
        {/* Giriş sayfası */}
        <Route path="/login" element={<Login />} />

        {/* Kayıt Sayfası */}
        <Route path="/register/:userId/:refToken" element={<Register />} />

        {/* şifresini unutan kullanıcının emalini girdiği sayfa */}
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/*maildeki linke tıklayında yeni şifreleri girdiğimiz sayfa*/}
        <Route
          path="/password-reset-form/:userId/:resetToken"
          element={<ResetPassword />}
        />

        {/* Davet edilen tedarikçinin referans kod ile başvuru yaptığı form sayfası */}
        <Route
          path="/invited-supplier-form/:refCode"
          element={<CompanyApplicationForm />}
        />

        {/* Hata sayfası */}
        <Route path="*" element={<Page404 />} />
        <Route path="/error" element={<Page500 />} />


        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/notifications" element={<Notifications />} />

          {/* Admin sayfaları */}
          <Route path="company-list" element={<CompanyListLayout />}>
            <Route index element={<CompanyList />} />
            <Route
              path="company-profile/:companyId"
              element={<CompanyProfile />}
            />
            <Route path="company-add" element={<CompanyAdd />} />
            <Route path="company-edit/:companyId" element={<CompanyEdit />} />
          </Route>
          <Route path="companies-applying" element={<CompaniesApplying />} />
          <Route
            path="/support-request"
            element={
              userRole === "Admin" ? <AdminSupport /> : <UserSupportRequest />
            }
          />

          <Route path="/all-tender" element={<AllTendersLayout />}>
            <Route path="/all-tender" element={<AllTenders />} />
            <Route path="tender-detail/:tenderId" element={<TenderDetail />} />
          </Route>

          <Route path="/all-offer" element={<AllOffersLayout />}>
            <Route path="/all-offer" element={<AllOffers />} />
            <Route path="offer-detail/:offerId" element={<OfferDetail />} />
          </Route>

          <Route path="/banner-panel" element={<BannersPanel />} />

          {/* Admin sayfaları - bitiş*/}

          <Route path="/banner-company" element={<BannersCompany />} />

          <Route path="/opportunities" element={<OpportunitiesLayout />}>
            <Route index element={<Opportunities />} />

            <Route
              path="/opportunities/sumbitted-offers"
              element={<SumbittedOffers />}
            />

            {/* Tedarikçinin ihaleye teklif verdiği sayfa */}
            <Route
              path="tender-detail-offer/:tenderId"
              element={<TenderDetailOffer />}
            />

            {/* Tedarikçinin gördüğü ihale detay sayfası */}
            <Route
              path="tender-detail-supplier/:tenderId"
              element={<TenderDetailSupplier />}
            />

            {/* Teklif detaylarını gösteren sayfa */}
            <Route
              path="tender-detail-supplier/:tenderId/offer-detail/:offerId"
              element={<OfferDetail />}
            />

            <Route path="offer-detail/:offerId" element={<OfferDetail />} />
          </Route>

          <Route path="/offers" element={<OffersLayout />}>
            <Route index element={<Offers />} />
            <Route
              path="confirmation-offers"
              element={<ConfirmationOffers />}
            />
            <Route path="revised-offers" element={<RevisedOffers />} />
            <Route path="offer-detail/:offerId" element={<OfferDetail />} />
            <Route path="offer-edit/:offerId" element={<OfferEdit />} />
            <Route path="offer-detail/:offerId" element={<OfferDetail />} />
          </Route>

          <Route path="offer-detail/:offerId" element={<OfferDetail />} />

          <Route path="/maincompanies" element={<MainCompaniesLayout />}>
            <Route index element={<MainCompanies />} />
            <Route
              path="company-profile/:companyId"
              element={<CompanyProfile />}
            />
          </Route>

          <Route path="/tenders" element={<TendersLayout />}>
            <Route index element={<Tenders />} />
            <Route
              path="confirmation-tenders"
              element={<ConfirmationTenders />}
            />
            <Route path="revised-tenders" element={<RevisedTenders />} />

            <Route path="tender-detail/:tenderId" element={<TenderDetail />}>
              {/* <Route index  element={<Users/>}/> */}
            </Route>
            {/* Hazırlanan ihaleyi yöneticinin onayladığı sayfa */}
            <Route
              path="tender-detail-manager/:tenderId"
              element={<TenderDetailManager />}
            />

            <Route
              path="tender-offer-detail/:offerId"
              element={<TenderOfferDetail />}
            />

            <Route path="tender-add" element={<TenderAdd />} />
            <Route path="tender-edit/:tenderId" element={<TenderEdit />} />
            <Route
              path="tender-change/:tenderNumber"
              element={<TenderChange />}
            />
          </Route>

          <Route path="/suppliers" element={<SuppliersLayout />}>
            <Route index element={<Suppliers />} />
            <Route path="supplier-add" element={<SupplierAdd />} />

            <Route
              path="company-profile/:companyId"
              element={<CompanyProfile />}
            />
          </Route>

          <Route path="/reports" element={<Reports />} />

          <Route
            path="/company-profile-edit"
            element={<CompanyProfileEdit />}
          />

          <Route path="/users" element={<UsersLayout />}>
            <Route path="/users" element={<Users />} />
            <Route
              path="/users/user-profile/:userId"
              element={<UserProfile />}
            />
            <Route path="/users/user-edit/:userId" element={<UserEdit />} />

            <Route path="/users/user-add" element={<UserAdd />} />
          </Route>

          <Route path="/profile" element={<ProfileLayout />}>
            {/* <Route path="/profile" element={<Profile />}>
              <Route index element={<UserTenders />} />
              <Route path="user-tenders" element={<UserTenders />} />
              <Route path="user-offers" element={<UserOffers />} />
            </Route> */}
            <Route path="/profile/:userId" element={<UserProfile />} />
            <Route path="profile-edit/:userId" element={<ProfileEdit />} />
          </Route>

          <Route path="/settings" element={<Settings />} />

          <Route path="/how-it-work" element={<HowItWork />} />
        </Route>
      </Routes>
      {routePath !== "login" &&
        routePath !== "forgot-password" &&
        routePath !== "password-reset-form" &&
        routePath !== "invited-supplier-form" &&
        routePath !== "register" && <HelpButton />}
    </div>
  );
}

export default App;
