import "./login.css";
import "../../styles/materiel/materiel.css";
import images from "../../assets/images/index";

import { useEffect, useRef, useState } from "react";

import { withNamespaces } from "react-i18next";
import i18n from "../../i18n/i18n";

import { AuthContextValue } from "../../context/AuthContext";

import InputLogin from "../../components/form_components/Inputs/InputLogin/InputLogin";
import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";

import LoginIcon from "../../components/Icons/LoginIcon";
import PasswordAskIcon from "../../components/Icons/PasswordAskIcon";
import StarTicIcon from "../../components/Icons/StarTicIcon";
import { Link } from "react-router-dom";

import icons from "../../assets/icons/index";
import { CrudContextValue } from "../../context/CrudContext";
import LangBox from "../../components/LangBox/LangBox";

const { offerexLogoLight } = icons;

const settings = JSON.parse(localStorage.getItem("setting-values"));

function Login({ t }) {
  const { token, authLogin, errorMessage, setErrorMessage, authLoading } =
    AuthContextValue();
  const { createMethod } = CrudContextValue();

  const formElementRef = useRef(null);

  const handleButtonClick = () => {
    if (formElementRef.current) {
      formElementRef.current.scrollIntoView({
        behavior: "smooth", // Animasyonlu kaydırma
        block: "start", // Elemanın üst tarafında görünmesi için
      });
      formElementRef.current.focus();
    }
  };

  const [loading, setLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    setErrorMessage("");
  }, [login]);

  function handleChangeLogin(e) {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  }

  function handleSubmitLogin(e) {
    e.preventDefault();

    if (login.email && login.password) {
      authLogin(process.env.REACT_APP_POST_LOGIN_URL, JSON.stringify(login));
    } else {
      alert(t("message_emty_form"));
    }
  }

  const [applicationForm, setApplicationForm] = useState({
    companyName: "",
    managerEmail: "",
    companyType: 1,
    companyEmail: "",
    phoneNumber: "",
    description: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "companyType") {
      setApplicationForm({
        ...applicationForm,
        [name]: Number(value),
      });
    } else {
      setApplicationForm({
        ...applicationForm,
        [name]: value,
      });
    }
  }

  async function handleSubmitApply(e) {
    e.preventDefault();

    console.log("applicationForm : ", applicationForm);

    setApplyLoading(true);

    await createMethod(
      process.env.REACT_APP_POST_SUPPLIER_REGISTRATION_URL,
      JSON.stringify(applicationForm),
      token
    );

    console.log(applicationForm);

    setApplyLoading(false);

    setApplicationForm({
      companyName: "",
      managerEmail: "",
      companyType: 1,
      companyEmail: "",
      phoneNumber: "",
      description: "",
    });
  }

  function handleChangeLang(lang) {
    i18n.changeLanguage(lang);

    if (settings) {
      settings.systemLanguage = lang;

      console.log(settings);

      localStorage.setItem("setting-values", JSON.stringify(settings));
    }
  }

  return (
    <div className="login-container">
      <div className="login-main-box">
        <img src={offerexLogoLight} alt="offerex-logo" />

        <h3>{t("title_login_main_title")}</h3>

        <ul>
          <li>{t("title_login_main_subtitle1")}</li>
          <li>{t("title_login_main_subtitle2")}</li>
          <li>{t("title_login_main_subtitle3")}</li>
        </ul>

        <div className="login-button">
          <button type="button" onClick={handleButtonClick}>
            Giriş veya Kayıt
          </button>
        </div>
      </div>

      <div className="login-form-box" ref={formElementRef}>
        <div className="login-form-inputs">
          <div className="login-box">
            <form className="login-form-login" onSubmit={handleSubmitLogin}>
              <h4>{t("title_form_login")}</h4>

              <div className="inputs">
                <InputLogin
                  required={true}
                  type={"email"}
                  title={t("input_email")}
                  name={"email"}
                  placeholder={`${t("input_email")}...`}
                  // errorMessage={errorMessage}
                  errorBorder={errorMessage}
                  onChange={handleChangeLogin}
                  value={login.email}
                />

                <InputLogin
                  required={true}
                  type={"password"}
                  title={t("input_password")}
                  name={"password"}
                  placeholder={`${t("input_password")}...`}
                  errorMessage={errorMessage}
                  errorBorder={errorMessage}
                  onChange={handleChangeLogin}
                  value={login.password}
                />
              </div>

              <div className="login-buttons-group">
                <ButtonWhiteOpacity
                  disabled={applyLoading || authLoading ? true : false}
                  icon={authLoading ? null : <LoginIcon />}
                  text={authLoading ? t("loading") : t("button_form_login")}
                  type="submit"
                />

                <Link to="/forgot-password">
                  <ButtonWhiteOpacity
                    disabled={applyLoading || authLoading ? true : false}
                    icon={<PasswordAskIcon />}
                    text={t("button_form_forgot_password")}
                    className="button-white-opacity"
                    type="button"
                  />
                </Link>
              </div>
            </form>
          </div>

          <div className="info-box">
            <p>{t("title_form_login_description1")}</p>

            {/* <br /> */}

            <p>{t("title_form_login_description2")}</p>
          </div>

          <div className="apply-box">
            <form className="login-form-apply" onSubmit={handleSubmitApply}>
              <h4>{t("title_form_apply")}</h4>

              <div className="inputs">
                <InputLogin
                  required={true}
                  type={"text"}
                  title={"Firma Adı"}
                  name={"companyName"}
                  placeholder={"Firma Adı..."}
                  value={applicationForm.companyName}
                  onChange={handleChange}
                />

                <InputLogin
                  required={true}
                  type={"email"}
                  title={"E-Posta"}
                  name={"managerEmail"}
                  placeholder={"Yönetici E-Posta"}
                  value={applicationForm.managerEmail}
                  onChange={handleChange}
                />

                <div className="role-select-container">
                  <label htmlFor="companyType">* Firma Tipi : </label>
                  <select
                    required
                    name="companyType"
                    id="companyType"
                    value={applicationForm.companyType}
                    onChange={handleChange}
                  >
                    <option value={1}>Ana Firma</option>
                    <option value={2}>Tedarikçi</option>
                    <option value={3}>Ana Firma - Tedarikçi</option>
                  </select>
                </div>

                <InputLogin
                  required={true}
                  type={"tel"}
                  title={t("input_phone")}
                  name={"phoneNumber"}
                  placeholder={`${t("input_phone")}...`}
                  value={applicationForm.phoneNumber}
                  onChange={handleChange}
                />

                <InputLogin
                  type={"email"}
                  title={"F. E-Postası"}
                  name={"companyEmail"}
                  placeholder={`Firma E-Postası...`}
                  value={applicationForm.companyEmail}
                  onChange={handleChange}
                />

                <div className="descriptipon-container">
                  <label htmlFor="description">Açıklama : </label>
                  <textarea
                    name="description"
                    cols="10"
                    id="description"
                    placeholder="Açıklama"
                    value={applicationForm.description}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <ButtonWhiteOpacity
                disabled={applyLoading || authLoading ? true : false}
                icon={applyLoading ? null : <StarTicIcon />}
                text={applyLoading ? "Loading..." : t("button_form_apply")}
                type={"submit"}
              />
            </form>
          </div>
        </div>

        <div className="lang-box">
          <LangBox />
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Login);
