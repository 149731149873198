import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import Select from "../form_components/Select/Select";

function BannerPanelEditBox({
  t,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  handleOpenAddBannerModal,
}) {
  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);
        break;
      case "add":
        handleOpenAddBannerModal();

        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: "", text: "Hepsi" },
    { key: 1, text: "Onay Bekleyen" },
    { key: 2, text: "Aktif" },
    { key: 3, text: "Pasif" },
  ];

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <div className="title">
              <i className="fa-regular fa-images"></i>
              <h4 className="title">Reklam Paneli</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={"Yenile"}
              onClick={() => handleClick("render")}
            />

            <Select
              name="filter"
              options={selectOptions}
              select={tableFilter}
              setSelect={setTableFilter}
              className="select-filter-sort"
            />

            <WhiteButton
              icon={<i className="fa-solid fa-plus" />}
              tooltip={"Yeni Reklam"}
              onClick={() => handleClick("add")}
              className="button-white-active"
            />
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(BannerPanelEditBox);
