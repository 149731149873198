import React from "react";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import "./uploadImageBox.css";

import { useEffect, useState } from "react";

function UploadImageBox({ images, setImages, formData, setFormData }) {
  const { token } = AuthContextValue();
  const { deleteMethod } = CrudContextValue();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function generateId() {
    const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
    return id.toString(); // sayıyı string'e dönüştür ve geri döndür
  }

  function handleDrop(files) {
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        if (images.map((img) => img.base64).includes(e.target.result)) {
          alert("Bu resim zaten var!");
        } else {
          setImages(
            images.length > 0
              ? [...images, { id: generateId(), base64: e.target.result }]
              : [{ id: generateId(), base64: e.target.result }]
          );
            console.log({
              ...formData,
              images:
                formData.images.length < 1
                  ? [e.target.result.split("base64,")[1]]
                  : [...formData.images, e.target.result.split("base64,")[1]],
            });
          setFormData({
            ...formData,
            images:
              formData.images.length < 1
                ? [e.target.result.split("base64,")[1]]
                : [...formData.images, e.target.result.split("base64,")[1]],
          });
        }
      };
    }
  }

  async function handleDelete(image) {
    const newImages = images.filter((img) => img.id !== image.id);
    console.log(image);
    console.log(currentImageIndex);
    if (currentImageIndex !== 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
    if (image.url && image.url.includes("/api/Images")) {
      setImages(newImages);

      const imagePath = image.url.split("images/")[1];
      console.log(imagePath);

      await deleteMethod(
        `http://sosocrm.oetech.online/api/Images/Delete-Images?file=${imagePath}`,
        token
      );
    } else {
      setImages(newImages);

      const newTenderImage = formData.images.filter(
        (prevImage) => prevImage !== image.base64.split("base64,")[1]
      );

      setFormData({ ...formData, images: newTenderImage });
    }
  }

  function handleNext() {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length ? 0 : prevIndex + 1
      );
    } else {
      setCurrentImageIndex(0);
    }
  }


  return (
    <>
      {images && images.length > 0 ? (
        <div className="upload-image-container">
          <img
            src={
              images[currentImageIndex].url
                ? images[currentImageIndex].url
                : images[currentImageIndex].base64
            }
            alt="image"
          />

          <button
            className="delete-img-button"
            onClick={() => handleDelete(images[currentImageIndex])}
          >
            Sil
          </button>

          <button className="next-img-button" onClick={handleNext}>
            {" "}
            ileri{" "}
          </button>

          <button
            className="new-img-button"
            onClick={() => document.getElementById("fileInput").click()}
          >
            +
          </button>
          <input
            style={{ display: "none" }}
            type="file"
            id="fileInput"
            onChange={(e) => handleDrop(e.target.files)}
            multiple
          />
        </div>
      ) : (
        <div className="upload-img-btn">
          <button onClick={() => document.getElementById("fileInput").click()}>
            Yükle
          </button>
          <input
            style={{ display: "none" }}
            type="file"
            id="fileInput"
            onChange={(e) => handleDrop(e.target.files)}
            multiple
          />
        </div>
      )}
    </>
  );
}

export default React.memo(UploadImageBox);
