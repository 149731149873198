import "./styles/supportRequestLayout/supportRequestLayout.css";
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import SupportRequestForm from "./SupportRequestForm";
import SupportRequest from "./SupportRequest";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import AdminSupport from "../../pages/AdminSupport/AdminSupport";
import { GetContextValue } from "../../context/GetContext";

const { TabPane } = Tabs;

function SupportRequestLayout({ handleCloseModal, supportRequestOpen }) {
  const { loginUserCompanyId, loginUserId, token, userRole } =
    AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [searchText, setSearchText] = useState("");

  // tablo filtreleme
  // 1 = "Bekliyor"
  // 2 = "İnceleniyor"
  // 3 = "Çözüldü"
  // 4 = "Çözülemedi"
  // 5 = "Sonuçlandı"
  const [tableFilter, setTableFilter] = useState(1);
  const [supportRequestPage, setSupportRequestPage] = useState(1);
  const [supportRequestRowsPerPage, setSupportRequestRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setSupportRequestUseFetchRender,
    useFetchRender: supportRequestUseFetchRender,
    error: supportRequestError,
    loading: supportRequestLoading,
    data: supportRequest,
    total: supportRequestTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_SUPPORT_LIST_URL}?CretedUserId=${loginUserId}&CompanyId=${loginUserCompanyId}&Status=${tableFilter}&SupportNumber=${searchText}&Page=${supportRequestPage}&Page=${supportRequestPage}&PageSize=${supportRequestRowsPerPage}`,
    token
  );

  useEffect(() => {
    setSupportRequestUseFetchRender(!supportRequestUseFetchRender);
  }, [notificationRender, supportRequestOpen, activeTab]);

  return (
    <Tabs
      activeKey={activeTab}
      onChange={handleTabChange}
      className="support-request-layout-container"
    >
      <TabPane tab="Talepler" key="1">
        {userRole === "Admin" ? (
          <AdminSupport />
        ) : (
          <SupportRequest
            page={supportRequestPage}
            setPage={setSupportRequestPage}
            rowsPerPage={supportRequestRowsPerPage}
            setRowsPerPage={setSupportRequestRowsPerPage}
            total={supportRequestTotal}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            setSupportRequestUseFetchRender={setSupportRequestUseFetchRender}
            supportRequestUseFetchRender={supportRequestUseFetchRender}
            supportRequest={supportRequest}
            supportRequestError={supportRequestError}
            supportRequestLoading={supportRequestLoading}
          />
        )}
      </TabPane>

      <TabPane tab="Talep Gönder" key="2">
        <SupportRequestForm
          handleCloseModal={handleCloseModal}
          setActiveTab={setActiveTab}
          setSupportRequestUseFetchRender={setSupportRequestUseFetchRender}
          supportRequestUseFetchRender={supportRequestUseFetchRender}
        />
      </TabPane>
    </Tabs>
  );
}

export default SupportRequestLayout;
