import "./adminTicketBox.css";
import React, { useState, useEffect } from "react";

import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";
import { Button, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { CrudContextValue } from "../../../context/CrudContext";
import { GetContextValue } from "../../../context/GetContext";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const ticketStatusMapOpt = [
  {
    value: 1,
    label: "Bekliyor",
  },
  {
    value: 2,
    label: "İnceleniyor",
  },
  {
    value: 3,
    label: "Çözüldü",
  },
  {
    value: 4,
    label: "Çözülemedi",
  },
  {
    value: 5,
    label: "Sonuçlandı",
  },
];

function AdminTicketBox({ supportNumber }) {
  const [ticket, setTicket] = useState({});
  const [ticketStatus, setTicketStatus] = useState(1);
  const [ticketColor, setTicketColor] = useState("#fff1b8");
  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();
  const { notificationRender } = GetContextValue();

  const {
    setUseFetchRender: setTicketUseFetchRender,
    useFetchRender: ticketUseFetchRender,
    error: ticketError,
    loading: ticketLoading,
    data: ticketData,
    total: ticketTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_SUPPORT_LIST_URL
    }?SupportNumber=${supportNumber}&Page=${1}&PageSize=${1}`,
    token
  );
  useEffect(() => {
    if (ticketData && ticketData[0]) {
      setTicket(ticketData[0]);
      setTicketStatus(ticketData[0].status);
      setTicketColor(
        ticketData[0].status === 1
          ? "#fff1b8"
          : ticketData[0].status === 2
          ? "#ffd8bf"
          : ticketData[0].status === 3
          ? "#d9f7be"
          : ticketData[0].status === 4
          ? "#ffccc7"
          : ticketData[0].status === 5
          ? "#b5f5ec"
          : "#fff1b8"
      );
    }
  }, [ticketData]);

  useEffect(() => {
    setTicketUseFetchRender(!ticketUseFetchRender);
  }, [notificationRender, supportNumber]);

  const [statusLoading, setStatusLoading] = useState(false);
  const [descriptionLoading, setDescriptionLoading] = useState(false);

  const [adminResponse, setAdminResponse] = useState("");

  const [photo, setPhoto] = useState(null);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  const handleOpenPhotoModal = (photo) => {
    setPhoto(photo);
    setPhotoModalOpen(true);
  };
  const handleClosePhotoModal = () => {
    setPhotoModalOpen(false);
  };

  // Function to handle sending the admin response
  const handleSendAdminResponse = async (supportNumber, status) => {
    if (!adminResponse) {
      alert("Lütfen açıklama giriniz");
      return;
    }
    setDescriptionLoading(true);
    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_UPDATE_SUPPORT_URL}?SupportNumber=${supportNumber}&Status=${status}&Description=${adminResponse}`,
      null,
      token
    );

    if (response && !response.catchError && !response.isError) {
      setAdminResponse("");
      setTicketUseFetchRender(!ticketUseFetchRender);
    } else {
      console.log({
        catchError: response.catchError,
        isError: response.isError,
      });
    }
    setDescriptionLoading(false);
  };

  const handleChangeStatus = async (supportNumber, newStatus, description) => {
    setStatusLoading(true);
    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_UPDATE_SUPPORT_URL}?SupportNumber=${supportNumber}&Status=${newStatus}&Description=${description}`,
      null,
      token
    );

    if (response && !response.catchError && !response.isError) {
      setTicketUseFetchRender(!ticketUseFetchRender);
    } else {
      console.log({
        catchError: response.catchError,
        isError: response.isError,
      });
    }
    setStatusLoading(false);
  };

  return (
    <>
      {ticketError ? (
        <ErrorComponent error={ticketError} />
      ) : ticketLoading ? (
        <Loading />
      ) : ticket && ticket.supportNumber ? (
        <div
          className="admin-ticket-box"
          style={{ backgroundColor: ticketColor }}
        >
          <h3>
            Talep Başlık : {ticket.title}{" "}
            <span className="date">{ticket.createdDate}</span>
          </h3>
          <hr />
          <p>
            {" "}
            <strong>Talep No :</strong> {ticket.supportNumber}
          </p>

          <p>
            {" "}
            <strong>Firma Adı :</strong> {ticket.companyName}
          </p>

          <p>
            {" "}
            <strong>Oluşturan Kullanıcı :</strong> {ticket.createdUserFullName}
          </p>

          <p>
            <strong>Talep Açıklama : </strong>
            {ticket.body}
          </p>

          <p>
            <strong>Talep Fotoğraf :</strong>{" "}
            <Button onClick={() => handleOpenPhotoModal(ticket.images[0])}>
              Fotoğrafı göster
            </Button>
          </p>

          <div className="ticket-status-container">
            <p className="ticket-status">Durum:</p>
            {/* Allow changing the ticket status */}
            <Select
                value={ticketStatus}
              defaultValue={ticketStatus}
              options={ticketStatusMapOpt}
              style={{
                width: 130,
              }}
              onChange={(value) =>
                handleChangeStatus(
                  ticket.supportNumber,
                  value,
                  ticket.description ? ticket.description : ""
                )
              }
            >
              {Object.entries(ticketStatusMapOpt).map(
                ([statusCode, statusLabel]) => (
                  <Select.Option key={statusCode} value={statusCode}>
                    {statusLabel}
                  </Select.Option>
                )
              )}
            </Select>
            {statusLoading && <Spin indicator={antIcon} />}
            <small>
              Düzenleme yaptığınızda durumu burdan güncelleyebilirsiniz
            </small>
          </div>

          {ticket.description ? (
            <div className="admin-reply">
              <h4>
                Admin Cevabı:{" "}
                <span className="date">{ticket.approvedDate}</span>
              </h4>
              <p>{ticket.description}</p>
            </div>
          ) : (
            <div className="admin-reply-form">
              <h4>
                Admin Cevabı :{" "}
                <span className="date">{ticket.approvedDate}</span>
              </h4>
              <textarea
                rows={4}
                value={adminResponse}
                onChange={(e) => setAdminResponse(e.target.value)}
              />
              <Button
                loading={descriptionLoading}
                onClick={() =>
                  handleSendAdminResponse(ticket.supportNumber, ticket.status)
                }
              >
                Gönder
              </Button>
            </div>
          )}

          {photoModalOpen && (
            <div
              className="photo-modal-container"
              onClick={handleClosePhotoModal}
            >
              <div className="photo-box">
                <button
                  className="close-button"
                  onClick={handleClosePhotoModal}
                >
                  x
                </button>
                <img className="ticket-image" src={photo} alt="Ticket" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="table-null-text">Destek talep mesajı yok</p>
      )}
    </>
  );
}

export default AdminTicketBox;
