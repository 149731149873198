import "./styles/forgotPassword.css";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import InputRadioText from "../../components/form_components/Inputs/InputRadioText/InputRadioText";
import { CrudContextValue } from "../../context/CrudContext";
import { AuthContextValue } from "../../context/AuthContext";
import { ThemeContextValue } from "../../context/ThemeContext";

import LangBox from "../../components/LangBox/LangBox";
import icons from "../../assets/icons/index";

const { offerexLogoDark, offerexLogoLight } = icons;

function ForgotPassword() {
  const { theme } = ThemeContextValue();
  const { createMethod } = CrudContextValue();

  const navigate = useNavigate();

  const [forgotPassLoading, setForgotPassLoading] = useState(false);

  const [email, setEmail] = useState("");

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "email") {
      setEmail(value);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    console.log("email : ", email);

    if (!email) {
      alert("Email alanı boş bırakılamaz!");
    } else {
      setForgotPassLoading(true);

      const responsePost = await createMethod(
        `http://sosocrm.oetech.online/api/Auth/password-reset?mail=${email}`,
        null,
        null
      );

      setForgotPassLoading(false);

      console.log(responsePost);

      if (responsePost) {
        toast.success(responsePost.data.data, {
          autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
        });
      } else {
        responsePost.data.errorMessageList.map((message) =>
          toast.error(message)
        );
      }
    }
  }

  return (
    <div className="forgot-password-container">
      <div className="offerex-logo-container">
        <img
          src={
            theme === "light"
              ? offerexLogoDark
              : theme === "dark"
              ? offerexLogoLight
              : offerexLogoDark
          }
          alt="offerex-logo"
        />
      </div>

      <form onSubmit={handleSubmit} className="forgot-password-box">
        <div className="forgot-password-main">
          <h4>Şifremi Unuttum</h4>

          <p>Şifrenizi sıfırlamak için lütfen mail adresinizi giriniz.</p>

          <div className="form-line">
            <InputStandart
              required={true}
              title={"E-Posta"}
              name="email"
              type="email"
              placeholder={`${"E-Posta"}...`}
              onChange={handleChange}
              value={email}
            />
          </div>

          <div className="forgot-password-buttons">
            <ButtonWhiteOpacity
              type="submit"
              text={forgotPassLoading ? "Loading..." : "Şifreyi Sıfırla"}
              className="button-apply"
              disabled={forgotPassLoading || !email}
            />

            <ButtonWhiteOpacity
              type={"button"}
              text={"Vazgeç"}
              className="button-apply"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </form>

      <div className="lang-box">
        <LangBox />
      </div>

      <div className="copyright-container">
        <p>OETech Solutions - 2023</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
