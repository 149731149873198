import "./styles/tenders/tenders.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";
import TenderEditBox from "../../components/EditBox/TenderEditBox";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import { CrudContextValue } from "../../context/CrudContext";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import RevisedTenders from "./RevisedTenders";
import ConfirmationTenders from "./ConfirmationTenders";
import ActiveTenders from "./ActiveTenders";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import FinalizedTenders from "./FinalizedTenders";
import { toast } from "react-toastify";
import { DemoContextValue } from "../../context/DemoContext";

function Tenders({ t }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    /* Sayfa link durumları */
    tendersPageLink,
    setTendersPageLink,
  } = GetContextValue();
  const { updateMethod, deleteMethod } = CrudContextValue();
  const { demoTenders, setDemoTenders } = DemoContextValue();

  const [revisedLoading, setRevisedLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const [openRevisedModal, setOpenRevisedModal] = useState(false);
  const handleOpenRevisedModal = () => setOpenRevisedModal(true);
  const handleCloseRevisedModal = () => setOpenRevisedModal(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  // const [tendersPage, setTendersPage] = useState(1);
  // const [tendersRowsPerPage, setTendersRowsPerPage] = useState(10);
  const [tableRender, setTableRender] = useState(false);
  const [revisedNote, setRevisedNote] = useState("");

  const [searchText, setSearchText] = useState("");

  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);

  const [tendersStatus, setTendersStatus] = useState("1");

  function handleClickLink(link) {
    setTendersPageLink(link);
    if (link === "OnayBekliyor") {
      setTendersStatus("1");
    } else if (link === "Revize") {
      setTendersStatus("3");
    } else if (link === "Onaylandı") {
      setTendersStatus("2");
    } else if (link === "Sonuçlananlar") {
      setTendersStatus("5");
    } else {
      setTendersStatus(null);
    }
  }

  useEffect(() => {
    if (tendersPageLink === "OnayBekliyor") {
      setTendersStatus("1");
    } else if (tendersPageLink === "Revize") {
      setTendersStatus("3");
    } else if (tendersPageLink === "Onaylandı") {
      setTendersStatus("2");
    } else if (tendersPageLink === "Sonuçlandı") {
      setTendersStatus("5");
    } else {
      setTendersStatus(null);
    }
  }, [tendersPageLink]);

  async function handleRevisedTender(revisedDescription) {
    console.log(
      `${selectValueId} idli İhale Revize edildi \n Revize açıklaması: ${revisedDescription}`
    );

    setRevisedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${selectValueId}&status=Revize&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    setTableRender(!tableRender);

    handleCloseRevisedModal();
  }

  async function handleDeleteTender() {
    console.log(selectValue, " ihale silindi!");

    setDeleteLoading(true);

    await deleteMethod(
      `${process.env.REACT_APP_DELETE_TENDER_URL}?id=${selectValueId}`,
      token
    );

    setDeleteLoading(false);

    setTableRender(!tableRender);

    handleCloseDeleteModal();
  }

  async function handleApprovedTender() {
    setApprovedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${selectValueId}&status=Onaylandı&confirmDescription="Onaylandı"`,
      null,
      token
    );

    setApprovedLoading(false);

    setTableRender(!tableRender);

    handleCloseConfirmModal();
  }

  function demoRevisedTender() {
    const filteredDemoTenders = demoTenders.filter(
      (tender) => Number(tender.tenderId) !== Number(selectValueId)
    );
    setDemoTenders(filteredDemoTenders);

    setRevisedLoading(false);

    toast.success("İhale revize edildi", {
      autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
    });

    setTableRender(!tableRender);

    handleCloseRevisedModal();
  }

  return (
    <div className="tenders-container">
      <div className="tenders-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {revisedLoading ? (
          <LoadingCircular />
        ) : openRevisedModal ? (
          <RevisedModal
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelText={"Vazgeç"}
            confirmIcon={<i className="fa-solid fa-paper-plane"></i>}
            confirmText={"Revize Et"}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedTender}
            // confirmFunc={demoRevisedTender}
          />
        ) : deleteLoading ? (
          <LoadingCircular />
        ) : openDeleteModal ? (
          <ConfirmModal
            text={t("message_delete_tender")}
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelButtonText={"Vazgeç"}
            handleCloseModal={handleCloseDeleteModal}
            confirmIcon={<i className="fa-solid fa-trash"></i>}
            confirmButtonText={"Sil"}
            confirmFunc={handleDeleteTender}
          />
        ) : approvedLoading ? (
          <LoadingCircular />
        ) : openConfirmModal ? (
          <ConfirmModal
            text={"İhaleyi onaylamak istediğinize emin misiniz?"}
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelButtonText={"Vazgeç"}
            handleCloseModal={handleCloseConfirmModal}
            confirmIcon={<i className="fa-solid fa-check"></i>}
            confirmButtonText={"Onayla"}
            confirmFunc={handleApprovedTender}
          />
        ) : (
          <TenderEditBox
            type={"tender"}
            tenderStatus={tendersStatus}
            title={"İhaleler"}
            titleIcon={<i className="fa-solid fa-ticket"></i>}
            searchText={searchText}
            setSearchText={setSearchText}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            useFetchRender={tableRender}
            setUseFetchRender={setTableRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenConfirmModal={handleOpenConfirmModal}
            handleOpenRevisedModal={handleOpenRevisedModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenRevisedNote={handleOpenRevisedNote}
          />
        )}
        {openRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={revisedNote}
            openRevisedNote={openRevisedNote}
            handleCloseRevisedNote={handleCloseRevisedNote}
          />
        )}
      </div>

      <div
        className={`tenders-page-link ${
          openConfirmModal ||
          openDeleteModal ||
          openRevisedModal ||
          approvedLoading ||
          revisedLoading ||
          deleteLoading
            ? "disabled-box"
            : ""
        }`}
      >
        
          <ButtonText
            icon={<i className="fa-solid fa-table-cells-large"></i>}
            text={"Sonuçlandı"}
            onClick={() => handleClickLink("Sonuçlandı")}
            className={tendersPageLink === "Sonuçlandı" ? "button-active" : ""}
          />

          <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={"Aktifler"}
            onClick={() => handleClickLink("Onaylandı")}
            className={tendersPageLink === "Onaylandı" ? "button-active" : ""}
          />

          <ButtonText
            icon={<i className="fa-solid fa-clock"></i>}
            text={"Bekleyenler"}
            onClick={() => handleClickLink("OnayBekliyor")}
            className={
              tendersPageLink === "OnayBekliyor" ? "button-active" : ""
            }
          />

          <ButtonText
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            text={"Düzenlenecekler"}
            onClick={() => handleClickLink("Revize")}
            className={tendersPageLink === "Revize" ? "button-active" : ""}
          />

          {/* <ButtonText
            icon={<i className="fa-solid fa-circle-minus"></i>}
            text={"Reddedilenler"}
            onClick={() => handleClickLink("Red")}
            className={tendersPageLink === "Red" ? "button-active" : ""}
          /> */}
        
      </div>

      <div
        className={`tenders-table ${
          openConfirmModal || openDeleteModal || openRevisedModal
            ? "disabled-box"
            : ""
        }`}
      >
        {tendersStatus === "3" ? (
          <RevisedTenders
            tableRender={tableRender}
            tableFilter={tableFilter}
            searchText={searchText}
            setRevisedNote={setRevisedNote}
          />
        ) : tendersStatus === "1" ? (
          <ConfirmationTenders
            tableRender={tableRender}
            tableFilter={tableFilter}
            searchText={searchText}
          />
        ) : tendersStatus === "2" ? (
          <ActiveTenders
            tableRender={tableRender}
            tableFilter={tableFilter}
            searchText={searchText}
            setRevisedNote={setRevisedNote}
          />
        ) : tendersStatus === "5" ? (
          <FinalizedTenders
            tableRender={tableRender}
            tableFilter={tableFilter}
            searchText={searchText}
          />
        ) : (
          <p className="table-null-text">NULL</p>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(Tenders);
