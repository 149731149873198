import "../components/NotificationCard/notificationCard.css";

import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

import { AuthContextValue } from "./AuthContext";
import useFetch from "../hooks/UseFetch";
import { getFetch } from "../hooks/getFetch";
import { getUserStatistics } from "../hooks/getUserStatistics";
import { Button, Space, notification } from "antd";
import { useNavigate } from "react-router-dom";

export const GetContext = createContext();

function GetContextProvider({ children }) {
  const navigate = useNavigate();
  const { token, userRole, loginUserCompanyId, loginUserId } =
    AuthContextValue();

  const [api, contextHolder] = notification.useNotification();
  const [userRender, setUserRender] = useState(false);

  /* Tablodan seçilen değerleri tutan stateler */
  const [selectValue, setSelectValue] = useState(null);
  const [selectValueId, setSelectValueId] = useState(null);

  /* Sayfalardaki seçilen linklerin tutulduğu stateler */
  const [tendersPageLink, setTendersPageLink] = useState("OnayBekliyor");

  const [offersPageLink, setOffersPageLink] = useState("sumbitted-offer");
  const [opportunitiesPageLink, setOpportunitiesPageLink] =
    useState("Onaylandı");

  /* ROLE DATA */
  const [roleList, setRoleList] = useState([]);

  /* giren firmanın tedarikçileri */
  // const [mySuppliers, setMySuppliers] = useState([]);
  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);
  const [mySuppliersTotal, setMySuppliersTotal] = useState(0);

  /* Bütün Firmalar */
  const [allCompanies, setAllCompanies] = useState([]);

  /* Firma detayları */
  const [companyDetail, setCompanyDetail] = useState({});

  /* Firma kullanıcıları idleri */
  const [companyUsersIds, setCompanyUsersIds] = useState([]);

  /* Kullanıcı istatistik ve performansları */
  const [usersStatisticsError, setUsersStatisticsError] = useState(null);
  const [usersStatisticsLoading, setUsersStatisticsLoading] = useState(false);
  const [usersStatistics, setUsersStatistics] = useState([]);

  const [notificationRender, setNotificationRender] = useState(false);
  const [unReadCount, setUnReadCount] = useState(null);

  /* döviz fiyatları */
  const [exChangeRate, setExChangeRate] = useState(0);
  const [exChangeRateLoading, setExChangeRateLoading] = useState(false);
  const [exChangeRateError, setExChangeRateError] = useState(null);

  useEffect(() => {
    setExChangeRateLoading(true);

    async function getExChangeRate() {
      try {
        const response = await axios.post(
          "http://sosocrm.oetech.online/api/Currency",
          {
            gun: 0,
            ay: 0,
            yil: 0,
            isToday: true,
          },
          token
        );

        if (response.data.hata) {
          setExChangeRateError(response.data.hata);
        } else {
          const filteredMoney = response.data.data.filter(
            (money) =>
              money.kodu === "USD" ||
              money.kodu === "EUR" ||
              money.kodu === "GBP" ||
              money.kodu === "CNY"
          );

          setExChangeRate(filteredMoney);
          // console.log("döviz : ", filteredMoney);
        }

        setExChangeRateLoading(false);
      } catch (err) {
        console.log("exChangeRate error : ", err);

        setExChangeRateLoading(false);
      }
    }

    getExChangeRate();
  }, []);

  /* kategori eklenince render olmuyor diğer usefetch fonksiyonlarındada aynı sorun var  */
  /* GET CATEGORİES */
  const {
    setUseFetchRender: setCategoryListUseFetchRender,
    useFetchRender: categoryListUseFetchRender,
    error: categoryListError,
    loading: categoryListLoading,
    data: categoryList,
  } = useFetch(process.env.REACT_APP_GET_ALL_CATEGORY_URL, token);

  useEffect(() => {
    setCategoryListUseFetchRender(!categoryListUseFetchRender);
  }, [token, notificationRender]);

  /* GET ROLES */
  const {
    setUseFetchRender: setRoleListUseFetchRender,
    useFetchRender: roleListUseFetchRender,
    error: roleListError,
    loading: roleListLoading,
    data: roleListData,
  } = useFetch(process.env.REACT_APP_GET_ROLE_LIST_URL, token);

  useEffect(() => {
    setRoleListUseFetchRender(!roleListUseFetchRender);
  }, [token, notificationRender]);

  useEffect(() => {
    if (roleListData) {
      console.log(roleListData);
      if (userRole === "Admin") {
        setRoleList(roleListData);
      } else {
        const filterRoleList = roleListData.filter(
          (role) => role.roleName !== "Admin"
        );

        setRoleList(filterRoleList);
      }
    }
  }, [token, userRole, roleListData]);

  /* MYSUPPLİERS USEFETCH */
  const {
    setUseFetchRender: setMySuppliersUseFetchRender,
    useFetchRender: mySuppliersUseFetchRender,
    error: mySuppliersError,
    loading: mySuppliersLoading,
    data: mySuppliers,
    total: mySuppliersTotalData,
  } = useFetch(
    `${process.env.REACT_APP_GET_MY_SUPPLIER_URL}?id=${loginUserCompanyId}&page=${mySuppliersPage}&pageSize=${mySuppliersRowsPerPage}`,
    token
  );

  useEffect(() => {
    setMySuppliersUseFetchRender(!mySuppliersUseFetchRender);

    setMySuppliersTotal(mySuppliersTotalData);
  }, [mySuppliersTotalData, token, notificationRender]);

  /* COMPANY DETAİL */
  useEffect(() => {
    const getCompanyDetail = async () => {
      try {
        const companyResponse = await getFetch(
          `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${loginUserCompanyId}`,
          token
        );

        setCompanyDetail(companyResponse.data.data[0]);
      } catch (error) {
        console.log("companydetail error : ", error);
      }
    };

    if (token && users) {
      getCompanyDetail();
    }
  }, [token]);

  /* USER USEFETCH */
  const {
    setUseFetchRender: setUserUseFetchRender,
    useFetchRender: userUseFetchRender,
    error: userError,
    loading: userLoading,
    data: user,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?UserId=${loginUserId}&Page=1&PageSize=10`,
    token
  );

  useEffect(() => {
    setUserUseFetchRender(!userUseFetchRender);
  }, [loginUserId, userRender, notificationRender]);

  /* USERS USEFETCH */
  const {
    setUseFetchRender: setUsersUseFetchRender,
    useFetchRender: usersUseFetchRender,
    error: usersError,
    loading: usersLoading,
    data: users,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?CompanyId=${loginUserCompanyId}&Page=1&PageSize=10`,
    token
  );

  useEffect(() => {
    if (users) {
      setCompanyUsersIds(users.map((user) => user.userId));
    }
  }, [users, notificationRender]);

  useEffect(() => {
    const fetchUserStatistics = async () => {
      setUsersStatisticsLoading(true);

      try {
        // const allOfferResponse = await getFetch(
        //   `${
        //     process.env.REACT_APP_GET_OFFER_LIST_URL
        //   }?OfferCompanyId=${loginUserCompanyId}&OfferStatus=2&Last=true&SortType=${3}&Page=${1}&PageSize=${1}`,
        //   token
        // );

        const promises = users.map(async (user) => {
          const statistics = await getUserStatistics(
            user.userId,
            companyDetail?.companyType,
            token
          );

          if (companyDetail?.companyType === 1) {
            return {
              ...user,
              statistics,
              tenderTotal: statistics.tenderTotal ? statistics.tenderTotal : 0,
            };
          } else if (companyDetail?.companyType === 2) {
            return {
              ...user,
              statistics,
              offerTotal: statistics.offerTotal ? statistics.offerTotal : 0,
            };
          } else if (companyDetail?.companyType === 3) {
            return {
              ...user,
              statistics,
              offerTotal: statistics.offerTotal ? statistics.offerTotal : 0,
              tenderTotal: statistics.tenderTotal ? statistics.tenderTotal : 0,
            };
          }
        });

        const results = await Promise.all(promises);
        const newUsersData = results.filter((result) => result !== undefined);

        setUsersStatistics(newUsersData);
      } catch (error) {
        console.log("users statistics error : ", error);
        if (error.status) {
          setUsersStatisticsError(error.status);
        } else {
          setUsersStatisticsError(error);
        }
      }

      setUsersStatisticsLoading(false);
    };

    if (token && users && users.length > 0) {
      fetchUserStatistics();
    }
  }, [users, token, notificationRender]);

  /* ALL COMPANIES USEFETCH */
  const [allCompaniesTotal, setAllCompaniesTotal] = useState(10);
  const {
    setUseFetchRender: setAllCompaniesUseFetchRender,
    useFetchRender: allCompaniesUseFetchRender,
    error: allCompaniesError,
    loading: allCompaniesLoading,
    data: allCompaniesData,
    total: allCompaniesTotalData,
  } = useFetch(
    `${process.env.REACT_APP_GET_ALL_COMPANY_URL}?Page=1&PageSize=${allCompaniesTotal}`,
    token
  );
  useEffect(() => {
    setAllCompaniesTotal(allCompaniesTotalData);
    setAllCompaniesUseFetchRender(!allCompaniesUseFetchRender);
  }, [loginUserId, userRender, allCompaniesTotal, notificationRender]);

  /* Bildirim gösterme fonksiyonu başlangıç */

  // Okundu olarak işaretlemek için
  const markAsRead = async (notificationId) => {
    const putData = {
      id: [notificationId],
      status: true,
    };

    try {
      const response = await axios.put(
        process.env.REACT_APP_PUT_UPDATE_NOTIFICATION_STATUS_URL,
        JSON.stringify(putData),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token} `,
            withCredentials: true,
          },
        }
      );

      if (response.data.isError) {
        console.log(
          "bildirim kartı tıklama hatası : ",
          response.data.errorMessageList[0]
        );
      } else {
        console.log(
          "bildirim kartı tıklama;bildirim durumu okundu olarak değiştirildiğinde dönen cevap: ",
          response.data.data
        );
      }
    } catch (error) {
      // Hata durumunda gerekli işlemleri yapabilirsiniz
      console.log("bildirim kartı tıklama cathError: ", error);
    }
  };

  const fetchData = async () => {
    const strogeNotify = localStorage.getItem("notify");

    try {
      const response = await axios(
        `${
          process.env.REACT_APP_GET_NOTIFICATION_URL
        }?Page=${1}&PageSize=${10}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.data) {
        const notifications = await response.data.data;
        const notReadNotify = await response.data.data[0].unReadCount;
        setUnReadCount(notReadNotify);

        if (strogeNotify) {
          // Eğer localstorage ta bildirim toplam sayısı varsa
          // ve istek atılınca bildirim sayısında artış olduysa
          // yeni eklenen bildirimler gösterilir
          if (response.data.total > strogeNotify) {
            if (notifications) {
              localStorage.setItem("notify", response.data.total);
            }

            const newNotificationsCount = response.data.total - strogeNotify;
            for (let i = 0; i < newNotificationsCount; i++) {
              const notification = notifications[i];

              if (!notification.isRead) {
                const close = () => {
                  console.log("Notification was closed.");
                  setNotificationRender(!notificationRender);
                };
                const openNotification = () => {
                  const key = `open${Date.now()}`;
                  const btn = (
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        onClick={async () => {
                          api.destroy(key);
                          const url = new URL(notification.values);
                          const route = url.pathname;
                          console.log(notification);
                          await markAsRead(notification.id);

                          navigate(route);
                        }}
                      >
                        Detay
                      </Button>
                    </Space>
                  );
                  api.open({
                    message: notification.title,
                    description: notification.body,
                    btn,
                    key,
                    type: "info",
                    placement: "bottomRight",
                    onClose: close,
                  });
                };

                openNotification();

                await setNotificationRender(!notificationRender); // Bildirim gösterildikten sonra state'i güncelle
              }
            }
          }
        } else {
          // Eğer localstorage ta bildirim toplam sayısı yoksa ekler
          //ve giriş yapıldığında okunmamış bildirim sayısı gösterilir
          if (token) {
            localStorage.setItem("notify", response.data.total);
            if (notReadNotify) {
              const close = () => {
                console.log("close message.");
              };
              const openNotification = () => {
                const key = `open${Date.now()}`;
                const btn = (
                  <Space>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        api.destroy(key);
                        navigate("/notifications");
                      }}
                    >
                      Mesajar
                    </Button>
                  </Space>
                );
                api.open({
                  message: "Bildirimler",
                  description: `${notReadNotify} Adet okunmamış bildiriminiz bulunmaktadır.`,
                  btn,
                  key,
                  type: "info",
                  placement: "bottomRight",
                  onClose: close,
                });
              };

              openNotification();
            }
          }
        }
      } else {
        console.error("Bildirimleri getirirken bir hata oluştu. 1");
      }
    } catch (error) {
      console.error("Bildirimleri getirirken bir hata oluştu: 2", error);
    }
  };

  useEffect(() => {
    let intervalId;
    let lastNotificationTime = 0;

    const fetchDataAndHandleClick = async () => {
      await fetchData();

      const handleClick = async () => {
        const storageNotify = localStorage.getItem("notify");
        if (storageNotify) {
          const currentTime = Date.now();
          if (currentTime - lastNotificationTime >= 15000) {
            await fetchData();
            lastNotificationTime = currentTime;
          }
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    };

    if (token) {
      fetchDataAndHandleClick();

      intervalId = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - lastNotificationTime >= 15000) {
          fetchData();
          lastNotificationTime = currentTime;
        }
      }, 15000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [token, notificationRender]);
  /* Bildirim gönderme fonksiyonu bitiş */

  const values = {
    /* seçilen değer */
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,

    /* Sayfa link durumları */
    tendersPageLink,
    setTendersPageLink,
    offersPageLink,
    setOffersPageLink,
    opportunitiesPageLink,
    setOpportunitiesPageLink,

    /* Döviz Kuru */
    exChangeRate,
    exChangeRateLoading,
    exChangeRateError,

    /* ROLELIST */
    roleList,
    roleListError,
    roleListLoading,
    setRoleListUseFetchRender,
    roleListUseFetchRender,

    /* CATEGORYLIST */
    categoryList,
    categoryListLoading,
    categoryListError,
    setCategoryListUseFetchRender,
    categoryListUseFetchRender,

    /* my supplier data */
    setMySuppliersUseFetchRender,
    mySuppliersUseFetchRender,

    mySuppliers,
    mySuppliersLoading,
    mySuppliersError,
    mySuppliersTotal,

    mySuppliersPage,
    setMySuppliersPage,
    mySuppliersRowsPerPage,
    setMySuppliersRowsPerPage,

    /* USER DETAİL */
    setUserUseFetchRender,
    userUseFetchRender,
    userError,
    userLoading,
    user,
    userRender,
    setUserRender,

    /* COMPANY USERS IDS */
    companyUsersIds,

    /* USERS STATISTICS */
    usersStatisticsError,
    usersStatisticsLoading,
    usersStatistics,

    /* AALL COMPANIES */
    allCompaniesError,
    allCompaniesLoading,
    allCompaniesData,
    allCompaniesTotalData,

    /* COMPANY DETAIL */
    companyDetail,

    /* Bildirimin  içeriği*/
    contextHolder,

    /* Bildirim geldiğinde sayfa yenilenmesi için */
    notificationRender,
    setNotificationRender,
    unReadCount,
  };

  return <GetContext.Provider value={values}>{children}</GetContext.Provider>;
}

export const GetContextValue = () => useContext(GetContext);

export default GetContextProvider;
