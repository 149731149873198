// import "./bannerCard.css";

// import React from "react";
// import images from "../../../assets/images/index";

// function BannerCard() {
//   return (
//     <div className="banner-card-container">
//       <img src={images.oeBanner} alt="oeBanner" />
//     </div>
//   );
// }

// export default BannerCard;

import { AuthContextValue } from "../../../context/AuthContext";
import useFetch from "../../../hooks/UseFetch";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";
import "./bannerCard.css";
import React, { useState, useEffect } from "react";
// import images from "../../../assets/images/index";

function BannerCard() {
  const { token } = AuthContextValue();
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    setUseFetchRender: setBannersUseFetchRender,
    useFetchRender: bannersUseFetchRender,
    error: bannersError,
    loading: bannersLoading,
    data: bannerData,
    total: bannersTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_BANNER_LIST_URL
    }?Status=2&Page=${1}&PageSize=${20}`,
    token
  );

  useEffect(() => {
    if (bannerData) {
      const interval = setInterval(() => {
        if (currentIndex < bannerData.length - 1) {
          setCurrentIndex(currentIndex + 1);
        } else {
          setCurrentIndex(0);
        }
      }, bannerData[currentIndex]?.displayTime * 1000);

      return () => clearInterval(interval);
    }
  }, [bannerData, currentIndex]);

  return (
    <div className="banner-card-container">
      {bannersError ? (
        <ErrorComponent error={bannersError} />
      ) : bannersLoading ? (
        <Loading />
      ) : bannerData && bannerData.length > 0 ? (
        <div className="img-container">
          <img
            src={bannerData[currentIndex]?.image}
            alt={bannerData[currentIndex]?.companyName}
          />
        </div>
      ) : (
        <p className="table-null-text">Reklam yok</p>
      )}
    </div>
  );
}

export default BannerCard;
