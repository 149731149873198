import "./offerDetailCard.css";
import React, { useEffect, useState, useRef } from "react";
import DetailText from "../DetailText/DetailText";
import Carousel from "../Carousel/Carousel";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { Link, useNavigate } from "react-router-dom";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import RevisedModal from "../Modals/RevisedModal/RevisedModal";
import { toast } from "react-toastify";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import RevisedDescriptionModal from "../Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import { Alert, Space } from "antd";
import HistoryOffersModal from "../Modals/HistoryOffersModal/HistoryOffersModal";

function OfferDetailCard({
  offerDetail,
  setOfferDetailUseFetchRender,
  offerDetailUseFetchRender,
}) {
  const navigate = useNavigate();

  const { token, loginUserCompanyId } = AuthContextValue();
  const { createMethod, deleteMethod, updateMethod } = CrudContextValue();

  const [revisedLoading, setRevisedLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  const [resultRevisedNote, setResultRevisedNote] = useState("");
  const [openResultRevisedNote, setOpenResultRevisedNote] = useState(false);
  const handleOpenResultRevisedNote = () => setOpenResultRevisedNote(true);
  const handleCloseResultRevisedNote = () => setOpenResultRevisedNote(false);

  const [historyOffersOpen, setHistoryOffersOpen] = useState(false);
  const handleOpenHistoryOfferModal = () => setHistoryOffersOpen(true);
  const handleCloseHistoryOfferModal = () => setHistoryOffersOpen(false);

  const [document, setDocument] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (offerDetail !== null) {
      if (offerDetail.images !== null && offerDetail.images.length > 0) {
        let imagesPath = [];
        let documentPath = [];
        offerDetail.images.map((doc) => {
          if (doc.includes("pdf")) {
            documentPath.push(doc);
          } else {
            imagesPath.push(doc);
          }
        });
        setDocument(documentPath);
        setImages(imagesPath);
      }

      setRevisedNote(offerDetail.confirmDescription);
      setResultRevisedNote(offerDetail.revisedDescription);
    }
  }, [offerDetail]);

  async function handleDeleteOffer() {
    console.log(offerDetail.offerId, "idli teklif silindi");

    setDeleteLoading(true);
    console.log(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${offerDetail.offerId}`
    );
    const response = await deleteMethod(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${offerDetail.offerId}`,
      token
    );

    setDeleteLoading(false);

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      handleCloseDeleteModal();
      setOfferDetailUseFetchRender(!offerDetailUseFetchRender);

      toast.info("Tekliflerim sayfsına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }
  }

  async function handleRevisedOffer(revisedDescription) {
    console.log(
      offerDetail.offerId,
      "idli teklif revize edildi",
      " açıklaması : ",
      revisedDescription
    );

    setRevisedLoading(true);

    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=3&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    handleCloseRevisedModal();

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      setOfferDetailUseFetchRender(!offerDetailUseFetchRender);
      handleCloseRevisedModal();

      toast.info("Tekliflerim sayfasına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }
  }

  async function handleConfirmOffer() {
    console.log(offerDetail.offerId, "idli teklif onaylandı");

    setApprovedLoading(true);
    console.log(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=Onaylandı`
    );
    await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=Onaylandı`,
      null,
      token
    );

    setApprovedLoading(false);

    handleCloseConfirmModal();

    setOfferDetailUseFetchRender(!offerDetailUseFetchRender);
  }

  return (
    <div className="offer-detail-card">
      <div className="offer-detail-card-top">
        {revisedLoading || deleteLoading || approvedLoading ? (
          <LoadingCircular />
        ) : deleteModalOpen ? (
          <ConfirmModal
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Sil"}
            handleCloseModal={handleCloseDeleteModal}
            confirmFunc={handleDeleteOffer}
            text={"Teklifi silmek istediğinize emin misiniz?"}
          />
        ) : revisedModalOpen ? (
          <RevisedModal
            cancelText={"Vazgeç"}
            confirmText={"Revize Et"}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedOffer}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Onayla"}
            handleCloseModal={handleCloseConfirmModal}
            confirmFunc={handleConfirmOffer}
            text={
              "Teklifi onaylamak ve yayına almak istediğinize emin misiniz?"
            }
          />
        ) : (
          <>
            <div className="title">
              <i className="fa-solid fa-bahai"></i>
              <h4 className="title">Teklif Detay</h4>
            </div>
            <div className="button-group">
              {Number(offerDetail.offerCompanyId) ===
                Number(loginUserCompanyId) && offerDetail.status !== "2" ? (
                <>
                  {offerDetail.status === "1" && (
                    <>
                      <WhiteButton
                        icon={<i className="fa-solid fa-check"></i>}
                        tooltip={"Onayla"}
                        onClick={handleOpenConfirmModal}
                      />
                      <WhiteButton
                        icon={<i className="fa-solid fa-arrows-rotate"></i>}
                        tooltip={"Revize"}
                        onClick={handleOpenRevisedModal}
                      />
                    </>
                  )}

                  {offerDetail.status === "3" && (
                    <WhiteButton
                      icon={<i className="fa-solid fa-note-sticky"></i>}
                      tooltip={"Revize Notu"}
                      onClick={handleOpenRevisedNote}
                    />
                  )}

                  <Link
                    to={
                      offerDetail.offerId
                        ? `/offers/offer-edit/${offerDetail.offerId}`
                        : false
                    }
                  >
                    <WhiteButton
                      icon={<i className="fa-solid fa-pen-to-square"></i>}
                      tooltip={"Düzenle"}
                    />
                  </Link>

                  <WhiteButton
                    icon={<i className="fa-solid fa-trash-can"></i>}
                    tooltip={"Sil"}
                    onClick={handleOpenDeleteModal}
                  />
                </>
              ) : null}

              {Number(offerDetail.offerCompanyId) ===
                Number(loginUserCompanyId) && offerDetail.result === "3" ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-note-sticky"></i>}
                    tooltip={"Revize Notu"}
                    onClick={handleOpenResultRevisedNote}
                  />

                  <Link
                    to={
                      offerDetail.tenderId
                        ? `/opportunities/tender-detail-offer/${offerDetail.tenderId}`
                        : false
                    }
                  >
                    <WhiteButton
                      icon={<i className="fa-solid fa-cart-plus"></i>}
                      tooltip={"Teklif Ver"}
                    />
                  </Link>
                </>
              ) : null}
              {Number(offerDetail.offerCompanyId) !==
                Number(loginUserCompanyId) && (
                <WhiteButton
                  icon={<i className="fa-solid fa-clock-rotate-left"></i>}
                  tooltip={"Geçmiş Teklifler"}
                  onClick={handleOpenHistoryOfferModal}
                />
              )}
            </div>
          </>
        )}
        {/* Firma içi manager revize talep notu */}
        {openRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={revisedNote}
            openRevisedNote={openRevisedNote}
            handleCloseRevisedNote={handleCloseRevisedNote}
          />
        )}
        {/* İhale sahibi teklif revize talebi notu */}
        {openResultRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={resultRevisedNote}
            openRevisedNote={openResultRevisedNote}
            handleCloseRevisedNote={handleCloseResultRevisedNote}
          />
        )}
      </div>
      <div className="offer-detail-card-main">
        {Number(offerDetail?.offerCompanyId) === Number(loginUserCompanyId) ? (
          <>
            {offerDetail.status === "1" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert message="Yönetici onayı bekleniyor." type="warning" />
              </Space>
            ) : offerDetail.status === "3" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message="Yönetici tarafından teklifi revize etmeniz istendi :("
                  type="error"
                />
              </Space>
            ) : offerDetail.result === "1" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message="İhale sahinin cevabı bekleniyor."
                  type="warning"
                />
              </Space>
            ) : offerDetail.result === "2" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert message="Teklifiniz onaylandı :)" type="success" />
              </Space>
            ) : offerDetail.result === "3" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message="İhale sahibi tarafından teklifinize revize talep edildi :|"
                  type="error"
                />
              </Space>
            ) : null}
          </>
        ) : null}
        <div className="offer-detail-card-images-container">
          {images && images.length > 0 ? (
            <Carousel images={images} />
          ) : (
            <div className="no-offer-image">
              <h3>Fotoğraf Yok</h3>
            </div>
          )}
        </div>

        <div className="offer-detail-card-info-container">
          <div className="offer-detail-card-documan-container">
            <p className="detail-text-title">Dökümanlar:</p>
            <div className="documan-box">
              {document && document.length >= 1
                ? document.map((doc, i) => {
                    return (
                      <a
                        key={i}
                        target="_blank"
                        // href={`${process.env.REACT_APP_GET_IMAGE_URL}${doc}`}
                        href={doc}
                        className="detail-text"
                      >
                        {doc.split("images/")[1]}
                      </a>
                    );
                  })
                : "-"}
            </div>
          </div>
          <DetailText
            title={"Teklif Tarihi:"}
            text={new Date(offerDetail.offerCreatedAt).toLocaleString()}
          />
          <DetailText title={"Teklifi Yapan:"} text={offerDetail.createdUser} />
          <DetailText
            title={"Termin:"}
            text={new Date(offerDetail.deadline).toLocaleString()}
          />
          <DetailText title={"Açıklama:"} text={offerDetail.description} />
          <DetailText
            title={"İşçilik:"}
            text={Number(offerDetail.laborPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText
            title={"Malzeme:"}
            text={Number(offerDetail.materialPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText
            title={"Birim Fiyat:"}
            text={Number(
              offerDetail.laborPrice + offerDetail.materialPrice
            ).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText
            title={"Toplam:"}
            text={Number(offerDetail.totalPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText title={"Not:"} text={offerDetail.note} />
        </div>

        {historyOffersOpen && (
          <HistoryOffersModal
            supplierId={offerDetail.offerCompanyId}
            tenderId={offerDetail.tenderId}
            isModalOpen={historyOffersOpen}
            handleCloseModal={handleCloseHistoryOfferModal}
          />
        )}
      </div>
    </div>
  );
}

export default OfferDetailCard;
