import "./styles/editBox.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import SelectRoles from "../form_components/Select/SelectRoles";

function UserEditBox({
  t,

  type,

  title,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenResetPasswordModal,
  handleOpenDeleteUserModal,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* SEARCH-END */

  function handleChangeRole(e) {
    setTableFilter(e.target.value);
  }

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "add":
        console.log("Ekle ye tıklandı!");

        setSearchOpen(false);

        break;

      case "edit":
        console.log("Edit e tıklandı!");
        console.log("düzenlenicek value : ", selectValue);

        setSearchOpen(false);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        setSearchOpen(false);

        handleOpenDeleteUserModal();

        break;

      case "reset-password":
        console.log("reset-passwordtıklandı!");

        setSearchOpen(false);

        handleOpenResetPasswordModal();

        break;

      default:
        break;
    }
  }

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <div className="title">
              <i className="fa-solid fa-users-line" />
              <h4 className="title">{title}</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={"Yenile"}
              onClick={() => handleClick("render")}
            />
            {!selectValue ? (
              <>
                <WhiteButton
                  icon={
                    isSearchOpen ? (
                      <div ref={searchRef} className="search-container">
                        <i className="fa-solid fa-magnifying-glass" />
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    ) : (
                      <i className="fa-solid fa-magnifying-glass" />
                    )
                  }
                  tooltip={"Ara"}
                  onClick={() => handleClick("search")}
                />
                <SelectRoles
                  className="select-roles"
                  name="filter"
                  value={tableFilter}
                  onChange={handleChangeRole}
                />
                <Link to={`/users/user-add`}>
                  <WhiteButton
                    icon={<i className="fa-solid fa-plus" />}
                    tooltip={"Kullanıcı Ekle"}
                    onClick={() => handleClick("add")}
                  />
                </Link>
              </>
            ) : (
              <>
                <WhiteButton
                  icon={<i className="fa-solid fa-trash-can"></i>}
                  tooltip={"Sil"}
                  onClick={() => handleClick("delete")}
                  disabled={selectValueId || selectValue ? false : true}
                />

                <Link
                  to={
                    selectValueId || selectValue
                      ? `/users/user-profile/${selectValueId}`
                      : false
                  }
                >
                  <WhiteButton
                    icon={<i className="fa-solid fa-info" />}
                    tooltip={"Detay"}
                    onClick={() => handleClick("profile")}
                    disabled={selectValueId || selectValue ? false : true}
                  />
                </Link>

                <WhiteButton
                  icon={<i className="fa-solid fa-xmarks-lines"></i>}
                  tooltip={"Şifre Sıfırla"}
                  onClick={() => handleClick("reset-password")}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(UserEditBox);
