function ReportIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM6.66667 15.5556H4.44444V7.77778H6.66667V15.5556ZM11.1111 15.5556H8.88889V4.44444H11.1111V15.5556ZM15.5556 15.5556H13.3333V11.1111H15.5556V15.5556Z"
            />
        </svg>

    )
}

export default ReportIcon;
