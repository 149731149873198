import "./styles/suppliers.css";

import { withNamespaces } from "react-i18next";

import { GetContextValue } from "../../context/GetContext";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { useEffect, useState } from "react";
import SupplierEditBox from "../../components/EditBox/SupplierEditBox";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { CrudContextValue } from "../../context/CrudContext";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { DemoContextValue } from "../../context/DemoContext";

function Suppliers({ t }) {
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const { token, loginUserCompanyId } = AuthContextValue();
  const { deleteMethod } = CrudContextValue();
  const { demoSuppliers } = DemoContextValue();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(1);
  const [supplierStatus, setSupplierStatus] = useState("Tümü");

  const [searchText, setSearchText] = useState("");

  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setMySuppliersUseFetchRender,
    useFetchRender: mySuppliersUseFetchRender,
    error: mySuppliersError,
    loading: mySuppliersLoading,
    data: mySuppliers,
    total: mySuppliersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_MY_SUPPLIER_URL}?searhedText=${searchText}&id=${loginUserCompanyId}&page=${mySuppliersPage}&pageSize=${mySuppliersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: "Foto",
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "companyCategory",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "distance",
      header: "Mesafe",
    },
    {
      key: "addresses",
      header: t("table_address"),
    },
  ];

  useEffect(() => {
    setMySuppliersUseFetchRender(!mySuppliersUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, [mySuppliers]);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function deleteSupplier() {
    console.log(`companyId=${loginUserCompanyId}&supplierId=${selectValueId}`);
    await deleteMethod(
      `${process.env.REACT_APP_DELETE_SUPPLIER_URL}?companyId=${loginUserCompanyId}&supplierId=${selectValueId}`,
      token
    );
    setMySuppliersUseFetchRender(!mySuppliersUseFetchRender);
    handleCloseDeleteModal();
  }

  return (
    <div className="suppliers-container">
      <div className="suppliers-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {deleteModalOpen ? (
          <ConfirmModal
            handleCloseModal={handleCloseDeleteModal}
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelButtonText={"Vazgeç"}
            confirmIcon={<i className="fa-solid fa-trash-can"></i>}
            confirmButtonText={"Sil"}
            text={"Tedarikçiyi silmek istediğinizden emin misiniz?"}
            confirmFunc={deleteSupplier}
          />
        ) : (
          <SupplierEditBox
            searchText={searchText}
            setSearchText={setSearchText}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            useFetchRender={mySuppliersUseFetchRender}
            setUseFetchRender={setMySuppliersUseFetchRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        )}
      </div>

      <div className="supplier-page-link">
        <div className="opportunities-button-groups">
          <ButtonText
            icon={<i className="fa-solid fa-table-cells-large"></i>}
            text={"Tümü"}
            onClick={() => setSupplierStatus("Tümü")}
            className={supplierStatus === "Tümü" ? "button-active" : ""}
          />

          {/* <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={"Teklif Yapanlar"}
            onClick={() => setSupplierStatus("Teklif Yapanlar")}
            className={
              supplierStatus === "Teklif Yapanlar" ? "button-active" : ""
            }
          />

          <ButtonText
            icon={<i className="fa-solid fa-clock"></i>}
            text={"Teklif Olmayanlar"}
            onClick={() => setSupplierStatus("Teklif Olmayanlar")}
            className={
              supplierStatus === "Teklif Olmayanlar" ? "button-active" : ""
            }
          /> */}
        </div>
      </div>

      <div className="suppliers-table">
        {mySuppliersError ? (
          <ErrorComponent error={mySuppliersError} />
        ) : mySuppliersLoading ? (
          <Loading />
        ) : mySuppliers !== null ? (
          <StandartTable
            type="company"
            searchText={searchText}
            setSearchText={setSearchText}
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={mySuppliersUseFetchRender}
            setUseFetchRender={setMySuppliersUseFetchRender}
            total={mySuppliersTotal}
            page={mySuppliersPage}
            setPage={setMySuppliersPage}
            rowsPerPage={mySuppliersRowsPerPage}
            setRowsPerPage={setMySuppliersRowsPerPage}
            columns={columns}
            data={mySuppliers}
          />
        ) :  (
          <div className="table-null-text">
            Sistemde kayıtlı tedarikçi firma yok
          </div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(Suppliers);
// demoSuppliers ? (
//           <StandartTable
//             type="company"
//             searchText={searchText}
//             setSearchText={setSearchText}
//             selectValueId={selectValueId}
//             selectValue={selectValue}
//             handleClickValue={handleClickValue}
//             useFetchRender={mySuppliersUseFetchRender}
//             setUseFetchRender={setMySuppliersUseFetchRender}
//             total={mySuppliersTotal}
//             page={mySuppliersPage}
//             setPage={setMySuppliersPage}
//             rowsPerPage={mySuppliersRowsPerPage}
//             setRowsPerPage={setMySuppliersRowsPerPage}
//             columns={columns}
//             data={demoSuppliers}
//           />
//         ) :