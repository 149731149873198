import { Button, Result } from "antd";
import React from "react";

function Page500() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Üzgünüm, bir şeyler ters gitti."
      extra={
        <Button type="primary" href="https://www.offerex.oetech.online/">
          Ana Sayfa
        </Button>
      }
    />
  );
}

export default Page500;
