import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import Loading from "../../components/Loading/Loading";
import useFetch from "../../hooks/UseFetch";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function TendersWaitingForOffer({ t, tableFilter, searchText, tableRender }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [
    opportunitiesWaitingForOfferPage,
    setOpportunitiesWaitingForOfferPage,
  ] = useState(1);
  const [
    opportunitiesWaitingForOfferRowsPerPage,
    setOpportunitiesWaitingForOfferRowsPerPage,
  ] = useState(10);

  const opportunitiesStatus = 2;

  const {
    setUseFetchRender: setOpportunitiesWaitingForOfferUseFetchRender,
    useFetchRender: opportunitiesWaitingForOfferUseFetchRender,
    error: opportunitiesWaitingForOfferError,
    loading: opportunitiesWaitingForOfferLoading,
    data: opportunitiesWaitingForOffer,
    total: opportunitiesWaitingForOfferTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_SUPPLIER_LIST_URL}?Count=0&SortType=${tableFilter}&Status=${opportunitiesStatus}&CompanyId=${loginUserCompanyId}&Page=${opportunitiesWaitingForOfferPage}&PageSize=${opportunitiesWaitingForOfferRowsPerPage}`,
    token
  );
console.log(opportunitiesWaitingForOffer);
  const columns = [
    {
      key: "logo",
      header: "Foto",
    },
    {
      key: "companyName",
      header: "Yayınlayan",
    },
    {
      key: "categories",
      header: "Kategori",
    },
    {
      key: "productName",
      header: "Ürün/Hizmet",
    },
    {
      key: "amount",
      header: "Miktar",
    },
    {
      key: "distance",
      header: "Konum",
    },
    {
      key: "duration",
      header: "Mesafe",
    },
    {
      key: "createdAt",
      header: "Tarih",
    },
    {
      key: "remainingTime",
      header: "Kalan Süre",
    },
  ];

  useEffect(() => {
    setOpportunitiesWaitingForOfferUseFetchRender(
      !opportunitiesWaitingForOfferUseFetchRender
    );
  }, [tableRender, notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <>
      {opportunitiesWaitingForOfferError ? (
        <ErrorComponent error={opportunitiesWaitingForOfferError} />
      ) : opportunitiesWaitingForOfferLoading ? (
        <Loading />
      ) : opportunitiesWaitingForOffer !== null &&
        opportunitiesWaitingForOffer.length > 0 ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={opportunitiesWaitingForOfferUseFetchRender}
          setUseFetchRender={setOpportunitiesWaitingForOfferUseFetchRender}
          total={opportunitiesWaitingForOfferTotal}
          page={opportunitiesWaitingForOfferPage}
          setPage={setOpportunitiesWaitingForOfferPage}
          rowsPerPage={opportunitiesWaitingForOfferRowsPerPage}
          setRowsPerPage={setOpportunitiesWaitingForOfferRowsPerPage}
          columns={columns}
          data={opportunitiesWaitingForOffer}
        />
      ) : (
        <div className="table-null-text">Teklif bekleyen bir fırsat yok!</div>
      )}
    </>
  );
}

export default withNamespaces()(TendersWaitingForOffer);
