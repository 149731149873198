import "./styles/companyEdit/companyEdit.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import CityComponent from "../../components/form_components/AddressComponents/CityComponent";
import DistrictComponent from "../../components/form_components/AddressComponents/DistrictComponent";
import NeighbourhoodComponent from "../../components/form_components/AddressComponents/NeighbourhoodComponent";
import { toast } from "react-toastify";
import { getFetch } from "../../hooks/getFetch";

function CompanyEdit() {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { categoryList, categoryListLoading, categoryListError } =
    GetContextValue();

  const { updateMethod, createMethod } = CrudContextValue();
  const {
    token,
    userRole,
    companyUpdateRender,
    setCompanyUpdateRender,
  } = AuthContextValue();

  const [image, setImage] = useState();

  const [selectedCity, setSelectedCity] = useState(81);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(0);

  const [companyInfoSaveOk, setcompanyInfoSaveOk] = useState(false);

  const [infoSaveLoading, setInfoSaveLoading] = useState(false);
  const [addressSaveLoading, setAddressSaveLoading] = useState(false);

  const [companyValues, setCompanyValues] = useState({
    companyId: companyId,
    companyType: 0,
    categoryId: 0,
    companyCode: "",
    companyName: "",
    taxNumber: "",
    taxLocation: "",
    phoneNumber: "",
    email: "",
    logo: "",
  });
  const [companyValuesErrors, setCompanyValuesErrors] = useState({
    companyTypeError: false,
    categoryIdError: false,
    companyCodeError: false,
    companyNameError: false,
    taxNumberError: false,
    taxLocationError: false,
    phoneNumberError: false,
    emailError: false,
  });

  const [companyAddress, setCompanyAddress] = useState({
    addressTitle: "",
    cityId: 0,
    districtId: 0,
    neighbourhoodId: 0,
    street: "",
    buildNumber: "",
    floorNumber: "",
    doorNumber: "",
    isInvoice: true,
    isDefault: true,
    coordinates: "",
  });
  const [companyAddressError, setCompanyAddressError] = useState({
    cityError: false,
    districtError: false,
    neighbourhoodError: false,
    streetError: false,
    buildNumberError: false,
  });

  const {
    setUseFetchRender: setCompanyDetailUseFetchRender,
    useFetchRender: companyDetailUseFetchRender,
    error: companyDetailError,
    loading: companyDetailLoading,
    data: companyDetail,
    total: companyDetailTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${companyId}`,
    token
  );

  useEffect(() => {
    setCompanyDetailUseFetchRender(!companyDetailUseFetchRender);

    async function getCompanyAddress() {
      await getFetch(
        `${process.env.REACT_APP_GET_COMPANY_ADDRESS_URL}?companyId=${companyId}`,
        token
      ).then((response) => {
        setSelectedCity(response.data.data[0].CityId);
        setSelectedDistrict(response.data.data[0].DistrictId);
        setSelectedNeighbourhood(response.data.data[0].NeighbourhoodId);
      });
    }

    if (companyId) {
      getCompanyAddress();
    }
  }, [companyId]);

  useEffect(() => {
    if (companyDetail && companyDetail.length > 0) {
      const findCategory = categoryList.find(
        (categ) => categ.categoryName === companyDetail[0].categoryName
      );

      setCompanyValues({
        companyId: companyDetail[0].companyId ? companyDetail[0].companyId : 0,
        companyType: companyDetail[0].companyType
          ? companyDetail[0].companyType
          : 0,
        categoryId: findCategory?.id,
        companyCode: companyDetail[0].companyCode
          ? companyDetail[0].companyCode
          : "",
        companyName: companyDetail[0].companyName
          ? companyDetail[0].companyName
          : "",
        taxNumber: companyDetail[0].taxNumber ? companyDetail[0].taxNumber : "",
        taxLocation: companyDetail[0].taxLocation
          ? companyDetail[0].taxLocation
          : "",
        phoneNumber: companyDetail[0].phoneNumber
          ? companyDetail[0].phoneNumber
          : "",
        email: companyDetail[0].email ? companyDetail[0].email : "",
        logo: "",
      });

      setImage(companyDetail[0].logo ? companyDetail[0].logo : "");

      if (companyDetail[0].addresses) {
        if (companyDetail[0].addresses.length > 0) {
          setCompanyAddress({
            addressTitle: "string",
            cityId: companyDetail[0].addresses[0].city
              ? companyDetail[0].addresses[0].city
              : 0,
            districtId: companyDetail[0].addresses[0].district
              ? companyDetail[0].addresses[0].district
              : 0,
            neighbourhoodId: companyDetail[0].addresses[0].neighbourhood
              ? companyDetail[0].addresses[0].neighbourhood
              : 0,
            street: companyDetail[0].addresses[0].street
              ? companyDetail[0].addresses[0].street
              : "",
            buildNumber: companyDetail[0].addresses[0].buildNumber
              ? companyDetail[0].addresses[0].buildNumber
              : "",
            floorNumber: companyDetail[0].addresses[0].floorNumber
              ? companyDetail[0].addresses[0].floorNumber
              : "",
            doorNumber: companyDetail[0].addresses[0].doorNumber
              ? companyDetail[0].addresses[0].doorNumber
              : "",
            isInvoice: true,
            isDefault: true,
            coordinates: "string",
          });
        } else {
          setCompanyAddress({
            addressTitle: "string",
            cityId: 0,
            districtId: 0,
            neighbourhoodId: 0,
            street: "",
            buildNumber: "",
            floorNumber: "",
            doorNumber: "",
            isInvoice: true,
            isDefault: true,
            coordinates: "string",
          });
        }
      }
    }
  }, [companyId, companyDetail]);

  /* address api */

  function handleChange(e) {
    const { name, value, files } = e.target;

    if (name === "logo") {
      setImage(URL.createObjectURL(files[0]));

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        // console.log("result : ",e.target.result.split("base64,")[1]);

        setCompanyValues({
          ...companyValues,
          [name]: e.target.result.split("base64,")[1],
        });
      };
    } else {
      setCompanyValues({
        ...companyValues,
        [name]: value,
      });
      setCompanyAddress({
        ...companyAddress,
        [name]: value,
      });
    }

    setCompanyValuesErrors({
      companyTypeError: false,
      categoryIdError: false,
      companyCodeError: false,
      companyNameError: false,
      taxNumberError: false,
      taxLocationError: false,
      phoneNumberError: false,
      emailError: false,
    });
    setCompanyAddressError({
      cityError: false,
      districtError: false,
      neighbourhoodError: false,
      streetError: false,
      buildNumberError: false,
    });
  }

  async function handleSaveCompanyInfo(e) {
    e.preventDefault();

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!companyValues.companyName) {
      inputErrors.companyNameError = true;
    }

    if (!companyValues.companyType) {
      inputErrors.companyTypeError = true;
    }

    if (!companyValues.companyCode) {
      inputErrors.companyCodeError = true;
    }

    if (!companyValues.phoneNumber) {
      inputErrors.phoneNumberError = true;
    }

    if (!companyValues.categoryId) {
      inputErrors.categoryIdError = true;
    }

    if (!companyValues.taxNumber) {
      inputErrors.taxNumberError = true;
    }

    if (!companyValues.taxLocation) {
      inputErrors.taxLocationError = true;
    }

    if (!companyValues.email) {
      inputErrors.emailError = true;
    }

    const form = document.getElementById("info-form");

    if (!form.checkValidity() && Object.keys(inputErrors).length > 0) {
      // Gerekli alanlar doldurulmamışsa, hata mesajını göster veya uygun işlemi yap
      console.log("Gerekli alanları doldurun");
      // Eğer hata durumları varsa, setCompanyValuesErrors işlevini çağırın
      setCompanyValuesErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
      return;
    }

    console.log("companyValues : ", companyValues);

    setInfoSaveLoading(true);

    const saveResponse = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_COMPANY_URL,
      JSON.stringify(companyValues),
      token
    );

    setInfoSaveLoading(false);

    if (saveResponse && !saveResponse.catchError && !saveResponse.isError) {
      toast.info("Firma Bilgileri Güncellendi", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setCompanyDetailUseFetchRender(!companyDetailUseFetchRender);

      setcompanyInfoSaveOk(!companyInfoSaveOk);
    }
  }

  async function handleSaveCompanyAddress(e) {
    e.preventDefault();

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!companyAddress.cityId) {
      inputErrors.cityError = true;
    }

    if (!companyAddress.districtId) {
      inputErrors.districtError = true;
    }

    if (!companyAddress.neighbourhoodId) {
      inputErrors.neighbourhoodError = true;
    }

    if (!companyAddress.street) {
      inputErrors.streetError = true;
    }

    if (!companyAddress.buildNumber) {
      inputErrors.buildNumberError = true;
    }

    const form = document.getElementById("address-form");

    if (!form.checkValidity() && Object.keys(inputErrors).length > 0) {
      // Gerekli alanlar doldurulmamışsa, hata mesajını göster veya uygun işlemi yap
      console.log("Gerekli alanları doldurun");
      // Eğer hata durumları varsa, setCompanyAddressError işlevini çağırın
      setCompanyAddressError(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
      return;
    }

    console.log("companyAddress : ", {
      ...companyAddress,
      cityId: Number(selectedCity),
      districtId: Number(selectedDistrict),
      neighbourhoodId: Number(selectedNeighbourhood),
      addressTitle: "string",
      coordinates: "string",
    });

    const companyAddressData = {
      ...companyAddress,
      cityId: Number(selectedCity),
      districtId: Number(selectedDistrict),
      neighbourhoodId: Number(selectedNeighbourhood),
      addressTitle: "string",
      coordinates: "string",
    };

    setAddressSaveLoading(true);

    const saveResponse = await createMethod(
      process.env.REACT_APP_POST_ADD_ADDRESS_URL,
      JSON.stringify(companyAddressData),
      token
    );

    setAddressSaveLoading(false);

    if (saveResponse && !saveResponse.catchError && !saveResponse.isError) {
      toast.info(
        "Adres Bilgileri Güncellendi. Anasayfaya yönlendiriliyorsunuz...",
        {
          autoClose: 5000, // 2 saniye sonra otomatik olarak kapat
        }
      );

      setTimeout(() => {
        navigate("/");
      }, 5000);

      setCompanyUpdateRender(!companyUpdateRender);

      setCompanyDetailUseFetchRender(!companyDetailUseFetchRender);

      setcompanyInfoSaveOk(!companyInfoSaveOk);
    }
  }

  if (userRole !== "Manager" && userRole !== "Admin") {
    navigate("/");

    return;
  }

  return (
    <div className="company-edit-container">
      <div
        className={`company-info-container ${
          companyInfoSaveOk ? "disabled-container" : ""
        }`}
      >
        <div className="info-top-container">
          <div className="title-box">
            <i className="fa-solid fa-briefcase"></i>
            <h4>Firma Bilgileri</h4>
          </div>

          <div className="company-profile-edit-button-groups">
            <WhiteButton
              disabled={infoSaveLoading}
              text={
                infoSaveLoading ? null : (
                  <i className="fa-solid fa-angle-right"></i>
                )
              }
              icon={infoSaveLoading ? "Loading..." : "İleri"}
              onClick={handleSaveCompanyInfo}
            />
          </div>
        </div>
        <form id="info-form">
          <div className="img-container">
            <div className="img-box">
              {image ? (
                <img src={image} alt="Seçilen resim" />
              ) : (
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="profile-img"
                />
              )}
            </div>

            <div className="img-buttons">
              <input
                style={{ display: "none" }}
                multiple
                name="logo"
                onChange={handleChange}
                type="file"
                id="contained-button-file"
                accept="image/*"
              />
              <WhiteButton
                type="button"
                htmlFor="contained-button-file"
                text={<label htmlFor="contained-button-file">{"Yükle"}</label>}
              />

              <WhiteButton type="button" text={"Kaldır"} />
            </div>
          </div>

          <div
            className={`inputs-container ${
              infoSaveLoading ? "disabled-container" : ""
            }`}
          >
            <InputStandart
              required={true}
              errorBorder={companyValuesErrors.companyNameError}
              type={"text"}
              name={"companyName"}
              title={"Firma Adı"}
              placeholder={"Firma Adı..."}
              onChange={handleChange}
              value={companyValues.companyName}
            />

            <div className="label-select">
              <label htmlFor="categoryId" title={companyValues.categoryId}>
                * Firma Kategorisi :
              </label>

              <div className="select-box">
                <select
                  required
                  id="categoryId"
                  name={"categoryId"}
                  value={companyValues.categoryId}
                  onChange={handleChange}
                >
                  {categoryListError ? (
                    <option value="">API ERROR</option>
                  ) : categoryListLoading ? (
                    <option value="">yükleniyor</option>
                  ) : categoryList !== null ? (
                    categoryList.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.categoryName}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">NULL</option>
                  )}
                </select>
              </div>
            </div>

            <InputStandart
              required={true}
              errorBorder={companyValuesErrors.emailError}
              type={"email"}
              name={"email"}
              title={"E-Posta"}
              placeholder={"E-Posta..."}
              onChange={handleChange}
              value={companyValues.email}
            />

            <InputStandart
              required={true}
              errorBorder={companyValuesErrors.phoneNumberError}
              type={"tel"}
              name={"phoneNumber"}
              title={"Telefon"}
              placeholder={"Telefon..."}
              onChange={handleChange}
              value={companyValues.phoneNumber}
            />
            <InputStandart
              required={true}
              errorBorder={companyValuesErrors.taxLocationError}
              type={"tex"}
              name={"taxLocation"}
              title={"Vergi Yeri"}
              placeholder={"Vergi Yeri..."}
              onChange={handleChange}
              value={companyValues.taxLocation}
            />
            <InputStandart
              required={true}
              errorBorder={companyValuesErrors.taxNumberError}
              type={"tex"}
              name={"taxNumber"}
              title={"Vergi Numarası"}
              placeholder={"Vergi Numarası..."}
              onChange={handleChange}
              value={companyValues.taxNumber}
            />
          </div>
        </form>
      </div>

      <div
        className={`company-address-container ${
          companyInfoSaveOk ? "" : "disabled-container"
        }`}
      >
        <div className="address-top-container">
          <div className="title-box">
            <i className="fa-solid fa-location-dot"></i>
            <h4>Adres Bilgileri</h4>
          </div>

          <div className="company-profile-edit-button-groups">
            <WhiteButton
              disabled={addressSaveLoading}
              icon={
                addressSaveLoading ? null : (
                  <i className="fa-solid fa-floppy-disk"></i>
                )
              }
              text={addressSaveLoading ? "Loading..." : "Kaydet"}
              onClick={handleSaveCompanyAddress}
            />
          </div>
        </div>
        <form
          id="address-form"
          className={addressSaveLoading ? "disabled-container" : ""}
        >
          <div className="label-select">
            <label htmlFor="city" title={selectedCity}>
              * Şehir :
            </label>

            <div className="select-box">
              <CityComponent
                required={true}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                // currentSelectCity={companyAddress.cityId}
              />
            </div>
          </div>

          <div className="label-select">
            <label htmlFor="districtss" title={selectedDistrict}>
              * İlçe :
            </label>

            <div className="select-box">
              <DistrictComponent
                required={true}
                selectedCity={selectedCity}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                // currentSelectDistrict={companyAddress.districtId}
              />
            </div>
          </div>

          <div className="label-select">
            <label htmlFor="neighbourhood" title={selectedNeighbourhood}>
              * Mahalle :
            </label>

            <div className="select-box">
              <NeighbourhoodComponent
                required={true}
                selectedCity={selectedCity}
                selectedDistrict={selectedDistrict}
                setSelectedNeighbourhood={setSelectedNeighbourhood}
                selectedNeighbourhood={selectedNeighbourhood}
                // currentSelectNeighbourhood={companyAddress.neighbourhoodId}
              />
            </div>
          </div>

          <InputStandart
            required={true}
            errorBorder={companyAddressError.streetError}
            type={"text"}
            name={"street"}
            title={"Cadde/Sokak"}
            placeholder={"Cadde/Sokak..."}
            onChange={handleChange}
            value={companyAddress.street}
          />

          <InputStandart
            required={true}
            errorBorder={companyAddressError.buildNumberError}
            type={"text"}
            name={"buildNumber"}
            title={"Bina No"}
            placeholder={"Bina No..."}
            onChange={handleChange}
            value={companyAddress.buildNumber}
          />

          <InputStandart
            type={"text"}
            name={"floorNumber"}
            title={"Kat No"}
            placeholder={"Kat No..."}
            onChange={handleChange}
            value={companyAddress.floorNumber}
          />

          <InputStandart
            type={"text"}
            name={"doorNumber"}
            title={"Daire No"}
            placeholder={"Daire No..."}
            onChange={handleChange}
            value={companyAddress.doorNumber}
          />
        </form>
      </div>
    </div>
  );
}

export default CompanyEdit;
