import "./firstTimeOfferexModal.css";
import React, { useEffect } from "react";
import { Modal, Space } from "antd";
import images from "../../../assets/images";

function FirstTimeOfferexModal() {
  useEffect(() => {
    const isFirstTimeOfferex = localStorage.getItem("isFirstTimeOfferex");

    if (isFirstTimeOfferex) {
      showTutorialModal();
      localStorage.removeItem("isFirstTimeOfferex");
    }
  }, []);

  const showTutorialModal = () => {
    Modal.info({
      width: "95%",
      title: "Bilgilendirme",
      content: (
        <div className="first-time-offerex-modal-container">
          <h1 className="title">Hoş Geldiniz!</h1>
          <p>
            İhale portalımıza ilk kez giren kullanıcı olarak sizleri burada
            ağırlamaktan mutluluk duyuyoruz.
          </p>
          <p>
            Bu portal, ana firma, tedarikçi ve her ikisine birden yönelik
            çeşitli işlemler yapmanızı sağlar. İhale açma ve teklif gönderme
            gibi önemli işlemleri gerçekleştirebilirsiniz.
          </p>
          <p>
            Sistemdeki işlemler genellikle tablo üzerinde yapılır. Teklif
            göndermek, güncellemek veya detay sayfalarına gitmek gibi işlemleri
            yapmak için tablodan ilgili satırı seçip üstte bulunan butonlardan
            işleme tıklamalısınız.
          </p>
          <img src={images.tableSelectGif} alt="table_select" />
          <p>
            Ayrıca, herhangi bir sorunla karşılaşırsanız veya bir öneriniz
            varsa, sağ alt köşede bulunan "?" sembolü olan yardım butonuna
            tıklayarak destek talebi oluşturabilirsiniz. Destek talebinizi
            göndermek için açılan sayfadan talebinizi iletebilirsiniz. Ayrıca,
            "Destek Talepleri" sayfasından da bu işlemi gerçekleştirebilirsiniz.
          </p>
          <p>
            Portalı daha iyi anlamak için "Nasıl Çalışır?" sayfasına veya aynı
            sağ alttaki yardım butonuna tıklayarak açılan menüde bulunan "Nasıl
            Çalışır?" bölümüne tıklayarak özel yardım videolarına
            ulaşabilirsiniz.
          </p>
        </div>
      ),
      okText: "Tamam",
      onOk() {},
      bodyStyle: {
        overflow: "hidden",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 24,
        paddingRight: 24,
      },
    });
  };

  return <Space></Space>;
}

export default FirstTimeOfferexModal;
