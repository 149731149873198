import "./styles/register.css";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import Modal from "react-modal";

import icons from "../../assets/icons/index";

import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import InputRadioText from "../../components/form_components/Inputs/InputRadioText/InputRadioText";
import PrivacyPolicyModal from "../../components/Modals/PrivacyPolicyModal/PrivacyPolicyModal";
import { ThemeContextValue } from "../../context/ThemeContext";
import LangBox from "../../components/LangBox/LangBox";

const { offerexLogoDark, offerexLogoLight } = icons;

const localStorageSystemLang = localStorage.getItem("i18nextLng");

function Register() {
  const navigate = useNavigate();
  const { userId, refToken } = useParams();

  const { theme } = ThemeContextValue();

  const [registerLoading, setRegisterLoading] = useState(false);

  const [formInputErrors, setFormInputErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    phoneNumberError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const handlePrivacyPolicyClick = () => setIsPrivacyPolicyOpen(true);
  const handleClosePrivacyPolicy = () => setIsPrivacyPolicyOpen(false);

  const [policyState, setPolicyState] = useState({
    confidentialityAgreement: false,
    userAgreement: false,
  });

  const [registerValues, setRegisterValues] = useState({
    userId: userId,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    language: localStorageSystemLang ? localStorageSystemLang : "tr",
    image: "",
    resetToken: refToken,
  });

  const [image, setImage] = useState();
  const [capturedImage, setCapturedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const webcamRef = useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setCapturedImage(imageSrc);

    setRegisterValues({
      ...registerValues,
      image: imageSrc.split("base64,")[1],
    });
  };

  const handleCancel = () => {
    setCapturedImage(null);
    setModalIsOpen(false);
  };

  function handleChange(e) {
    const { name, value, files } = e.target;

    if (name === "image") {
      setImage(URL.createObjectURL(files[0]));

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        // console.log("result : ",e.target.result.split("base64,")[1]);

        setRegisterValues({
          ...registerValues,
          [name]: e.target.result.split("base64,")[1],
        });
      };
    } else {
      setRegisterValues({
        ...registerValues,
        [name]: value,
      });
    }
  }

  useEffect(() => {
    /* reset token onay işlemi */
    async function verifyToken() {
      // const response = await createMethod(process.env.REACT_APP_POST_RESET_TOKEN_URL+`?resetToken=${refToken}&userId=${userId}`, null, null);
      const response = await axios.post(
        `http://sosocrm.oetech.online/api/Auth/verify-reset-token?resetToken=${refToken}&userId=${userId}`
      );

      console.log("responseVerifyToken: ", response);
    }

    verifyToken();
  }, [userId, refToken]);

  async function handleSubmit(e) {
    e.preventDefault();

    console.log("registerValues : ", registerValues);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!registerValues.firstName) {
      inputErrors.firstName = true;
    }

    if (!registerValues.lastName) {
      inputErrors.lastNameError = true;
    }

    if (!registerValues.phoneNumber) {
      inputErrors.phoneNumberError = true;
    }

    if (!registerValues.password) {
      inputErrors.passwordError = true;
    }

    if (!registerValues.confirmPassword) {
      inputErrors.confirmPasswordError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setFormInputErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
    } else {
      if (registerValues.password !== registerValues.confirmPassword) {
        alert("Şifre ve Şifre Tekrar aynı değil!");
      } else {
        setRegisterLoading(true);

        try {
          const response = await axios.post(
            process.env.REACT_APP_POST_CONFIRMED_REGISTER_URL,
            JSON.stringify(registerValues),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          if (!response.data.isError) {
            console.log("resdata: ", response.data.data);

            toast.info("Giriş sayfasına yönlendiriliyorsunuz.", {
              autoClose: 3000, // 3 saniye sonra otomatik olarak kapat
            });

            setTimeout(() => {
              navigate("/login");
            }, 3000);

            toast.success(response.data.data, {
              autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
            });
          }
        } catch (err) {
          console.log("REACT_APP_POST_CONFIRMED_REGISTER_URL error : ", err);

          toast.error("Bir sorun oluştu !");
        }

        setRegisterLoading(false);
      }
    }
  }

  return (
    <div className="register-container">
      <div className="offerex-logo-container">
        <img
          src={
            theme === "light"
              ? offerexLogoDark
              : theme === "dark"
              ? offerexLogoLight
              : offerexLogoDark
          }
          alt="offerex-logo"
        />
      </div>

      <form className="register-box" onSubmit={handleSubmit}>
        <h5>Kullanmaya başlamak için kayıt olun</h5>

        <div className="form-line">
          <div className="img-container">
            {image ? (
              <img src={image} alt="Seçilen resim" />
            ) : (
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="profile-img"
              />
            )}

            <div className="img-buttons">
              <input
                style={{ display: "none" }}
                multiple
                name="image"
                onChange={handleChange}
                type="file"
                id="contained-button-file"
                accept="image/*"
              />
              <ButtonWhiteOpacity
                type={"button"}
                text={<label htmlFor="contained-button-file">{"Yükle"}</label>}
              />

              <ButtonWhiteOpacity
                text={"Çek"}
                type="button"
                onClick={() => setModalIsOpen(true)}
              />
            </div>

            {modalIsOpen && (
              <Modal
                isOpen={modalIsOpen}
                className="capture-modal"
                ariaHideApp={false}
              >
                <div className="capture-modal-container">
                  <div className="capture-modal-box">
                    {capturedImage === null && (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        className="capture-modal-webcam"
                      />
                    )}
                    {capturedImage ? (
                      <>
                        <img src={capturedImage} alt="Captured" />
                        <div className="modal-button-groups">
                          <button
                            onClick={() => {
                              setModalIsOpen(false);
                              setCapturedImage(null);
                            }}
                          >
                            Onayla
                          </button>
                          <button onClick={() => setCapturedImage(null)}>
                            Tekrar Çek
                          </button>
                          <button onClick={handleCancel}>İptal</button>
                        </div>
                      </>
                    ) : (
                      <div className="modal-button-groups">
                        <button onClick={handleCapture}>Çek</button>
                        <button onClick={handleCancel}>İptal</button>
                      </div>
                    )}
                  </div>
                </div>
              </Modal>
            )}
          </div>
          <div className="inputs-container">
            <InputStandart
              required={true}
              errorBorder={formInputErrors.firstNameError}
              title={"Ad"}
              name="firstName"
              type="text"
              placeholder={`${"Ad"}...`}
              onChange={handleChange}
              value={registerValues.firstName}
            />

            <InputStandart
              required={true}
              errorBorder={formInputErrors.lastNameError}
              title={"Soyad"}
              name="lastName"
              type="text"
              placeholder={`${"Soyad"}...`}
              onChange={handleChange}
              value={registerValues.lastName}
            />

            <InputStandart
              required={true}
              errorBorder={formInputErrors.phoneNumberError}
              title={"Telefon"}
              name="phoneNumber"
              type="tel"
              placeholder={`${"Telefon"}...`}
              onChange={handleChange}
              value={registerValues.phoneNumber}
            />

            <InputStandart
              required={true}
              errorBorder={formInputErrors.passwordError}
              title={"Şifre"}
              name="password"
              type="password"
              placeholder={`${"Şifre"}...`}
              onChange={handleChange}
              value={registerValues.password}
            />

            <InputStandart
              required={true}
              errorBorder={formInputErrors.confirmPasswordError}
              title={"Şifre Tekrar"}
              name="confirmPassword"
              type="password"
              placeholder={`${"Şifre Tekrar"}...`}
              onChange={handleChange}
              value={registerValues.confirmPassword}
            />

            <div className="agreement-buttons">
              <div className="policy-container">
                <div
                  className={`policy-box ${
                    policyState.confidentialityAgreement ? "" : "policy-opacity"
                  }`}
                >
                  <div className="policy-text">
                    <span
                      className="policy-link"
                      onClick={handlePrivacyPolicyClick}
                    >
                      <i className="fa-solid fa-file-invoice"></i>
                      Gizlilik Sözleşmesi
                    </span>
                    <span>' ni kabul ediyorum.</span>
                  </div>
                  <div
                    onClick={() =>
                      setPolicyState({
                        ...policyState,
                        confidentialityAgreement:
                          !policyState.confidentialityAgreement,
                      })
                    }
                    className="input-box"
                  >
                    {policyState.confidentialityAgreement ? (
                      <i className="fa-solid fa-circle-check"></i>
                    ) : (
                      <i className="fa-regular fa-circle-check"></i>
                    )}
                  </div>
                </div>

                <div
                  className={`policy-box ${
                    policyState.userAgreement ? "" : "policy-opacity"
                  }`}
                >
                  <div className="policy-text">
                    <span
                      className="policy-link"
                      onClick={handlePrivacyPolicyClick}
                    >
                      <i className="fa-solid fa-file-invoice"></i>
                      Kullanıcı Sözleşmesi
                    </span>
                    <span>' ni kabul ediyorum.</span>
                  </div>

                  <div
                    onClick={() =>
                      setPolicyState({
                        ...policyState,
                        userAgreement: !policyState.userAgreement,
                      })
                    }
                    className="input-box"
                  >
                    {policyState.userAgreement ? (
                      <i className="fa-solid fa-circle-check"></i>
                    ) : (
                      <i className="fa-regular fa-circle-check"></i>
                    )}
                  </div>
                </div>
              </div>

              {/* Gizlilik sözleşmesi modalı */}
              {isPrivacyPolicyOpen && (
                <PrivacyPolicyModal
                  isOpen={isPrivacyPolicyOpen}
                  onClose={handleClosePrivacyPolicy}
                />
              )}
            </div>
          </div>
        </div>

        <ButtonWhiteOpacity
          disabled={
            policyState.confidentialityAgreement && policyState.userAgreement
              ? false
              : true
          }
          type="submit"
          text={registerLoading ? "Loading..." : "Kayıt Ol"}
          className="button-apply"
        />
      </form>

      <div className="lang-box">
        <LangBox data={registerValues} setData={setRegisterValues} />
      </div>

      <div className="copyright-container">
        <p>OETech Solutions - 2023</p>
      </div>
    </div>
  );
}

export default Register;
