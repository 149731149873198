import "./notification.css";
import { useEffect, useState } from "react";
import NotificationEditBox from "../../components/EditBox/NotificationEditBox";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import useFetch from "../../hooks/UseFetch";
import NotificationBox from "../../components/NotificationBox/NotificationBox";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Loading from "../../components/Loading/Loading";
import { GetContextValue } from "../../context/GetContext";
import Pagination from "../../components/Table/Pagination/Pagination";

function Notifications() {
  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();
  const { notificationRender, setNotificationRender } = GetContextValue();

  const [searchText, setSearchText] = useState("");

  const [notificationsPage, setNotificationsPage] = useState(1);
  const [notificationsRowsPerPage, setNotificationsRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setNotificationsUseFetchRender,
    useFetchRender: notificationsUseFetchRender,
    error: notificationsError,
    loading: notificationsLoading,
    data: notifications,
    total: notificationsTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_NOTIFICATION_URL}?Page=${notificationsPage}&PageSize=${notificationsRowsPerPage}`,
    token
  );

  useEffect(() => {
    setNotificationsUseFetchRender(!notificationsUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    setSelectedNotifications([]);
  }, [notificationsUseFetchRender]);

  function handleDeleteNotification() {
    console.log("delete noti");
  }

  const [selectedNotifications, setSelectedNotifications] = useState([]);
  // Bildirimleri seçmek için
  const handleSelect = (notificationId, isSelected) => {
    if (isSelected) {
      const newSelectedNotification = selectedNotifications.filter(
        (notification) => notification !== notificationId
      );

      setSelectedNotifications(newSelectedNotification);
    } else {
      setSelectedNotifications([...selectedNotifications, notificationId]);
    }
  };

  const selectAllNotifications = () => {
    if (notifications.length === selectedNotifications.length) {
      setSelectedNotifications([]);
    } else {
      const allNotificationIds = notifications.map(
        (notification) => notification.id
      );
      setSelectedNotifications(allNotificationIds);
    }
  };

  // Okundu olarak işaretlemek için
  const markAsRead = async () => {
    const putData = {
      id: selectedNotifications,
      status: true,
    };

    try {
      await updateMethod(
        process.env.REACT_APP_PUT_UPDATE_NOTIFICATION_STATUS_URL,
        JSON.stringify(putData),
        token
      );

      setNotificationRender(!notificationRender);
      // Başarılı bir şekilde güncellendiğinde bildirimleri güncelleyin
      setNotificationsUseFetchRender(!notificationsUseFetchRender);
      setSelectedNotifications([]);
    } catch (error) {
      // Hata durumunda gerekli işlemleri yapabilirsiniz
    }
  };

  return (
    <div className="notification-container">
      <div className="notification-edit-box">
        <NotificationEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          useFetchRender={notificationsUseFetchRender}
          setUseFetchRender={setNotificationsUseFetchRender}
          handleDeleteNotification={handleDeleteNotification}
          handleMakeRead={markAsRead}
          handleSelectAll={selectAllNotifications}
          selectValue={selectedNotifications}
        />
      </div>
      <div className="notification-main">
        {notificationsError ? (
          <ErrorComponent error={notificationsError} />
        ) : notificationsLoading ? (
          <Loading />
        ) : notifications !== null && notifications.length > 0 ? (
          notifications.map((notification) => {
            return (
              <NotificationBox
                key={notification.id}
                notification={notification}
                isSelected={selectedNotifications.includes(notification.id)}
                handleSelect={handleSelect}
              />
            );
          })
        ) : (
          <div className="table-null-text">Bildirim Yok!</div>
        )}
      </div>
      <div className="pagination">
        <Pagination
          page={notificationsPage}
          setPage={setNotificationsPage}
          rowsPerPage={notificationsRowsPerPage}
          setRowsPerPage={setNotificationsRowsPerPage}
          total={notificationsTotal}
        />
      </div>
    </div>
  );
}

export default Notifications;
