import "./styles/documentsStyle/documentStyle.css";
import { pdfData } from "../../assets/pdf/index";

function DocumentBox({ text, document }) {
  function handleClickDocument() {
    // PDF görüntüleyiciyi açmak için yeni bir pencere oluşturun
    window.open(document, "_blank");
  }
  return (
    <div className="document-box-container" onClick={handleClickDocument}>
      <i className="fa-solid fa-file-pdf" />
      <div className="document-name">
        <p>{text}</p>
      </div>
    </div>
  );
}

function DocumentsPage() {
  return (
    <div className="documents-page-container">
      <DocumentBox
        text={"OfferEx Kullanım Klavuzu"}
        document={pdfData.offerExTutorialPdf}
      />
    </div>
  );
}

export default DocumentsPage;
