import "./companyDetailCard.css";
import React, { useEffect, useState } from "react";
import DetailText from "../DetailText/DetailText";
import { getCompanyStatistics } from "../../hooks/getCompanyStatistics";
import { AuthContextValue } from "../../context/AuthContext";

function addressFilter(address) {
  let output = "-";

  if (address) {
    if (address.city) {
      output = address.city;
    }

    if (address.district) {
      output = output.concat("/", address.district);
    }

    if (address.neighbourhood) {
      output = output.concat("/", address.neighbourhood);
    }

    if (address.street) {
      output = output.concat("/", address.street);
    }

    if (address.floorNumber) {
      output = output.concat("/", address.floorNumber, ". kat");
    }

    if (address.doorNumber) {
      output = output.concat("/", address.doorNumber);
    }
  }

  return output;
}

function CompanyDetailCard({ companyDetail }) {
  const { token } = AuthContextValue();
  const [companyStatistic, setCompanyStatistic] = useState({});

  useEffect(() => {
    async function getData() {
      const responseData = await getCompanyStatistics(
        companyDetail.companyId,
        companyDetail.companyType,
        token
      );

      setCompanyStatistic(responseData);
    }

    if (companyDetail) {
      getData();
    }
  }, [companyDetail, token]);

  console.log(companyDetail);

  return (
    <div className="company-detail-card-container">
      <div className="company-detail-card-top-box">
        <div className="image-box">
          <img src={companyDetail.logo} alt="foto" />
        </div>
        <div className="user-detail-name-role">
          <h4>
            <strong>{companyDetail.companyName} </strong>
          </h4>
          <p>
            {companyDetail.companyType === 1
              ? "Ana Firma"
              : companyDetail.companyType === 2
              ? "Tedarikçi"
              : companyDetail.companyType === 3
              ? "Ana Firma - Tedarikçi"
              : "null"}
          </p>
        </div>
      </div>
      <div className="company-detail-card-bottom-box">
        <DetailText title={"E-Posta:"} text={companyDetail.email} />
        <DetailText title={"Telefon:"} text={companyDetail.phoneNumber} />
        <DetailText title={"Üyelik Tarihi:"} text={companyDetail.createdAt} />
        <DetailText
          title={"Adres"}
          text={addressFilter(companyDetail.addresses[0])}
        />
        <DetailText title={"Yönetici:"} text={companyDetail.managerName} />
        <DetailText
          title={"Yönetici E-Posta:"}
          text={companyDetail.managerEmail}
        />
        <DetailText
          title={"Firma Kategorisi:"}
          text={companyDetail.categoryName}
        />
        {companyDetail.companyType === 1 && (
          <>
            <DetailText
              title={"Aktif İhale:"}
              text={`${companyStatistic.tenderActive} Adet`}
            />
            <DetailText
              title={"Sonuçlanan İhale:"}
              text={`${companyStatistic.tenderOver} Adet`}
            />
            {/* <DetailText
              title={"Toplam İhale:"}
              text={`${companyStatistic.tenderTotal} Adet`}
            /> */}
            <DetailText
              title={"Toplam İhale:"}
              text={`${
                companyStatistic.tenderActive + companyStatistic.tenderOver
              } Adet`}
            />
          </>
        )}

        {companyDetail.companyType === 2 && (
          <>
            <DetailText
              title={"Başarılı Teklif:"}
              text={`${companyStatistic.offerSuccess} Adet`}
            />
            <DetailText
              title={"Başarısız Teklif:"}
              text={`${companyStatistic.offerUnSuccessful} Adet`}
            />
            <DetailText
              title={"Onay Bekleyen Teklif:"}
              text={`${companyStatistic.offerWaitingApp} Adet`}
            />
            {/* <DetailText
              title={"Toplam Teklif:"}
              text={`${companyStatistic.offerTotal} Adet`}
            /> */}
            <DetailText
              title={"Toplam Teklif:"}
              text={`${
                companyStatistic.offerSuccess +
                companyStatistic.offerUnSuccessful +
                companyStatistic.offerWaitingApp
              } Adet`}
            />
          </>
        )}

        {companyDetail.companyType === 3 && (
          <>
            <DetailText
              title={"Aktif İhale:"}
              text={`${companyStatistic.tenderActive} Adet`}
            />
            <DetailText
              title={"Sonuçlanan İhale:"}
              text={`${companyStatistic.tenderOver} Adet`}
            />
            {/* <DetailText
              title={"Toplam İhale:"}
              text={`${companyStatistic.tenderTotal} Adet`}
            /> */}
            <DetailText
              title={"Toplam İhale:"}
              text={`${
                companyStatistic.tenderActive + companyStatistic.tenderOver
              } Adet`}
            />

            <DetailText
              title={"Başarılı Teklif:"}
              text={`${companyStatistic.offerSuccess} Adet`}
            />
            <DetailText
              title={"Başarısız Teklif:"}
              text={`${companyStatistic.offerUnSuccessful} Adet`}
            />
            <DetailText
              title={"Onay Bekleyen Teklif:"}
              text={`${companyStatistic.offerWaitingApp} Adet`}
            />
            {/* <DetailText
              title={"Toplam Teklif:"}
              text={`${companyStatistic.offerTotal} Adet`}
            /> */}
            <DetailText
              title={"Toplam Teklif:"}
              text={`${
                companyStatistic.offerSuccess +
                companyStatistic.offerUnSuccessful +
                companyStatistic.offerWaitingApp
              } Adet`}
            />
          </>
        )}

        <DetailText title={"Puan:"} text={companyDetail.averageScore ? companyDetail.averageScore : "-"} />
      </div>
    </div>
  );
}

export default CompanyDetailCard;
