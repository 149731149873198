import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { DemoContextValue } from "../../context/DemoContext";

function RevisedTenders({
  t,
  tableFilter,
  searchText,
  tableRender,
  setRevisedNote,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();
  const { demoRevisedTenders } = DemoContextValue();

  const [revisedTendersPage, setRevisedTendersPage] = useState(1);
  const [revisedTendersRowsPerPage, setRevisedTendersRowsPerPage] =
    useState(10);

  const status = 3;

  const {
    setUseFetchRender: setRevisedTendersUseFetchRender,
    useFetchRender: revisedTendersUseFetchRender,
    error: revisedTendersError,
    loading: revisedTendersLoading,
    data: revisedTenders,
    total: revisedTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?Status=${status}&CompanyId=${loginUserCompanyId}&Last=false&SortType=${tableFilter}&SearchedText=${searchText}&Page=${revisedTendersPage}&PageSize=${revisedTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: "Foto",
    },
    {
      key: "status",
      header: "Durum",
    },
    {
      key: "tenderNumber",
      header: "İhale No",
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: "Ürün/Hizmet",
    },
    {
      key: "createdAt",
      header: "Tarih",
    },
    {
      key: "minPrice",
      header: "Min.Bütçe",
    },
    {
      key: "maxPrice",
      header: "Max.Bütçe",
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
    setRevisedNote(value.confirmDescription);
  }

  useEffect(() => {
    setRevisedTendersUseFetchRender(!revisedTendersUseFetchRender);
  }, [tableRender, notificationRender]);

  return (
    <>
      {revisedTendersError ? (
        <ErrorComponent error={revisedTendersError} />
      ) : revisedTendersLoading ? (
        <Loading />
      ) : revisedTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={revisedTendersUseFetchRender}
          setUseFetchRender={setRevisedTendersUseFetchRender}
          total={revisedTendersTotal}
          page={revisedTendersPage}
          setPage={setRevisedTendersPage}
          rowsPerPage={revisedTendersRowsPerPage}
          setRowsPerPage={setRevisedTendersRowsPerPage}
          columns={columns}
          data={revisedTenders}
        />
      ) : (
        <div className="table-null-text">{t("message_no_revised_tender")}</div>
      )}

      {/* {demoRevisedTenders ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={revisedTendersUseFetchRender}
          setUseFetchRender={setRevisedTendersUseFetchRender}
          total={revisedTendersTotal}
          page={revisedTendersPage}
          setPage={setRevisedTendersPage}
          rowsPerPage={revisedTendersRowsPerPage}
          setRowsPerPage={setRevisedTendersRowsPerPage}
          columns={columns}
          data={demoRevisedTenders}
        />
      ) : null} */}
    </>
  );
}

export default withNamespaces()(RevisedTenders);
