import "./styles/editBox.css";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import ButtonWhite from "../form_components/Buttons/ButtonWhite/ButtonWhite";
import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import Select from "../form_components/Select/Select";
import { CrudContextValue } from "../../context/CrudContext";
import { AuthContextValue } from "../../context/AuthContext";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";

function TenderEditBox({
  t,

  type,

  tenderStatus,

  title,
  titleIcon,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenConfirmModal,
  handleOpenRevisedModal,
  handleOpenDeleteModal,
  handleOpenRevisedNote,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */

  const { pathname } = useLocation();

  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* DROPDOWN-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "add":
        console.log("Ekle ye tıklandı!");

        setSearchOpen(false);

        break;

      case "note":
        console.log("Not a tıklandı!");

        handleOpenRevisedNote();

        setSearchOpen(false);

        break;

      case "revised":
        console.log("Revize ye tıklandı!");

        handleOpenRevisedModal();

        setSearchOpen(false);

        break;

      case "confirm":
        console.log("Onayla ya tıklandı!");

        handleOpenConfirmModal();

        setSearchOpen(false);

        break;

      case "edit":
        console.log("Edit e tıklandı!");
        console.log("düzenlenicek value : ", selectValue);

        setSearchOpen(false);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        handleOpenDeleteModal();

        setSearchOpen(false);
        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: 1, text: "Artan Fiyat" },
    { key: 2, text: "Azalan Fiyat" },
    { key: 3, text: "En Yeniden Eskiye" },
    { key: 4, text: "En Eskiden Yeniye" },
  ];

  return (
    <div className="edit-container">
      <div className="edit-box">
        <div className="edit-container-title">
          <h4 className="title">
            {" "}
            <i className="fa-solid fa-star" /> İhaleler
          </h4>
        </div>

        <div className="edit-container-button-group">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-right" />}
            tooltip="Yenile"
            onClick={() => handleClick("render")}
            className="button-white-active"
          />

          <WhiteButton
            icon={
              isSearchOpen ? (
                <div ref={searchRef} className="search-container">
                  <i className="fa-solid fa-magnifying-glass" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              ) : (
                <i className="fa-solid fa-magnifying-glass" />
              )
            }
            tooltip={"Ara"}
            onClick={() => handleClick("search")}
          />

          <Select
            name="filter"
            options={selectOptions}
            select={tableFilter}
            setSelect={setTableFilter}
            className="select-filter-sort"
          />

          {selectValue?.status === "2" || selectValue?.status === "5" ? (
            <Link
              to={
                selectValueId || selectValue
                  ? `/tenders/tender-detail/${selectValueId}`
                  : false
              }
            >
              <WhiteButton
                icon={<i className="fa-solid fa-info"></i>}
                tooltip={"Detay"}
                disabled={selectValueId || selectValue ? false : true}
              />
            </Link>
          ) : (
            <Link
              to={
                selectValueId || selectValue
                  ? `/tenders/tender-detail-manager/${selectValueId}`
                  : false
              }
            >
              <WhiteButton
                icon={<i className="fa-solid fa-info"></i>}
                tooltip={"Detay"}
                disabled={selectValueId || selectValue ? false : true}
              />
            </Link>
          )}

          {tenderStatus === "2" && (
            <WhiteButton
              icon={<i className="fa-solid fa-pen-to-square"></i>}
              tooltip={t("button_edit_revised_note")}
              onClick={() => handleClick("revised-note")}
              disabled={selectValueId || selectValue ? false : true}
            />
          )}

          {tenderStatus === "3" && (
            <>
              <WhiteButton
                icon={<i className="fa-regular fa-note-sticky"></i>}
                tooltip={"Revize Notu"}
                onClick={() => handleClick("note")}
                disabled={selectValueId || selectValue ? false : true}
              />
              <Link to={`tender-edit/${selectValueId}`}>
                <WhiteButton
                  icon={<i className="fa-solid fa-pen-to-square"></i>}
                  tooltip={t("button_edit_update")}
                  onClick={() => handleClick("edit")}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </Link>
            </>
          )}

          {tenderStatus === "1" && (
            <>
              <WhiteButton
                icon={<i className="fa-solid fa-arrows-rotate"></i>}
                tooltip={"Revize İste"}
                onClick={() => handleClick("revised")}
                disabled={selectValueId || selectValue ? false : true}
              />

              <WhiteButton
                icon={<i className="fa-solid fa-check"></i>}
                tooltip={t("button_edit_confirm")}
                onClick={() => handleClick("confirm")}
                disabled={selectValueId || selectValue ? false : true}
              />
            </>
          )}
          {tenderStatus === "1" || tenderStatus === "3" ? (
            <WhiteButton
              icon={<i className="fa-solid fa-trash-can"></i>}
              tooltip={t("button_edit_delete")}
              onClick={() => handleClick("delete")}
              disabled={selectValueId || selectValue ? false : true}
            />
          ) : null}

          {pathname !== "/opportunities" && (
            <Link to={`tender-add`}>
              <WhiteButton
                icon={<i className="fa-solid fa-plus" />}
                tooltip={"Oluştur"}
                onClick={() => handleClick("add")}
                className="button-white-active"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderEditBox);
