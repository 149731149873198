import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function FinalizedTenders({
  t,
  tableFilter,
  searchText,
  tableRender,
  setRevisedNote,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [finalizedTendersPage, setFinalizedTendersPage] = useState(1);
  const [finalizedTendersRowsPerPage, setFinalizedTendersRowsPerPage] =
    useState(10);

  const status = 5;

  const {
    setUseFetchRender: setFinalizedTendersUseFetchRender,
    useFetchRender: finalizedTendersUseFetchRender,
    error: finalizedTendersError,
    loading: finalizedTendersLoading,
    data: finalizedTenders,
    total: finalizedTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?Status=${status}&CompanyId=${loginUserCompanyId}&Last=false&SortType=${tableFilter}&SearchedText=${searchText}&Page=${finalizedTendersPage}&PageSize=${finalizedTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: "Foto",
    },
    {
      key: "offers",
      header: "Teklifler",
    },
    {
      key: "tenderNumber",
      header: "İhale No",
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: "Ürün/Hizmet",
    },
    {
      key: "createdAt",
      header: "Tarih",
    },
    {
      key: "remainingTime",
      header: "Kalan Süre",
    },
    {
      key: "minPrice",
      header: "Min.Bütçe",
    },
    {
      key: "maxPrice",
      header: "Max.Bütçe",
    },
  ];
  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setFinalizedTendersUseFetchRender(!finalizedTendersUseFetchRender);
  }, [tableRender, notificationRender]);

  return (
    <>
      {finalizedTendersError ? (
        <ErrorComponent error={finalizedTendersError} />
      ) : finalizedTendersLoading ? (
        <Loading />
      ) : finalizedTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={finalizedTendersUseFetchRender}
          setUseFetchRender={setFinalizedTendersUseFetchRender}
          total={finalizedTendersTotal}
          page={finalizedTendersPage}
          setPage={setFinalizedTendersPage}
          rowsPerPage={finalizedTendersRowsPerPage}
          setRowsPerPage={setFinalizedTendersRowsPerPage}
          columns={columns}
          data={finalizedTenders}
        />
      ) : (
        <div className="table-null-text">{t("message_no_revised_tender")}</div>
      )}
    </>
  );
}

export default withNamespaces()(FinalizedTenders);
