import "./select.css";

import { withNamespaces } from "react-i18next";

import { GetContextValue } from "../../../context/GetContext";
import { Tooltip } from "antd";

function SelectRoles({
  t,

  name,
  id,
  value,
  onChange,
}) {
  const { roleList, roleListError, roleListLoading } = GetContextValue();

  return (
    <Tooltip placement="top" title={"Filtrele"}>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="select-filter-roles"
        multiple={false}
      >
        <option value="">Roles</option>
        {roleListError ? (
          <option>API {t("error")}</option>
        ) : roleListLoading ? (
          <option>{t("loading")}</option>
        ) : roleList !== null ? (
          roleList.map((role) => {
            return (
              <option key={role.roleId} value={[role.roleId, role.roleName]}>
                {role.roleName}
              </option>
            );
          })
        ) : (
          <option>NULL</option>
        )}
      </select>
    </Tooltip>
  );
}

export default withNamespaces()(SelectRoles);
