import "./styles/editBox.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Select from "../form_components/Select/Select";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { Tooltip } from "antd";

function OfferEditBox({
  t,

  type,

  searchText,
  setSearchText,

  offersPageLink,

  tableFilter,
  setTableFilter,

  statusFilter,
  setStatusFilter,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenConfirmModal,
  handleOpenDeleteModal,
  handleOpenRevisedModal,
  handleOpenRevisedNoteModal,
  handleOpenResultRevisedNoteModal,
}) {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* DROPDOWN-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!", useFetchRender);

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "revised":
        console.log("Revize ye tıklandı!");

        handleOpenRevisedModal();

        break;

      case "delete":
        console.log("delete a tıklandı!");

        handleOpenDeleteModal();

        setSearchOpen(false);

        break;

      case "confirm":
        console.log("offer a tıklandı!");

        handleOpenConfirmModal();

        setSearchOpen(false);

        break;

      case "revised-note":
        console.log("revised-note a tıklandı!");

        handleOpenRevisedNoteModal();

        setSearchOpen(false);

        break;

      case "result-revised-note":
        console.log("result-revised-note a tıklandı!");

        handleOpenResultRevisedNoteModal();

        setSearchOpen(false);

        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: 1, text: "Artan Fiyat" },
    { key: 2, text: "Azalan Fiyat" },
    { key: 3, text: "En Yeniden Eskiye" },
    { key: 4, text: "En Eskiden Yeniye" },
  ];

  return (
    <div className="edit-container">
      <div className="edit-box">
        <div className="edit-container-title">
          <h4 className="title">
            {" "}
            <i className="fa-solid fa-star" /> Teklfiler
          </h4>
        </div>

        <div className="edit-container-button-group">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-right" />}
            tooltip={"Yenile"}
            onClick={() => handleClick("render")}
          />

          {/* <WhiteButton
            icon={<i className="fa-solid fa-magnifying-glass" />}
            text={
              isSearchOpen ? (
                <div ref={searchRef} className="search-container">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              ) : null
            }
            onClick={() => handleClick("search")}
          /> */}
          <WhiteButton
            icon={
              isSearchOpen ? (
                <div ref={searchRef} className="search-container">
                  <i className="fa-solid fa-magnifying-glass" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              ) : (
                <i className="fa-solid fa-magnifying-glass" />
              )
            }
            tooltip={"Ara"}
            onClick={() => handleClick("search")}
          />

          <Select
            name="filter"
            options={selectOptions}
            select={tableFilter}
            setSelect={setTableFilter}
            className="select-filter-sort"
          />

          {offersPageLink === "waiting-offer" && (
            <>
              <Tooltip placement="top" title={"Filtrele"}>
                <select onChange={(e) => setStatusFilter(e.target.value)}>
                  <option value={"all"}>Tümü</option>
                  <option value={1}>Bekliyor</option>
                  <option value={3}>Revize İstendi</option>
                </select>
              </Tooltip>

              <WhiteButton
                icon={<i className="fa-solid fa-trash-can"></i>}
                tooltip={"Sil"}
                onClick={() => handleClick("delete")}
                disabled={selectValueId || selectValue ? false : true}
              />
            </>
          )}

          <Link
            to={
              selectValueId || selectValue
                ? `offer-detail/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              icon={<i className="fa-solid fa-info" />}
              tooltip={"Detay"}
              disabled={selectValueId || selectValue ? false : true}
            />
          </Link>

          {selectValue?.result === "3" && (
            <Link
              to={`/opportunities/tender-detail-offer/${selectValue?.tenderId}`}
            >
              <WhiteButton
                icon={<i className="fa-solid fa-cart-plus"></i>}
                tooltip={"Teklif Ver"}
              />
            </Link>
          )}

          {selectValue?.result === "3" && (
            <WhiteButton
              icon={<i className="fa-solid fa-note-sticky"></i>}
              tooltip={"Revize Notu"}
              onClick={() => handleClick("result-revised-note")}
              disabled={selectValueId || selectValue ? false : true}
            />
          )}

          {selectValue?.status === "3" ? (
            <>
              <WhiteButton
                icon={<i className="fa-solid fa-note-sticky"></i>}
                tooltip={"Revize Notu"}
                onClick={() => handleClick("revised-note")}
                disabled={selectValueId || selectValue ? false : true}
              />
              <Link to={`offer-edit/${selectValueId}`}>
                <WhiteButton
                  icon={<i className="fa-solid fa-pen-to-square"></i>}
                  tooltip={"Düzenle"}
                  onClick={() => handleClick("update")}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </Link>
            </>
          ) : selectValue?.status === "1" ? (
            <>
              <WhiteButton
                icon={<i className="fa-solid fa-rotate"></i>}
                tooltip={"Revize İste"}
                onClick={() => handleClick("revised")}
                disabled={selectValueId || selectValue ? false : true}
              />

              <WhiteButton
                icon={<i className="fa-solid fa-check"></i>}
                tooltip={"Onayla"}
                onClick={() => handleClick("confirm")}
                disabled={selectValueId || selectValue ? false : true}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default OfferEditBox;
