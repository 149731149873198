import "./styles/mainCompanies.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { GetContextValue } from "../../context/GetContext";
import MainCompaniesEditBox from "../../components/EditBox/MainCompaniesEditBox";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function MainCompanies({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [supplierStatus, setSupplierStatus] = useState("Tümü");

  const [searchText, setSearchText] = useState("");

  const [mainCompaniesPage, setMainCompaniesPage] = useState(1);
  const [mainCompaniesRowsPerPage, setMainCompaniesRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setMainCompaniesUseFetchRender,
    useFetchRender: mainCompaniesUseFetchRender,
    error: mainCompaniesError,
    loading: mainCompaniesLoading,
    data: mainCompanies,
    total: mainCompaniesTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_MY_MAIN_COMPANY_LIST_URL}?id=${loginUserCompanyId}&page=${mainCompaniesPage}&pageSize=${mainCompaniesRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: "",
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "companyCategory",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "distance",
      header: "Mesafe",
    },
    {
      key: "addresses",
      header: t("table_address"),
    },
  ];

  useEffect(() => {
    setMainCompaniesUseFetchRender(!mainCompaniesUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }
 
  return (
    <div className="maincompanies-container">
      <div className="maincompanies-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        <MainCompaniesEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          useFetchRender={mainCompaniesUseFetchRender}
          setUseFetchRender={setMainCompaniesUseFetchRender}
          selectValueId={selectValueId}
          setSelectValueId={setSelectValueId}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
        />
      </div>

      <div className="maincompanies-page-link">
        <div className="opportunities-button-groups">
          <ButtonText
            icon={<i className="fa-solid fa-table-cells-large"></i>}
            text={"Tümü"}
            onClick={() => setSupplierStatus("Tümü")}
            className={supplierStatus === "Tümü" ? "button-active" : ""}
          />

          {/* <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={"Fırsat Sunanlar"}
            onClick={() => setSupplierStatus("Fırsat Sunanlar")}
            className={
              supplierStatus === "Fırsat Sunanlar" ? "button-active" : ""
            }
          />

          <ButtonText
            icon={<i className="fa-solid fa-clock"></i>}
            text={"Fırsat Sunmayanlar"}
            onClick={() => setSupplierStatus("Fırsat Sunmayanlar")}
            className={
              supplierStatus === "Fırsat Sunmayanlar" ? "button-active" : ""
            }
          /> */}
        </div>
      </div>

      <div className="maincompanies-table">
        {mainCompaniesError ? (
          <ErrorComponent error={mainCompaniesError} />
        ) : mainCompaniesLoading ? (
          <Loading />
        ) : mainCompanies !== null ? (
          <StandartTable
            type="company"
            searchText={searchText}
            setSearchText={setSearchText}
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={mainCompaniesUseFetchRender}
            setUseFetchRender={setMainCompaniesUseFetchRender}
            total={mainCompaniesTotal}
            page={mainCompaniesPage}
            setPage={setMainCompaniesPage}
            rowsPerPage={mainCompaniesRowsPerPage}
            setRowsPerPage={setMainCompaniesRowsPerPage}
            columns={columns}
            data={mainCompanies}
          />
        ) : (
          <div className="table-null-text">Sistemde kayıtlı ana firma yok!</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(MainCompanies);
