import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

let token;
let decodedToken;
let userRole;
let loginUserId;
let userEmail;
let userName;
let loginUserCompanyId;

function AuthContextProvider({ children }) {
  const navigate = useNavigate();

  const [companyUpdateRender, setCompanyUpdateRender] = useState(false);
  const [userInfoRender, setUserInfoRender] = useState(false);

  const [loginUserInfo, setloginUserInfo] = useState();

  const [loginUserToken, setLoginUserToken] = useState(
    JSON.parse(localStorage.getItem("token"))
  );
  const [errorMessage, setErrorMessage] = useState();
  const [authLoading, setAuthLoading] = useState(false);

  if (loginUserToken) {
    token = loginUserToken;

    decodedToken = jwt_decode(loginUserToken);

    userRole =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];

    loginUserId =
      decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];

    userEmail = decodedToken.email;

    userName = decodedToken.name;

    loginUserCompanyId =
      decodedToken[
        "http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor"
      ];
  }

  async function authLogin(url, data) {
    try {
      setAuthLoading(true);

      const getData = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      setLoginUserToken(getData.data.data);

      if (getData.data.isError === false) {
        localStorage.setItem("token", JSON.stringify(getData.data.data));

        const token = getData.data.data;
        const tkn = jwt_decode(token);
        const role =
          tkn["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const companyId =
          tkn["http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor"];

        /* Kullanıcının rolu "Manager" ise kişinin firma idsine göre firma bilgileri apiden istenir. */
        /* İstenen bilgiler yoksa sisteme eklenmiş yeni firma olduğu anlaşılır ve firma bilgileri güncelleme sayfasına yönlendirilir. */
        if ( role && role === "Manager") {
          console.log("role : ", role);
          await axios
            .get(
              `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${companyId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((response2) => {
              console.log(
                "firm bilgisi",
                response2.data.data[0]
              );

              if (response2.data.data[0].logo === null) {
                localStorage.setItem("isFirstTimeUser", true);
                localStorage.setItem("isFirstTimeOfferex", true);
                navigate("/company-profile-edit");
              } else {
                navigate("/");
              }
            }).catch(err=>{navigate("/")})

        } else {
          navigate("/");
        }
      } else {
        console.log(getData.data.errorMessageList[0]);
        setErrorMessage(getData.data.errorMessageList[0]);
      }
    } catch (err) {
      console.log("ERROROROOROR", err);
      setErrorMessage(err);
      navigate("/error", { state: { message: err.message, from: "/" } });
    }

    setAuthLoading(false);
  }

  useEffect(() => {
    async function getUserInformation() {
      try {
        const [response, responseImage] = await Promise.all([
          axios.get(
            "http://sosocrm.oetech.online/api/User/company/get-user-information",
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          axios.get(
            `http://sosocrm.oetech.online/api/Company/admin/get-users-by-companyId?UserId=${loginUserId}&Page=1&PageSize=1`,
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        if (responseImage && response) {
          const imageData = responseImage.data.data;
          const userData = response.data.data;
          if (imageData && imageData.length > 0) {
            const image =
              imageData[0].image !== null
                ? imageData[0].image
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
            setloginUserInfo({ image, ...userData[0] });
          } else if (userData && userData.length > 0) {
            setloginUserInfo({ ...userData[0] });
          }
        }
      } catch (error) {
        console.log("status : ", error);
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          // navigate("/error", { state: { message: error.message } });
        }
      }
    }

    if (loginUserToken) {
      getUserInformation();
    }
  }, [loginUserToken, companyUpdateRender, userInfoRender]);

  const values = {
    companyUpdateRender,
    setCompanyUpdateRender,

    userInfoRender,
    setUserInfoRender,

    authLogin,
    loginUserToken,
    token,
    decodedToken,
    loginUserId,
    loginUserCompanyId,
    userName,
    userRole,
    userEmail,
    loginUserInfo,

    errorMessage,
    setErrorMessage,
    authLoading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export const AuthContextValue = () => useContext(AuthContext);

export default AuthContextProvider;
