import React, { useState } from "react";
import "./ratingComponent.css"; // Stil dosyanızı ekleyin
import { Rate } from "antd";

const RatingComponent = ({ handleRating, loading }) => {
  const [reactionScore, setReactionScore] = useState(0);
  const [achievementScore, setAchievementScore] = useState(0);
  const [deliveryScore, setDeliveryScore] = useState(0);

  const handleRate = async () => {
    const ratingData = {
      reactionScore: reactionScore,
      achievementScore: achievementScore,
      deliveryScore: deliveryScore,
    };

    await handleRating(ratingData);
  };

  return (
    <div className="rating-container">
      <div className="rating-main">
        <h3>Firmaya Puan Ver</h3>
        <div className="rating-item">
          <label>Reaksiyon Skoru:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={reactionScore}
            onChange={(value) => setReactionScore(value)}
          />
        </div>
        <div className="rating-item">
          <label>Başarı Skoru:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={achievementScore}
            onChange={(value) => setAchievementScore(value)}
          />
        </div>
        <div className="rating-item">
          <label>Teslimat Skoru:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={deliveryScore}
            onChange={(value) => setDeliveryScore(value)}
          />
        </div>
        <button onClick={handleRate} disabled={loading}>
          {loading ? "Yükleniyor" : "Oyla"}
        </button>
      </div>
    </div>
  );
};

export default RatingComponent;
