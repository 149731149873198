import "./userTicketBox.css";
import React, { useEffect, useState } from "react";

import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";
import { Button } from "antd";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { GetContextValue } from "../../../context/GetContext";

function UserTicketBox({ supportNumber }) {
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [ticket, setTicket] = useState({});
  const [ticketStatus, setTicketStatus] = useState(1);
  const [ticketColor, setTicketColor] = useState("#fff1b8");

  const [photo, setPhoto] = useState(null);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  const handleOpenPhotoModal = (photo) => {
    setPhoto(photo);
    setPhotoModalOpen(true);
  };
  const handleClosePhotoModal = () => {
    setPhotoModalOpen(false);
  };

  const {
    setUseFetchRender: setTicketUseFetchRender,
    useFetchRender: ticketUseFetchRender,
    error: ticketError,
    loading: ticketLoading,
    data: ticketData,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_SUPPORT_LIST_URL
    }?SupportNumber=${supportNumber}&Page=${1}&PageSize=${1}`,
    token
  );

  
  useEffect(() => {
    if (ticketData && ticketData[0]) {
      setTicket(ticketData[0]);
      setTicketStatus(
        ticket.status === 1
          ? "Bekliyor"
          : ticket.status === 2
          ? "İnceleniyor"
          : ticket.status === 3
          ? "Çözüldü"
          : ticket.status === 4
          ? "Çözülemedi"
          : ticket.status === 5
          ? "Sonuçlandı"
          : "Bekliyor"
      );
      setTicketColor(
        ticketData[0].status === 1
          ? "#fff1b8"
          : ticketData[0].status === 2
          ? "#ffd8bf"
          : ticketData[0].status === 3
          ? "#d9f7be"
          : ticketData[0].status === 4
          ? "#ffccc7"
          : ticketData[0].status === 5
          ? "#b5f5ec"
          : "#fff1b8"
      );
    }
  }, [ticketData]);

  useEffect(() => {
    setTicketUseFetchRender(!ticketUseFetchRender);
  }, [notificationRender, supportNumber]);

  return (
    <>
      {ticketError ? (
        <ErrorComponent error={ticketError} />
      ) : ticketLoading ? (
        <Loading />
      ) : ticket && ticket.supportNumber ? (
        <div
          className="user-ticket-box"
          style={{ backgroundColor: ticketColor }}
        >
          <h3>
            {" "}
            <strong>Talep Başlık :</strong> {ticket.title}
            <span className="date">{ticket.createdDate}</span>
          </h3>

          <hr />

          <p>
            {" "}
            <strong>Talep No :</strong> {ticket.supportNumber}
          </p>
          <p>
            {" "}
            <strong>Talep Açıklama :</strong> {ticket.body}
          </p>

          <p>
            <strong>Talep Fotoğraf :</strong>{" "}
            <Button onClick={() => handleOpenPhotoModal(ticket.images[0])}>
              Fotoğrafı göster
            </Button>
          </p>

          <p>
            <strong>Durum : </strong> {ticketStatus}
          </p>

          {ticket.description && (
            <div className="admin-reply">
              <span className="date">{ticket.approvedDate}</span>
              <h4>Admin Cevabı:</h4>
              <p>{ticket.description}</p>
            </div>
          )}
        </div>
      ) : (
        <p className="table-null-text">Destek talep mesajı yok</p>
      )}
      {photoModalOpen && (
        <div className="photo-modal-container" onClick={handleClosePhotoModal}>
          <div className="photo-box">
            <button className="close-button" onClick={handleClosePhotoModal}>
              x
            </button>
            <img className="ticket-image" src={photo} alt="Ticket" />
          </div>
        </div>
      )}
    </>
  );
}

export default UserTicketBox;
