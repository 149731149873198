import "./styles/mediumTableDetailCard/mediumTableDetailCard.css";

import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import MediumTable from "../../components/Table/MediumTable/MediumTable";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function AllOfferCard() {
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  /* Tablodan seçilen değerleri tutan stateler */
  const [selectValue, setSelectValue] = useState(null);
  const [selectValueId, setSelectValueId] = useState(null);

  const {
    setUseFetchRender: setOffersUseFetchRender,
    useFetchRender: offersUseFetchRender,
    error: offersError,
    loading: offersLoading,
    data: offers,
    total: offersTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?Last=false&SortType=${3}&Page=${1}&PageSize=${5}`,
    token
  );


  const columns = [
    {
      key: "offerCompanyName",
      header: "Firma Adı",
    },
    {
      key: "productName",
      header: "Ürün/Hizmet",
    },
    {
      key: "createdUser",
      header: "Oluşturan",
    },
    {
      key: "remainingTime",
      header: "Kalan Süre",
    },
    {
      key: "totalPrice",
      header: "Toplam",
    },
  ];

  useEffect(() => {
    setOffersUseFetchRender(!offersUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    setOffersUseFetchRender(!offersUseFetchRender);
  }, []);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <div className="medium-table-container">
      <div className="table-top">
        <div className="title-box">
          <i className="fa-solid fa-bahai"></i>
          <h5>Teklifler</h5>
        </div>
        <div className="buttons">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            tooltip={"Yenile"}
            onClick={() => {
              setOffersUseFetchRender(!offersUseFetchRender);
              setSelectValue(null);
              setSelectValueId(null);
            }}
          />
          <Link
            to={
              selectValue || selectValueId
                ? `/opportunities/tender-detail-supplier/${selectValue?.tenderId}`
                : false
            }
          >
            <WhiteButton
              disabled={selectValue || selectValueId ? false : true}
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>

      <div className="table-main">
        {offersError ? (
          <ErrorComponent error={offersError} />
        ) : offersLoading ? (
          <Loading />
        ) : offers !== null && offers.length > 0 ? (
          <MediumTable
            type="offer"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            columns={columns}
            data={offers}
          />
        ) : (
          <p className="table-null-text">Gönderilmiş bir teklif yok!</p>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(AllOfferCard);
