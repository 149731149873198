import "./bannerEditForm.css";
import { useEffect, useState } from "react";

// Function to format date in "yyyy-MM-dd" format
const formatDateToISODate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const BannerEditForm = ({ banner, onSubmit, onCancel }) => {
  const [bannerImage, setBannerImage] = useState("");
  const [editedBanner, setEditedBanner] = useState({
    id: banner.advertId,
    index: banner.index,
    companyName: banner.companyName,
    displayTime: banner.displayTime,
    startDate: banner.startDate,
    finishDate: banner.finishDate,
    images: [],
  });

  useEffect(() => {
    setEditedBanner({
      id: banner.advertId,
      index: banner.index,
      companyName: banner.companyName,
      displayTime: banner.displayTime,
      startDate: banner.startDate,
      finishDate: banner.finishDate,
      images: [],
    });
    setBannerImage(banner.image);
  }, [banner]);

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input field is for startDate or finishDate
    // and format the date value accordingly
    const formattedValue =
      name === "startDate" || name === "finishDate"
        ? formatDateToISODate(value)
        : value;

    setEditedBanner((prevBanner) => ({
      ...prevBanner,
      [name]: formattedValue,
    }));
  };

  // Function to handle image input changes
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    setBannerImage(URL.createObjectURL(imageFile));

    const reader = new FileReader();

    reader.onloadend = () => {
      setEditedBanner((prevBanner) => ({
        ...prevBanner,
        images: [reader.result.split(",")[1]], // Set the base64 encoded image data as the new image value
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the onSubmit function passed as a prop with the editedBanner data
    onSubmit(editedBanner);
  };

  return (
    <form onSubmit={handleSubmit} className="banner-edit-form-container">
      <div className="form-group">
        <label>Fotoğraf:</label>
        <div className="image-box">
          <img src={bannerImage} alt="videoImage" />
        </div>

        <label htmlFor="image" className="change-image-label">
          Fotoğrafı Değiştir
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
        />
      </div>
      <div className="form-group">
        <label htmlFor="companyName">Firma Adı:</label>
        <h3>{editedBanner.companyName}</h3>
      </div>
      <div className="form-group">
        <label htmlFor="index">Sırası:</label>
        <input
          type="number"
          id="index"
          name="index"
          value={editedBanner.index}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="displayTime">Süresi (Saniye):</label>
        <input
          type="text"
          id="displayTime"
          name="displayTime"
          value={editedBanner.displayTime}
          onChange={handleChange}
        />
        <p className="note">Lütfen süreyi saniye cinsinden giriniz.</p>
      </div>
      <div className="date-container">
        <div className="form-group">
          <label htmlFor="startDate">Başlangıç Tarihi:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formatDateToISODate(editedBanner.startDate)}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="finishDate">Bitiş Tarihi:</label>
          <input
            type="date"
            id="finishDate"
            name="finishDate"
            value={formatDateToISODate(editedBanner.finishDate)}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-buttons">
        <button type="submit">Kaydet</button>
        <button
          type="button"
          onClick={() => {
            onCancel();
            setBannerImage("");
          }}
        >
          İptal
        </button>
      </div>
    </form>
  );
};

export default BannerEditForm;
