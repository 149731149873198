import "./styles/tenderChange.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { CrudContextValue } from "../../../context/CrudContext";
import { AuthContextValue } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import TenderChangeStepOne from "./TenderChangeStepOne";
import TenderChangeStepTwo from "./TenderChangeStepTwo";

function TenderChange() {
  const { tenderNumber } = useParams();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { token } = AuthContextValue();
  const { createMethod } = CrudContextValue();

  const [changeLoading, setChangeLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ tenderNumber: tenderNumber });

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handlePrev = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    setFormData({ ...formData, ...data });

    console.log({ ...formData, ...data });

    const newTender = { ...formData, ...data, tenderNumber: tenderNumber };

    setChangeLoading(true);

    const response = await createMethod(
      process.env.REACT_APP_POST_REVISED_TENDER_URL,
      JSON.stringify(newTender),
      token
    );

    if (response) {
      toast.info("İhale sayfasına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/tenders");
      }, 3000);
    }

    setChangeLoading(false);

    setStep(1);
    setFormData({});
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tender-change">
      {windowWidth < 1028 ? (
        <>
          {step === 1 ? (
            <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
              <TenderChangeStepOne
                onNext={handleNext}
                formData={formData}
                step={step}
              />
            </div>
          ) : step === 2 ? (
            <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
              <TenderChangeStepTwo
                onPrev={handlePrev}
                onSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
                step={step}
                setStep={setStep}
                changeLoading={changeLoading}
              />
            </div>
          ) : (
            "null"
          )}
        </>
      ) : (
        <>
          <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
            <TenderChangeStepOne
              onNext={handleNext}
              formData={formData}
              step={step}
            />
          </div>
          <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
            <TenderChangeStepTwo
              onPrev={handlePrev}
              onSubmit={handleSubmit}
              formData={formData}
              setFormData={setFormData}
              step={step}
              setStep={setStep}
              changeLoading={changeLoading}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default TenderChange;
