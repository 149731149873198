import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import "./documanUploader.css";

import { useEffect, useState } from "react";

function DocumentUploader({
  documents,
  setDocuments,
  formData,
  setFormData,
}) {
  const { token } = AuthContextValue();
  const { deleteMethod } = CrudContextValue();

  function generateId() {
    const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
    return id.toString(); // sayıyı string'e dönüştür ve geri döndür
  }

  function handleFileUpload(event) {
    const fileList = event.target.files;
    const documentsArray = Array.from(fileList).map((file) => file.name);
    // setDocuments(documents.concat(documentsArray));

    for (const file of fileList) {
      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      reader.onload = (e) => {
        setDocuments([
          ...documents,
          {
            fileName: documentsArray,
            base64: e.target.result.split("base64,")[1],
          },
        ]);

        if (documents.map((doc) => doc.base64).includes(e.target.result)) {
          alert("Bu resim zaten var!");
        } else {
          setDocuments(
            documents.length > 0
              ? [...documents, { id: generateId(), base64: e.target.result }]
              : [{ id: generateId(), base64: e.target.result }]
          );

          setFormData({
            ...formData,
            images:
              formData.images.length < 1
                ? [e.target.result.split("base64,")[1]]
                : [
                    ...formData.images,
                    e.target.result.split("base64,")[1],
                  ],
          });
        }
      };
    }
  }

  // useEffect(() => {
  //     setFormData({
  //         ...formData,
  //         images: [...formData.images, ...documents.map(doc => doc.base64)]
  //     });
  //     console.log(documents);
  // }, [documents]);

  async function handleDocumentDelete(document) {
    const updatedDocuments = documents.filter((doc) => doc.id !== document.id);

    if (document.fileName && document.fileName.includes("/api/Images")) {
      setDocuments(updatedDocuments);

      const documentPath = document.fileName.split("images/")[1];
      console.log(documentPath);
      
      await deleteMethod(
        `http://sosocrm.oetech.online/api/Images/Delete-Images?file=${documentPath}`,
        token
      );
    } else {
      setDocuments(updatedDocuments);

      const newTenderDocument = formData.images.filter(
        (prevdocument) => prevdocument !== document.base64.split("base64,")[1]
      );

      setFormData({ ...formData, images: newTenderDocument });
    }
  }

  function shortenDocumentName(name) {
    if (name && name.length > 5) {
      let newName = name.includes("images/")
        ? name.split("images/")[1]
        : name.includes("base64")
        ? name.split("base64,")[1]
        : name;
      return newName.slice(0, 15) + "...";
    }
    return name;
  }

  /* BURDA KALDIM */
  return (
    <div className="documan-container">
      <input
        type="file"
        id="documanInput"
        multiple
        onChange={handleFileUpload}
      />
      <button onClick={() => document.getElementById("documanInput").click()}>
        Yükle
      </button>
      <div className="documan-detail-box">
        {documents &&
          documents.map((document, i) => (
            <div key={i} className="documan-detail">
              <a href={document.fileName} target="_blank">
                {document.fileName
                  ? shortenDocumentName(document.fileName)
                  : document.base64
                  ? shortenDocumentName(document.base64)
                  : ""}
              </a>

              <button onClick={() => handleDocumentDelete(document)}>x</button>
            </div>
          ))}
      </div>
    </div>
  );
}

export default DocumentUploader;
