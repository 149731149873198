import "./tenderDetailCard.css";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DetailText from "../DetailText/DetailText";
import ImageDetailCard from "../ImageDetailCard/ImageDetailCard";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { AuthContextValue } from "../../context/AuthContext";
import RevisedModal from "../Modals/RevisedModal/RevisedModal";
import { Alert, Space, Tooltip } from "antd";
import { tenderTimeOverCalcFunc } from "../../hooks/tenderTimeOver";

function TenderDetailCard({
  tenderDetail,
  setCardRender,
  cardRender,
  manager,
  opportunitiesConfirmStatus,
  opportunitiesRevisedResult,
  opportunitiesConfirmFunc,
  opportunitiesConfirmLoading,
  opportunitiesRevisedFunc,
  opportunitiesRevisedLoading,
}) {
  const navigate = useNavigate();
  const { loginUserCompanyId } = AuthContextValue();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [formattedTime, setFormattedTime] = useState("");
  const [price, setPrice] = useState("");

  const [tenderImages, setTenderImages] = useState([]);
  const [tenderDocuments, setTenderDocuments] = useState([]);

  useEffect(() => {
    if (tenderDetail && tenderDetail.expireDate) {
      const timeDiff = new Date(tenderDetail.expireDate) - new Date(); // farkı al

      if (timeDiff < 0) {
        setFormattedTime("Bitti");
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // gün hesabı
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24); // saat hesabı
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60); // dakika hesabı

        const formattedTime = `${days} Gün, ${hours} Saat, ${minutes} Dakika`; // formatlı hali

        setFormattedTime(formattedTime); // örnek çıktı: "4 Gün, 12 Saat, 23 Dakika"
      }
    }

    if (tenderDetail && tenderDetail.imagePaths) {
      let filterDocuments = [];
      let filterImages = [];

      tenderDetail.imagePaths.map((path) => {
        if (path.includes("pdf")) {
          filterDocuments.push(path);
        } else {
          filterImages.push(path);
        }
      });

      setTenderDocuments(filterDocuments);
      setTenderImages(filterImages);

      if (tenderDetail.expireDate) {
        // console.log(tenderDetail?.expireDate);
        if (tenderDetail.currency && tenderDetail.currency !== "string") {
          setPrice(
            Number(tenderDetail.minPrice).toLocaleString(
              tenderDetail.currency,
              {
                style: "currency",
                currency: tenderDetail.currency,
              }
            ) +
              " - " +
              Number(tenderDetail.maxPrice).toLocaleString(
                tenderDetail.currency,
                {
                  style: "currency",
                  currency: tenderDetail.currency,
                }
              )
          );
        } else {
          setPrice(
            Number(tenderDetail.minPrice).toLocaleString() +
              " - " +
              Number(tenderDetail.maxPrice).toLocaleString()
          );
        }
      }
    }
  }, [tenderDetail]);

  function formatDuration(duration) {
    const days = Math.ceil(duration / (24 * 60 * 60 * 1000));

    return `${days} gün`;
  }

  async function handleApprovedOpportunities() {
    await opportunitiesConfirmFunc();

    handleCloseConfirmModal();
  }

  async function handleRevisedOpportunities(revisedDescription) {
    console.log("denemeee : ", revisedDescription);
    await opportunitiesRevisedFunc(revisedDescription);
    await setCardRender(!cardRender);
    handleCloseRevisedModal();
  }

  return (
    <div className="tender-detail-box">
      <div className="tender-detail-top">
        {opportunitiesConfirmLoading || opportunitiesRevisedLoading ? (
          <LoadingCircular />
        ) : revisedModalOpen ? (
          <RevisedModal
            cancelText={"Vazgeç"}
            confirmText={"Revize Et"}
            confirmFunc={handleRevisedOpportunities}
            handleCloseModal={handleCloseRevisedModal}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Katıl"}
            text={"İhaleye katılmak istediğinize emin misiniz?"}
            confirmFunc={handleApprovedOpportunities}
            handleCloseModal={handleCloseConfirmModal}
          />
        ) : (
          <>
            <div className="tender-detail-top-left">
              <WhiteButton
                icon={<i className="fa-solid fa-chevron-left"></i>}
                text={"Geri"}
                onClick={() => navigate(-1)}
              />
              <h4>{tenderDetail.productName}</h4>
            </div>
            <div className="tender-detail-top-right">
              {opportunitiesConfirmStatus === "1" ||
              opportunitiesConfirmStatus === "3" ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-arrows-rotate"></i>}
                    tooltip={"İhaleye Revize İste"}
                    onClick={handleOpenRevisedModal}
                    // className="tender-detail-top-edit-button"
                  />
                  <WhiteButton
                    tooltip={"İhaleye Katıl"}
                    icon={<i className="fa-solid fa-check"></i>}
                    className="tender-detail-top-edit-button"
                    onClick={handleOpenConfirmModal}
                  />
                </>
              ) : Number(tenderDetail?.companyId) ===
                Number(loginUserCompanyId) ? (
                <Link
                  to={`/tenders/tender-change/${tenderDetail.tenderNumber}`}
                >
                  <WhiteButton
                    icon={
                      <i className="fa-solid fa-arrow-right-arrow-left"></i>
                    }
                    tooltip={"İhaleyi Değiştir"}
                  />
                </Link>
              ) : null}
            </div>
          </>
        )}
      </div>
      <div className="tender-detail-main">
        {opportunitiesRevisedResult === "2" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message="İhale sahibi ihaleyi revize etme talebinizi onayladı :)"
              type="success"
            />
          </Space>
        ) : opportunitiesRevisedResult === "1" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message="İhale sahibinden istediğiniz revize talebinizin cevabı bekleniyor."
              type="warning"
            />
          </Space>
        ) : opportunitiesRevisedResult === "4" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message="İhale sahibi tarafından ihaleyi revize etme talebiniz  reddedildi :("
              type="error"
            />
          </Space>
        ) : null}

        {Number(loginUserCompanyId) !== Number(tenderDetail?.companyId) &&
        tenderTimeOverCalcFunc(
          new Date(tenderDetail?.expireDate) - new Date()
        ) === "overTime" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message="İhale süresi doldu teklif gönderemezsiniz"
              type="error"
              showIcon={true}
            />
          </Space>
        ) : null}
        <div className="tender-detail-image">
          <ImageDetailCard images={tenderImages} />
        </div>

        <div className="tender-detail-info">
          <div className="tender-detail-box-documan-container">
            <p className="detail-text-title">Dökümanlar:</p>
            <div className="documan-box">
              {tenderDocuments && tenderDocuments.length >= 1
                ? tenderDocuments.map((doc, i) => {
                    return (
                      <a
                        key={i}
                        target="_blank"
                        href={doc}
                        className="detail-text"
                      >
                        {doc.split("images/")[1]}
                      </a>
                    );
                  })
                : "-"}
            </div>
          </div>

          <DetailText
            title={"İhale No:"}
            text={
              <Tooltip placement="top" title="İhale Detayı">
                <Link
                  style={{ fontSize: ".8rem", color: "rgb(19, 148, 223)" }}
                  to={
                    Number(tenderDetail?.companyId) ===
                    Number(loginUserCompanyId)
                      ? `/tenders/tender-detail/${tenderDetail.tenderId}`
                      : `/opportunities/tender-detail-supplier/${tenderDetail.tenderId}`
                  }
                >
                  {tenderDetail.tenderNumber}
                </Link>
              </Tooltip>
            }
          />
          <DetailText title={"Oluşturan:"} text={tenderDetail.createdUser} />
          <DetailText title={"Onay Veren:"} text={tenderDetail.approvedUser} />
          <DetailText
            title={"Başlangıç Tarihi:"}
            text={new Date(tenderDetail.createdAt).toLocaleString()}
          />
          <DetailText
            title={"Bitiş Tarihi:"}
            text={new Date(tenderDetail.expireDate).toLocaleString()}
          />
          <DetailText
            title={"İhale Süresi:"}
            text={formatDuration(
              new Date(tenderDetail.expireDate) -
                new Date(tenderDetail.createdAt)
            )}
          />
          <DetailText title={"Kalan Süre:"} text={formattedTime} />
          <DetailText
            title={"Kategori:"}
            text={tenderDetail?.categories
              ?.map((category) => category.categoryName)
              .join(", ")}
          />
          {/* <DetailText title={"Max Bütçe:"} text={tenderDetail.maxPrice} />
          <DetailText title={"Min Bütçe:"} text={tenderDetail.minPrice} /> */}
          <DetailText title={"Bütçe:"} text={price} />
          <DetailText
            title={"Miktar:"}
            text={`${tenderDetail.amount} ${
              tenderDetail.unitType === 1
                ? "Adet"
                : tenderDetail.unitType === 2
                ? "Ton"
                : tenderDetail.unitType === 3
                ? "Kilo"
                : tenderDetail.unitType === 4
                ? "Gram"
                : tenderDetail.unitType === 5
                ? "Metre"
                : tenderDetail.unitType === 6
                ? "Santimetre"
                : tenderDetail.unitType === 6
                ? "Milimetre"
                : tenderDetail.unitType === 6
                ? "Litre"
                : tenderDetail.unitType === 6
                ? "Inc"
                : ""
            }`}
          />
          <DetailText
            title={"Termin:"}
            text={new Date(tenderDetail.deadline).toLocaleString()}
          />
          <DetailText title={"Açıklama:"} text={tenderDetail.description} />
          <DetailText title={"*Not:"} text={tenderDetail.note} />
        </div>
      </div>
    </div>
  );
}

export default TenderDetailCard;
