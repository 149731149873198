import "./helpButton.css";

import React, { useState } from "react";
import { FloatButton, Modal } from "antd";
import { useLocation } from "react-router-dom";
import HowItWork from "../../pages/HowItWork/HowItWork";
import { CommentOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import SupportRequestModal from "../Modals/SupportRequestModal/SupportRequestModal";
import SupportRequestLayout from "../SupportRequestBox/SupportRequestLayout";

const HelpButton = () => {
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];

  const [howItWorkModalOpen, setHowItWorkModalOpen] = useState(false);
  const handleOpenHowItWork = () => {
    setHowItWorkModalOpen(true);
  };
  const handleCloseHowItWork = () => {
    setHowItWorkModalOpen(false);
  };

  const [supportRequestOpen, setSupportRequestOpen] = useState(false);
  const handleOpenSupportRequest = () => {
    setSupportRequestOpen(true);
  };
  const handleCloseSupportRequest = () => {
    setSupportRequestOpen(false);
  };

  return (
    <>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{
          right: 10,
          bottom: 10,
        }}
        icon={<QuestionCircleOutlined style={{ fontSize: "20px" }} />}
      >
        <FloatButton
          onClick={handleOpenHowItWork}
          tooltip={<div>Nasıl Çalışır ?</div>}
        />
        <FloatButton
          onClick={handleOpenSupportRequest}
          icon={<CommentOutlined />}
          tooltip={<div>Destek Talep</div>}
        />
      </FloatButton.Group>

      <Modal
        title={""}
        open={howItWorkModalOpen}
        onCancel={handleCloseHowItWork}
        footer={null}
        width={"80%"}
      >
        {/* //Modal içeriği burada olacak */}

        {/* //Her sayfaya özel içeriği buraya yerleştirin */}
        <HowItWork />
      </Modal>

      <Modal
        title={
          <h3>
            <i className="fa-solid fa-circle-question" /> Destek Talep
          </h3>
        }
        open={supportRequestOpen}
        onCancel={handleCloseSupportRequest}
        footer={null}
        width={"80%"}
        // bodyStyle={{ height: "70vh", overflow: "hidden" }}
        style={{ height: "95vh", overflow: "hidden", top: "2rem" }}
        className="support-modal-container"
      >
        {/* //Modal içeriği burada olacak */}
        {/* //Destek Talep içeriği burada olacak */}
        {/* <SupportRequestModal handleCloseModal={handleCloseSupportRequest} /> */}
        <SupportRequestLayout
          handleCloseModal={handleCloseSupportRequest}
          supportRequestOpen={supportRequestOpen}
        />
      </Modal>
    </>
  );
};

export default HelpButton;
{
  /* <div
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        zIndex: "9999",
      }}
    >
      <Button
        type="primary"
        shape="circle"
        icon={<i className="fa-solid fa-question" />}
        onClick={openModal}
      />
      
      <Modal
        title={""}
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={"80%"}
      >
        //Modal içeriği burada olacak
        <HowItWork />
        //Her sayfaya özel içeriği buraya yerleştirin
      </Modal>
    </div> 
  */
}
