import "../style/detailCardStyle/detailCardStyle.css";

import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../../context/GetContext";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";

function formatDate(createdAt) {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);

  // Bugünün tarihini alır
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Oluşturulan tarihini alır
  const createdDay = createdDate.getDate();
  const createdMonth = createdDate.getMonth();
  const createdYear = createdDate.getFullYear();

  // Bugünün tarihini kontrol eder
  if (
    currentDay === createdDay &&
    currentMonth === createdMonth &&
    currentYear === createdYear
  ) {
    return "bugün";
  }

  // Dünün tarihini kontrol eder
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDay - 1);

  if (
    createdDay === yesterday.getDate() &&
    createdMonth === yesterday.getMonth() &&
    createdYear === yesterday.getFullYear()
  ) {
    return "dün";
  }

  // Tarihi formatlar
  const options = { year: "numeric", month: "long", day: "numeric" };
  return createdDate.toLocaleDateString("tr-TR", options);
}

function SupportRequestCard() {
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const {
    setUseFetchRender: setSupportRequestUseFetchRender,
    useFetchRender: supportRequestUseFetchRender,
    error: supportRequestError,
    loading: supportRequestLoading,
    data: supportRequest,
    total: supportRequestTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_SUPPORT_LIST_URL}?Page=${1}&PageSize=${5}`,
    token
  );

  useEffect(() => {
    setSupportRequestUseFetchRender(!supportRequestUseFetchRender);
  }, [notificationRender]);

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-bell"></i>
          <h5>Destek Talep Mesajları</h5>
        </div>
        <div className="card-buttons">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            tooltip={"Yenile"}
            onClick={() =>
              setSupportRequestUseFetchRender(!supportRequestUseFetchRender)
            }
          />
          <Link to={"/support-request"}>
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {supportRequestError ? (
            <ErrorComponent error={supportRequestError} />
          ) : supportRequestLoading ? (
            <Loading />
          ) : supportRequest !== null && supportRequest.length > 0 ? (
            supportRequest.map((ticket) => {
              return (
                <li key={ticket.supportNumber}>
                  <p>{ticket.title}</p>
                  <p>{formatDate(ticket.createdDate)}</p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">Destek talep mesajı yok</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default SupportRequestCard;
