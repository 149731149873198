import "./tutorialModal.css";
import React, { useEffect } from "react";
import { Modal, Space } from "antd";

function TutorialModal() {
  useEffect(() => {
    const isFirstTimeUser = localStorage.getItem("isFirstTimeUser");

    if (isFirstTimeUser) {
      showTutorialModal();
      localStorage.removeItem("isFirstTimeUser");
    }
  }, []);

  const showTutorialModal = () => {
    Modal.info({
      width: "60%",
      title: "Bilgilendirme",
      content: (
        <div className="tutorial-modal-container">
          <h1 className="title">Hoş Geldiniz!</h1>
          <p>
            Firma bilgilerinizi eksiksiz bir şekilde girmeniz gerekmektedir. Bu
            bilgileri doldurduktan sonra ihale oluşturabilir, teklif verebilir
            ve kullanıcı ekleyebilirsiniz. İhale portalımız size kolay kullanım
            sunan ve anlaşılır bir web sitesi olarak tasarlanmıştır.
          </p>
          <p>
            Firma bilgilerini doldurmanın nasıl yapıldığını öğrenmek için sağ
            alt köşedeki <span className="ask-icon">"?"</span> işaretli butona
            tıklayarak görsel rehberi görüntüleyebilirsiniz. Bu rehber, adım
            adım firma bilgilerinizi nasıl gireceğinizi anlatan kısa videolar
            içermektedir. Ayrıca, sayfalara özel yardım videolarını bu butona
            tıklayarak görüntüleyebilir veya <span className="howtowork-text">"? Nasıl Çalışır"</span> sayfasına giderek
            bütün içeriklere erişebilirsiniz.
          </p>
        </div>
      ),
      okText: "Tamam",
      onOk() {},
    });
  };

  return <Space></Space>;
}

export default TutorialModal;
