import "../style/detailCardStyle/detailCardStyle.css";

import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../../context/GetContext";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";

function formatDate(createdAt) {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);

  // Bugünün tarihini alır
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Oluşturulan tarihini alır
  const createdDay = createdDate.getDate();
  const createdMonth = createdDate.getMonth();
  const createdYear = createdDate.getFullYear();

  // Bugünün tarihini kontrol eder
  if (
    currentDay === createdDay &&
    currentMonth === createdMonth &&
    currentYear === createdYear
  ) {
    return "bugün";
  }

  // Dünün tarihini kontrol eder
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDay - 1);

  if (
    createdDay === yesterday.getDate() &&
    createdMonth === yesterday.getMonth() &&
    createdYear === yesterday.getFullYear()
  ) {
    return "dün";
  }

  // Tarihi formatlar
  const options = { year: "numeric", month: "long", day: "numeric" };
  return createdDate.toLocaleDateString("tr-TR", options);
}

function NotificationDetailCard() {
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [activeNotification, setActiveNotification] = useState([]);

  const {
    setUseFetchRender: setNotificationsUseFetchRender,
    useFetchRender: notificationsUseFetchRender,
    error: notificationsError,
    loading: notificationsLoading,
    data: notifications,
    total: notificationsTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_NOTIFICATION_URL}?Page=${1}&PageSize=${20}`,
    token
  );

  useEffect(() => {
    setNotificationsUseFetchRender(!notificationsUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    if (notifications !== null && notifications.length > 0) {
      const filterNotifications = notifications
        .filter((notification) => notification.isRead !== true)
        .slice(0, 5);

      setActiveNotification(filterNotifications);
    }
  }, [notifications]);

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-bell"></i>
          <h5>Bildirimler</h5>
        </div>
        <div className="card-buttons">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            tooltip={"Yenile"}
            onClick={() =>
              setNotificationsUseFetchRender(!notificationsUseFetchRender)
            }
          />
          <Link to={"/notifications"}>
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {notificationsError ? (
            <ErrorComponent error={notificationsError} />
          ) : notificationsLoading ? (
            <Loading />
          ) : activeNotification !== null && activeNotification.length > 0 ? (
            activeNotification.map((notification) => {
              return (
                <li key={notification.id}>
                  <p>{notification.title}</p>
                  <p>{formatDate(notification.createdAt)}</p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">Bildirim Yok!</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default NotificationDetailCard;
