import "./revisedDescriptionModal.css";
import Modal from "react-modal";

function RevisedDescriptionModal({
  revisedNote,
  handleCloseRevisedNote,
  openRevisedNote,
}) {
  console.log("tıkladı");
  return (
    <Modal
      isOpen={openRevisedNote}
      onRequestClose={handleCloseRevisedNote}
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "800px",
          minWidth: "300px",
          maxHeight: "80%",
          minHeight: "100px",
          overflow: "auto",
          // padding:0
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <button onClick={handleCloseRevisedNote} className="rev-des-close-btn">
        <i className="fa-regular fa-rectangle-xmark" />
      </button>
      <h4 className="rev-des-title">Revize Açıklaması</h4>
      <p className="rev-des-note">{revisedNote}</p>
    </Modal>
  );
}

export default RevisedDescriptionModal;