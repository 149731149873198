import "./styles/howItWorkStyle/howItWork.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import InputSearch from "../../components/form_components/Inputs/InputSearch/InputSearch";
import VideosPage from "./VideosPage";
import DocumentsPage from "./DocumentsPage";

function HowItWork() {
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];

  const [searchBar, setSearchBar] = useState("");

  const [howItWorkPageLink, setHowItWorkPageLink] = useState("videos");

  function handleClickLink(link) {
    setHowItWorkPageLink(link);
  }


  return (
    <div className="how-it-work-container">
      <div className="howitwork-title-container">
        <div className="title-box">
          <i className="fa-solid fa-question" />
          <h4 className="title">
            Nasıl Çalışır {""}
            {page === "offers"
              ? "- Teklif sayfası işlemleri"
              : page === "opportunities"
              ? "- Fırsatlar sayfası işlemleri"
              : page === "suppliers"
              ? "- Tedarikçi sayfası işlemleri"
              : page === "users"
              ? "- Kullanıcılar sayfası işlemleri"
              : page === "tenders"
              ? "- İhale sayfası işlemleri"
              : page === "company-list"
              ? "- Firma bilgileri sayfası işlemleri"
              : page === "company-profile-edit"
              ? "- Firma bilgileri sayfası işlemleri"
              : ""}
          </h4>
        </div>

        <div className="right-box">
          <InputSearch
            value={searchBar}
            onChange={(e) => setSearchBar(e.target.value)}
          />
        </div>
      </div>

      <div className="howitwork-links">
        <div className="left-box">
          <ButtonText
            icon={<i className="fa-solid fa-file-pdf"></i>}
            text={"Dökümanlar"}
            onClick={() => handleClickLink("documents")}
            className={howItWorkPageLink === "documents" ? "button-active" : ""}
          />

          <ButtonText
            icon={<i className="fa-solid fa-video"></i>}
            text={"Videolar"}
            onClick={() => handleClickLink("videos")}
            className={howItWorkPageLink === "videos" ? "button-active" : ""}
          />
        </div>
      </div>

      <div className="howitwork-main-container">
        {howItWorkPageLink === "documents" ? (
          <DocumentsPage />
        ) : howItWorkPageLink === "videos" ? (
          <VideosPage searchBar={searchBar} />
        ) : (
          <div className="table-null-text">Böyle bir sayfa yok!</div>
        )}
      </div>
    </div>
  );
}

export default HowItWork;
