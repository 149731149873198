import "./modalCarousel.css";
import React, { useState } from "react";

function ModalCarousel({
  images,
  openModalCarousel,
  handleCloseModalCarousel,
}) {
  const [modalCarouselImageIndex, setModalCarouselImageIndex] = useState(0);

  const handleNext = () => {
    if (modalCarouselImageIndex < images.length - 1) {
      setModalCarouselImageIndex(modalCarouselImageIndex + 1);
    } else {
      setModalCarouselImageIndex(0);
    }
  };

  const handlePrev = () => {
    if (modalCarouselImageIndex > 0) {
      setModalCarouselImageIndex(modalCarouselImageIndex - 1);
    } else {
      setModalCarouselImageIndex(images.length - 1);
    }
  };

  console.log(images);
  return (
    <div className={`modal-carousel-container`}>
      <div
        className={`images-detail-main  ${
          openModalCarousel ? "open-modal" : "out-modal"
        }`}
      >
        <div className="images-detail-close" onClick={handleCloseModalCarousel}>
          X
        </div>

        <div className="modal-image-box">
          {images && images.length > 0 ? (
            <img
              src={
                images[modalCarouselImageIndex].includes("http")
                  ? images[modalCarouselImageIndex]
                  : `http://sosocrm.oetech.online/api/Images/images/${images[modalCarouselImageIndex]}`
              }
              alt="offer-img"
              className="modal-img"
            />
          ) : (
            <p className="table-null-text">Resim yok!</p>
          )}
        </div>

        <button className="images-detail-button-prev" onClick={handlePrev}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
        <button className="images-detail-button-next" onClick={handleNext}>
          <i className="fa-solid fa-caret-right"></i>
        </button>
      </div>
    </div>
  );
}

export default ModalCarousel;
