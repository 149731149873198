import "./companyApplicationForm.css";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import { ThemeContextValue } from "../../context/ThemeContext";

import { withNamespaces } from "react-i18next";
import InputLogin from "../../components/form_components/Inputs/InputLogin/InputLogin";
import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";
import StarTicIcon from "../../components/Icons/StarTicIcon";
import LangBox from "../../components/LangBox/LangBox";
import images from "../../assets/images";
import icons from "../../assets/icons/index";

const { offerexLogoDark, offerexLogoLight } = icons;

function CompanyApplicationForm({ t }) {
  const { refCode } = useParams();

  const { theme } = ThemeContextValue();
  const { token } = AuthContextValue();
  const { createMethod } = CrudContextValue();

  const [formInputErrors, setFormInputErrors] = useState({
    companyNameError: false,
    companyTypeError: false,
    managerEmailError: false,
    phoneNumberError: false,
  });
  const [loading, setLoading] = useState(false);

  // {
  //     "companyName": "string",
  //     "managerEmail": "string",
  //     "companyType": 1,
  //     "companyEmail": "string",
  //     "phoneNumber": "string",
  //     "description": "string",
  //     "refNo": "string"
  // }
  const [applicationForm, setApplicationForm] = useState({
    companyName: "",
    managerEmail: "",
    companyType: 1,
    companyEmail: "",
    phoneNumber: "",
    description: "",
    refNo: refCode,
  });

  useEffect(() => {
    setApplicationForm({ ...applicationForm, refNo: refCode });
  }, [refCode]);

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "companyType") {
      setApplicationForm({
        ...applicationForm,
        [name]: Number(value),
      });
    } else {
      setApplicationForm({
        ...applicationForm,
        [name]: value,
      });
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    
    console.log("applicationForm : ", applicationForm);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun
    
    if (!applicationForm.companyName) {
      inputErrors.companyNameError = true;
    }
    
    if (!applicationForm.companyType) {
      inputErrors.companyTypeError = true;
    }
    
    if (!applicationForm.managerEmail) {
      inputErrors.managerEmailError = true;
    }
    
    if (!applicationForm.phoneNumber) {
      inputErrors.phoneNumberError = true;
    }

    
    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setFormInputErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
    } else {
      setLoading(true);
      
      await createMethod(
        process.env.REACT_APP_POST_SUPPLIER_REGISTRATION_URL,
        JSON.stringify(applicationForm),
        token
      );

      setLoading(false);

      setApplicationForm({
        companyName: "",
        managerEmail: "",
        companyType: 1,
        companyEmail: "",
        phoneNumber: "",
        description: "",
        refNo: refCode,
      });
    }
  }

  return (
    <div className="company-application-form">
      <div className="offerex-logo-container">
        <img
          src={
            theme === "light"
              ? offerexLogoDark
              : theme === "dark"
              ? offerexLogoLight
              : offerexLogoDark
          }
          alt="logo"
        />
      </div>

      <form className="company-application-form-apply" onSubmit={handleSubmit}>
        <h4>Başvuru Formu </h4>

        <div className="company-application-form-line">
          <InputLogin
            required={true}
            errorBorder={formInputErrors.companyNameError}
            type={"text"}
            title={"Firma Adı"}
            name={"companyName"}
            placeholder={"Firma Adı..."}
            value={applicationForm.companyName}
            onChange={handleChange}
          />

          <InputLogin
            required={true}
            errorBorder={formInputErrors.managerEmailError}
            type={"email"}
            title={"Yönetici E-Posta"}
            name={"managerEmail"}
            placeholder={"Yönetici E-Posta"}
            value={applicationForm.managerEmail}
            onChange={handleChange}
          />

          <div className="input-login-container">
            <label htmlFor="companyType">* Firma Tipi : </label>
            <select
              required
              style={{
                border: formInputErrors.companyType ? "2px solid red" : "",
              }}
              name="companyType"
              id="companyType"
              value={applicationForm.companyType}
              onChange={handleChange}
            >
              <option value={1}>Ana Firma</option>
              <option value={2}>Tedarikçi</option>
              <option value={3}>Ana Firma - Tedarikçi</option>
            </select>
          </div>

          <InputLogin
            required={true}
            errorBorder={formInputErrors.phoneNumberError}
            type={"tel"}
            title={t("input_phone")}
            name={"phoneNumber"}
            placeholder={`${t("input_phone")}...`}
            value={applicationForm.phoneNumber}
            onChange={handleChange}
          />

          <InputLogin
            type={"email"}
            title={"Firma E-Postası"}
            name={"companyEmail"}
            placeholder={`Firma E-Postası...`}
            value={applicationForm.companyEmail}
            onChange={handleChange}
          />

          <div className="input-login-container">
            <label htmlFor="description"> Açıklama : </label>
            <textarea
              name="description"
              cols="10"
              id="description"
              value={applicationForm.description}
              onChange={handleChange}
            />
          </div>

          <InputLogin
            type={"text"}
            title={"Referans Numarası"}
            name={"refNo"}
            placeholder={`Referans Numarası...`}
            defaultValue={refCode}
            disabled
          />
        </div>

        <ButtonWhiteOpacity
          icon={loading ? null : <StarTicIcon />}
          text={loading ? "Loading..." : t("button_form_apply")}
          type={"submit"}
        />
      </form>

      <div className="lang-box">
        <LangBox />
      </div>

      <div className="copyright-container">
        <p>OETech Solutions - 2023</p>
      </div>
    </div>
  );
}

export default withNamespaces()(CompanyApplicationForm);
