import React, { useState } from "react";

import ButtonDark from "../../components/form_components/Buttons/ButtonDark/ButtonDark";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import { toast } from "react-toastify";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function CompanyAdd() {
  const { createMethod } = CrudContextValue();
  const { token } = AuthContextValue();

  const [inviteSuppLoading, setInviteSuppLoading] = useState(false);

  const [inviteForm, setInviteForm] = useState({
    companyName: "",
    email: "",
    phoneNumber: "",
  });

  const [inviteFormErrors, setInviteFormErrors] = useState({
    companyNameError: false,
    emailError: false,
    phoneNumberError: false,
  });

  function handleChangeInvite(e) {
    const { name, value } = e.target;

    console.log(e.target);

    setInviteForm({
      ...inviteForm,
      [name]: value,
    });

    setInviteFormErrors({
      companyNameError: false,
      emailError: false,
      phoneNumberError: false,
    });
  }

  async function handleInviteSupplier() {
    console.log("inviteForm: ", inviteForm);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!inviteForm.companyName) {
      inputErrors.companyNameError = true;
    }

    if (!inviteForm.email) {
      inputErrors.emailError = true;
    }

    if (!inviteForm.phoneNumber) {
      inputErrors.phoneNumberError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setInviteFormErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
    } else {
      setInviteSuppLoading(true);
      const inviteResponse = await createMethod(
        `http://sosocrm.oetech.online/api/Supplier/invite-supplier?CompanyName=${inviteForm.companyName}&ManagerEmail=${inviteForm.email}&PhoneNumber=${inviteForm.phoneNumber}`,
        null,
        token
      );

      if (
        inviteResponse &&
        !inviteResponse.cathError &&
        !inviteResponse.isError
      ) {
        setInviteForm({
          companyName: "",
          email: "",
          phoneNumber: "",
        });
      }

      toast.success("Tedarikçiye davet maili gönderildi", {
        autoClose: 3000,
      });

      setInviteSuppLoading(false);
    }
  }

  return (
    <div className="company-add-container">
      <div className="company-add-edit-box">
        <div className="title-box">
          <i className="fa-solid fa-plus"></i>
          <h4 className="title">Firma Ekle</h4>
        </div>

        <div className="company-edit-button-groups">
          <ButtonDark
            icon={<i className="fa-solid fa-floppy-disk" />}
            text={inviteSuppLoading ? "Yükleniyor" : "Kaydet"}
            onClick={handleInviteSupplier}
            className="button-dark-active"
          />
        </div>
      </div>

      <div className="company-add-form-container">
        <form onSubmit={handleInviteSupplier}>
          <InputStandart
            required={true}
            errorBorder={inviteFormErrors.companyNameError}
            type={"text"}
            name={"companyName"}
            title={"Şirket Adı"}
            placeholder={"Şirket Adı..."}
            onChange={handleChangeInvite}
            value={inviteForm.companyName}
          />

          <InputStandart
            required={true}
            errorBorder={inviteFormErrors.emailError}
            type={"email"}
            name={"email"}
            title={"E-Posta"}
            placeholder={"E-Posta..."}
            onChange={handleChangeInvite}
            value={inviteForm.email}
          />

          <InputStandart
            required={true}
            errorBorder={inviteFormErrors.phoneNumberError}
            type={"tel"}
            name={"phoneNumber"}
            title={"Telefon"}
            placeholder={"Telefon..."}
            onChange={handleChangeInvite}
            value={inviteForm.phoneNumber}
          />
        </form>
      </div>

      {inviteSuppLoading && <LoadingCircular />}
    </div>
  );
}

export default CompanyAdd;
