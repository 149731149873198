import "./exChangeRateCard.css";
import React from "react";
import { GetContextValue } from "../../../context/GetContext";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";

function ExChangeRateCard() {
  const { exChangeRate, exChangeRateLoading, exChangeRateError } =
    GetContextValue();
  return (
    <div className="exchange-card-container">
      <div className="exchange-card-main">
        {exChangeRateError ? (
          <ErrorComponent error={exChangeRateError} />
        ) : exChangeRateLoading ? (
          <Loading />
        ) : exChangeRate !== null && exChangeRate.length > 0 ? (
          <>
            <table className="money-table">
              <thead>
                <tr>
                  <th>Para Birimi</th>
                  <th>Kodu</th>
                  <th>Alış</th>
                  <th>Satış</th>
                </tr>
              </thead>
              <tbody>
                {exChangeRate.map((money, index) => {
                  return (
                    <tr key={index}>
                      <td title={money.adi}>{money.adi}</td>
                      <td title={money.kodu}>{money.kodu}</td>
                      <td title={money.alisKuru}>{money.alisKuru}</td>
                      <td title={money.satisKuru}>{money.satisKuru}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* {exChangeRate.map((money, index) => {
              return (
                <div className="money-box" key={index}>
                  <div className="money-icon">{money.kodu}</div>
                  <div className="money">
                    {money.kodu === "CNY"
                      ? money.satisKuru
                      : money.efektifSatisKuru}
                  </div>
                </div>
              );
            })} */}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(ExChangeRateCard);
