import "./styles/userPages.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import UserEditBox from "../../components/EditBox/UserEditBox";
import { CrudContextValue } from "../../context/CrudContext";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function Users({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();
  const { deleteMethod, createMethod } = CrudContextValue();

  const [resetPassLoading, setResetPassLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const handleOpenResetPasswordModal = () => setOpenResetPasswordModal(true);
  const handleCloseResetPasswordModal = () => setOpenResetPasswordModal(false);

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const handleOpenDeleteUserModal = () => setOpenDeleteUserModal(true);
  const handleCloseDeleteUserModal = () => setOpenDeleteUserModal(false);

  const [searchText, setSearchText] = useState("");
  const [usersRole, setUsersRole] = useState("");

  // const [loginCompanyUsersTotal, setLoginCompanyUsersTotal] = useState(0);
  const [loginCompanyUsersPage, setLoginCompanyUsersPage] = useState(1);
  const [loginCompanyUsersRowsPerPage, setLoginCompanyUsersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setLoginCompanyUsersUseFetchRender,
    useFetchRender: loginCompanyUsersUseFetchRender,
    error: loginCompanyUsersError,
    loading: loginCompanyUsersLoading,
    data: loginCompanyUsers,
    total: loginCompanyUsersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?RoleName=${usersRole ? usersRole.split(",")[1] : ""}&SearhedText=${searchText}&CompanyId=${loginUserCompanyId}&Page=${loginCompanyUsersPage}&PageSize=${loginCompanyUsersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: "Foto",
    },
    {
      key: "firstName",
      header: "Adı",
    },
    {
      key: "lastName",
      header: "Soyadı",
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "roleName",
      header: t("table_role"),
    },
  ];

  useEffect(() => {
    setLoginCompanyUsersUseFetchRender(!loginCompanyUsersUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    setLoginCompanyUsersUseFetchRender(!loginCompanyUsersUseFetchRender);
  }, [usersRole]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function handleDeleteUser() {
    console.log(selectValue.email, " emailli firma silindi!");
    setDeleteUserLoading(true);

    await deleteMethod(
      `http://sosocrm.oetech.online/api/User/User-Delete-Test?email=${selectValue.email}`,
      token
    );

    setDeleteUserLoading(false);

    handleCloseDeleteUserModal();

    setLoginCompanyUsersUseFetchRender(!loginCompanyUsersUseFetchRender);
  }

  async function handleResetPassword() {
    setResetPassLoading(true);

    const email = selectValue.email;
    const responsePost = await createMethod(
      `http://sosocrm.oetech.online/api/Auth/password-reset?mail=${email}`,
      null,
      null
    );

    console.log(responsePost);

    setResetPassLoading(false);

    handleCloseResetPasswordModal();
  }

  return (
    <div className="users-container">
      <div className="users-edit-box">
        {deleteUserLoading || resetPassLoading ? (
          <LoadingCircular />
        ) : openResetPasswordModal ? (
          <ConfirmModal
            text={`${selectValue?.firstName} adlı kullanıcının şifresini sıfırlamak istediğinize emin misiniz?`}
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Sıfırla"}
            confirmFunc={handleResetPassword}
            handleCloseModal={handleCloseResetPasswordModal}
          />
        ) : openDeleteUserModal ? (
          <ConfirmModal
            text={`${selectValue?.firstName} adlı kullanıcıyı silmek istediğinize emin misiniz?`}
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Sil"}
            confirmFunc={handleDeleteUser}
            handleCloseModal={handleCloseDeleteUserModal}
          />
        ) : (
          //Tabloda işlem yapmak için kullanılan butonlar
          <UserEditBox
            type={"users"}
            title={"Kullanıcılar"}
            searchText={searchText}
            setSearchText={setSearchText}
            tableFilter={usersRole}
            setTableFilter={setUsersRole}
            useFetchRender={loginCompanyUsersUseFetchRender}
            setUseFetchRender={setLoginCompanyUsersUseFetchRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenResetPasswordModal={handleOpenResetPasswordModal}
            handleOpenDeleteUserModal={handleOpenDeleteUserModal}
          />
        )}
      </div>

      <div className="users-table">
        {loginCompanyUsersError ? (
          <ErrorComponent error={loginCompanyUsersError} />
        ) : loginCompanyUsersLoading ? (
          <Loading />
        ) : loginCompanyUsers !== null ? (
          <StandartTable
            type="user"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={loginCompanyUsersUseFetchRender}
            setUseFetchRender={setLoginCompanyUsersUseFetchRender}
            total={loginCompanyUsersTotal}
            page={loginCompanyUsersPage}
            setPage={setLoginCompanyUsersPage}
            rowsPerPage={loginCompanyUsersRowsPerPage}
            setRowsPerPage={setLoginCompanyUsersRowsPerPage}
            columns={columns}
            data={loginCompanyUsers}
          />
        ) : (
          <div className="table-null-text">Sistemde kayıtlı kullanıcı yok</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(Users);
