import "../style/detailCardStyle/detailCardStyle.css";
import React, { useEffect } from "react";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../../context/GetContext";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";

function formatDate(createdAt) {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);

  // Bugünün tarihini alır
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Oluşturulan tarihini alır
  const createdDay = createdDate.getDate();
  const createdMonth = createdDate.getMonth();
  const createdYear = createdDate.getFullYear();

  // Bugünün tarihini kontrol eder
  if (
    currentDay === createdDay &&
    currentMonth === createdMonth &&
    currentYear === createdYear
  ) {
    return "bugün";
  }

  // Dünün tarihini kontrol eder
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDay - 1);

  if (
    createdDay === yesterday.getDate() &&
    createdMonth === yesterday.getMonth() &&
    createdYear === yesterday.getFullYear()
  ) {
    return "dün";
  }

  // Tarihi formatlar
  const options = { year: "numeric", month: "long", day: "numeric" };
  return createdDate.toLocaleDateString("tr-TR", options);
}

function DeadlinesDetailCard() {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const opportunitiesStatus = 2;

  const {
    setUseFetchRender: setOpportunitiesWaitingForOfferUseFetchRender,
    useFetchRender: opportunitiesWaitingForOfferUseFetchRender,
    error: opportunitiesWaitingForOfferError,
    loading: opportunitiesWaitingForOfferLoading,
    data: opportunitiesWaitingForOffer,
    total: opportunitiesWaitingForOfferTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_TENDER_SUPPLIER_LIST_URL
    }?Count=0&SortType=${3}&Status=${opportunitiesStatus}&CompanyId=${loginUserCompanyId}&Page=${1}&PageSize=${5}`,
    token
  );

  useEffect(() => {
    setOpportunitiesWaitingForOfferUseFetchRender(
      !opportunitiesWaitingForOfferUseFetchRender
    );
  }, [notificationRender]);

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-calendar-check"></i>
          <h5>Terminler</h5>
        </div>
        <div className="card-buttons">
          <Link to={"/opportunities"}>
            <WhiteButton icon={<i className="fa-solid fa-info"></i>} tooltip={"Detay"} />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {opportunitiesWaitingForOfferError ? (
            <ErrorComponent error={opportunitiesWaitingForOfferError} />
          ) : opportunitiesWaitingForOfferLoading ? (
            <Loading />
          ) : opportunitiesWaitingForOffer !== null &&
            opportunitiesWaitingForOffer.length > 0 ? (
            opportunitiesWaitingForOffer.map((opportunities) => {
              return (
                <li key={opportunities.tenderId}>
                  <p>{opportunities.companyName}</p>
                  <p>{formatDate(opportunities.expireDate)}</p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">Teklif bekleyen bir fırsat yok!</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default DeadlinesDetailCard;
