import "./imageDetailCard.css";
import { useEffect, useState } from "react";

import ButtonDark from "../form_components/Buttons/ButtonDark/ButtonDark";
import ModalCarousel from "../Modals/ModalCarousel/ModalCarousel";

function ImageDetailCard({ images }) {
  const [imagesData, setImagesData] = useState([]);

  const [openModalCarousel, setOpenModalCarousel] = useState(false);
  const handleOpenModalCarousel = () => setOpenModalCarousel(true);
  const handleCloseModalCarousel = () => setOpenModalCarousel(false);

  useEffect(() => {
    if (images) {
      setImagesData(images);
    }
  }, [images]);

  function ImagesModal() {
    handleOpenModalCarousel();
  }

  return (
    <div className="image-detail-card">
      <div className="image-detail-img-box">
        <div className="image-detail-img-card">
          {imagesData && <img src={imagesData[0]} alt="tender-image" />}
        </div>
      </div>
      <ButtonDark
        icon={<i className="fa-regular fa-images"></i>}
        onClick={ImagesModal}
      />

      {openModalCarousel && (
        <ModalCarousel
          images={imagesData}
          openModalCarousel={openModalCarousel}
          handleCloseModalCarousel={handleCloseModalCarousel}
        />
      )}
    </div>
  );
}

export default ImageDetailCard;
