import "./revisedModal.css";
import { useState } from "react";
import ButtonDark from "../../form_components/Buttons/ButtonDark/ButtonDark";

function RevisedModal({
  t,
  confirmIcon,
  confirmText,
  cancelIcon,
  cancelText,
  handleCloseModal,
  confirmFunc,
}) {
  const [revisedDescription, setRevisedDescription] = useState("");

  function tenderRevised() {
    console.log(revisedDescription);
    confirmFunc(revisedDescription);

    handleCloseModal();
  }

  return (
    <div className="revised-modal-container">
      <div className="revised-modal-main">
        <div className="revised-input-container">
          <label htmlFor="revised-description">Revize Sebebi:</label>
          <input
            type="text"
            id="revised-description"
            name="revisedDescription"
            placeholder="Revize sebebini giriniz..."
            value={revisedDescription}
            onChange={(e) => setRevisedDescription(e.target.value)}
          />
        </div>

        <div className="revised-modal-button-group">
          <ButtonDark
            icon={confirmIcon}
            text={confirmText}
            onClick={tenderRevised}
            className="button-dark-active"
          />
          <ButtonDark
            icon={cancelIcon}
            text={cancelText}
            onClick={handleCloseModal}
            className="button-dark-active"
          />
        </div>
      </div>
    </div>
  );
}

export default RevisedModal;
