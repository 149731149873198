import "./styles/offers.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";

import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import OfferEditBox from "../../components/EditBox/OfferEditBox";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import WaitingOffers from "./WaitingOffers";
import SumbittedOffers from "../Offers/SumbittedOffers";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function Offers({ t }) {
  const navigate = useNavigate();

  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    /* Sayfa link durumları */

    offersPageLink,
    setOffersPageLink,
  } = GetContextValue();
  const { deleteMethod, updateMethod } = CrudContextValue();

  const [revisedLoading, setRevisedLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNoteModal] = useState(false);
  const handleOpenRevisedNoteModal = () => setOpenRevisedNoteModal(true);
  const handleCloseRevisedNoteModal = () => setOpenRevisedNoteModal(false);

  const [resultRevisedNote, setResultRevisedNote] = useState("");
  const [openResultRevisedNote, setOpenResultRevisedNoteModal] =
    useState(false);
  const handleOpenResultRevisedNoteModal = () =>
    setOpenResultRevisedNoteModal(true);
  const handleCloseResultRevisedNoteModal = () =>
    setOpenResultRevisedNoteModal(false);

  const [tableRender, setTableRender] = useState(false);
  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);
  const [offerStatus, setofferStatus] = useState("all");
  const [searchText, setSearchText] = useState("");

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function handleDeleteOffer() {
    console.log(selectValueId, "idli teklif silindi");

    setDeleteLoading(true);
    console.log(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${selectValueId}`
    );
    await deleteMethod(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${selectValueId}`,
      token
    );

    setDeleteLoading(false);

    handleCloseDeleteModal();

    setTableRender(!tableRender);
  }

  async function handleRevisedOffer(revisedDescription) {
    console.log(
      selectValueId,
      "idli teklif revize edildi",
      " açıklaması : ",
      revisedDescription
    );

    setRevisedLoading(true);

    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${selectValueId}&status=3&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    handleCloseRevisedModal();

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      handleCloseRevisedModal();

      toast.info("Tekliflerim sayfasına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }

    setSelectValue(null);
    setSelectValueId(null);
    setTableRender(!tableRender);
  }

  async function handleConfirmOffer() {
    console.log(selectValueId, "idli teklif onaylandı");

    setApprovedLoading(true);
    console.log(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${selectValueId}&status=Onaylandı`
    );
    await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${selectValueId}&status=Onaylandı`,
      null,
      token
    );

    setApprovedLoading(false);

    handleCloseConfirmModal();

    setTableRender(!tableRender);
  }

  const handleRevised = (revisedDescription) => {
    // revisedDescription değeriyle bir şeyler yapın
    console.log("Revised description:", revisedDescription);
  };

  return (
    <div className="offers-main">
      <div className="offers-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {revisedLoading || deleteLoading || approvedLoading ? (
          <LoadingCircular />
        ) : deleteModalOpen ? (
          <ConfirmModal
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Sil"}
            handleCloseModal={handleCloseDeleteModal}
            confirmFunc={handleDeleteOffer}
            text={"Teklifi silmek istediğinize emin misiniz?"}
          />
        ) : revisedModalOpen ? (
          <RevisedModal
            cancelText={"Vazgeç"}
            confirmText={"Revize İste"}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedOffer}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Onayla"}
            handleCloseModal={handleCloseConfirmModal}
            confirmFunc={handleConfirmOffer}
            text={"Teklifi onaylamak istediğinize emin misiniz?"}
          />
        ) : (
          <OfferEditBox
            type={"offer"}
            offersPageLink={offersPageLink}
            searchText={searchText}
            setSearchText={setSearchText}
            statusFilter={offerStatus}
            setStatusFilter={setofferStatus}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            useFetchRender={tableRender}
            setUseFetchRender={setTableRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenConfirmModal={handleOpenConfirmModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenRevisedModal={handleOpenRevisedModal}
            handleOpenRevisedNoteModal={handleOpenRevisedNoteModal}
            handleOpenResultRevisedNoteModal={handleOpenResultRevisedNoteModal}
          />
        )}

        {openRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={revisedNote}
            openRevisedNote={openRevisedNote}
            handleCloseRevisedNote={handleCloseRevisedNoteModal}
          />
        )}

        {openResultRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={resultRevisedNote}
            openRevisedNote={openResultRevisedNote}
            handleCloseRevisedNote={handleCloseResultRevisedNoteModal}
          />
        )}
      </div>

      <div
        className={`offers-page-link ${
          revisedLoading ||
          deleteLoading ||
          approvedLoading ||
          revisedModalOpen ||
          deleteModalOpen ||
          confirmModalOpen
            ? "disabled-box"
            : ""
        }`}
      >
        <div className="opportunities-button-groups">
          <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={"Gönderilenler"}
            onClick={() => setOffersPageLink("sumbitted-offer")}
            className={
              offersPageLink === "sumbitted-offer" ? "button-active" : ""
            }
          />

          <ButtonText
            icon={<i className="fa-solid fa-clock"></i>}
            text={"Bekleyenler"}
            onClick={() => {
              setOffersPageLink("waiting-offer");
              setofferStatus("all");
            }}
            className={
              offersPageLink === "waiting-offer" ? "button-active" : ""
            }
          />
        </div>
      </div>

      <div
        className={`offers-table ${
          revisedLoading ||
          deleteLoading ||
          approvedLoading ||
          revisedModalOpen ||
          deleteModalOpen ||
          confirmModalOpen
            ? "disabled-box"
            : ""
        }`}
      >
        {offersPageLink === "sumbitted-offer" ? (
          <SumbittedOffers
            offerStatus={offerStatus}
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
            setResultRevisedNote={setResultRevisedNote}
          />
        ) : offersPageLink === "waiting-offer" ? (
          <WaitingOffers
            offersPageLink={offersPageLink}
            offerStatus={offerStatus}
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
            setRevisedNote={setRevisedNote}
          />
        ) : (
          "null"
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(Offers);
