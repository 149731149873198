function FactoryIcon(props) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.25 0C0.558594 0 0 0.638393 0 1.42857V17.8571C0 19.0402 0.839844 20 1.875 20H18.125C19.1602 20 20 19.0402 20 17.8571V5.36607C20 4.55357 19.2422 4.04018 18.6172 4.42411L12.5 8.1875V5.36607C12.5 4.55357 11.7422 4.04018 11.1172 4.42411L5 8.1875V1.42857C5 0.638393 4.44141 0 3.75 0H1.25Z"
            />
        </svg>

    )
}

export default FactoryIcon;
