import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function CompanyUsers({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [searchText, setSearchText] = useState("");
  const [usersRole, setUsersRole] = useState("");

  const [loginCompanyUsersPage, setLoginCompanyUsersPage] = useState(1);
  const [loginCompanyUsersRowsPerPage, setLoginCompanyUsersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setLoginCompanyUsersUseFetchRender,
    useFetchRender: loginCompanyUsersUseFetchRender,
    error: loginCompanyUsersError,
    loading: loginCompanyUsersLoading,
    data: loginCompanyUsers,
    total: loginCompanyUsersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?${
      usersRole && "RoleName=" + usersRole
    }&SearhedText=${searchText}&CompanyId=${id}&Page=${loginCompanyUsersPage}&PageSize=${loginCompanyUsersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: "Foto",
    },
    {
      key: "firstName",
      header: "Adı",
    },
    {
      key: "lastName",
      header: "Soyadı",
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "roleName",
      header: t("table_role"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setLoginCompanyUsersUseFetchRender(!loginCompanyUsersUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <>
      {loginCompanyUsersError ? (
        <ErrorComponent error={loginCompanyUsersError} />
      ) : loginCompanyUsersLoading ? (
        <Loading />
      ) : loginCompanyUsers !== null ? (
        <StandartTable
          type="user"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={loginCompanyUsersUseFetchRender}
          setUseFetchRender={setLoginCompanyUsersUseFetchRender}
          total={loginCompanyUsersTotal}
          page={loginCompanyUsersPage}
          setPage={setLoginCompanyUsersPage}
          rowsPerPage={loginCompanyUsersRowsPerPage}
          setRowsPerPage={setLoginCompanyUsersRowsPerPage}
          columns={columns}
          data={loginCompanyUsers}
        />
      ) : (
        <div className="table-null-text">Sistemde kayıtlı kullanıcı yok</div>
      )}
    </>
  );
}

export default withNamespaces()(CompanyUsers);
