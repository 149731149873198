import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { CrudContextValue } from "../../../context/CrudContext";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const validateMessages = {
  required: "${label} zorunlu!",
};

function SupportRequestModal({ handleCloseModal }) {
  const {u} = CrudContextValue();

  const [sumbitLoading, setSumbitLoading] = useState(false);

  const onFinish = (values) => {
    console.log(values);

    setSumbitLoading(true);

    setSumbitLoading(false);

    toast.success("Destek talep mesajınız başarıyla gönderildi", {
      autoClose: 3000,
    });
    setTimeout(() => {
      handleCloseModal();
    }, 3000);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      style={{
        maxWidth: 600,
        paddingRight: "5rem",
        paddingTop: "1rem",
      }}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="title"
        label="Başlık"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Açıklama"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="photo" label="Fotoğraf">
        <Input type="file" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          Gönder
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SupportRequestModal;
