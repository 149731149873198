import "./styles/tenderDetailManager/tenderDetailManager.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";

import useFetch from "../../hooks/UseFetch";

import StandartTable from "../../components/Table/StandartTable/StandartTable";
import Loading from "../../components/Loading/Loading";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import { DemoContextValue } from "../../context/DemoContext";

function TenderDetailManager({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const { token } = AuthContextValue();
  const { deleteMethod, updateMethod } = CrudContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();
  const { demoTenders, demoSuppliers, setDemoTenders } = DemoContextValue();
  const [demoTenderDetail, setDemoTenderDetail] = useState({});

  const [approvedLoading, setApprovedLoading] = useState(false);
  const [revisedLoading, setRevisedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [offerConfirm, setOfferConfirm] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const [openRevisedModal, setOpenRevisedModal] = useState(false);
  const handleOpenRevisedModal = () => setOpenRevisedModal(true);
  const handleCloseRevisedModal = () => setOpenRevisedModal(false);

  const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  const [formattedTime, setFormattedTime] = useState("");

  const [supplierstatus, setSupplierstatus] = useState("");
  const [suppliersPage, setSuppliersPage] = useState(1);
  const [suppliersRowsPerPage, setSuppliersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
    total: tenderDetailTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  const {
    setUseFetchRender: setSuppliersUseFetchRender,
    useFetchRender: suppliersUseFetchRender,
    error: suppliersError,
    loading: suppliersLoading,
    data: suppliers,
    total: suppliersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL}?TenderId=${tenderId}&Last=false&SortType=1&Page=${suppliersPage}&PageSize=${suppliersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: "Foto",
    },
    {
      key: "companyName",
      header: "Tedarikçi",
    },
    {
      key: "city",
      header: "Şehir",
    },
    {
      key: "distance",
      header: "Mesafe(km)",
    },
    {
      key: "averageScore",
      header: "Puan",
    },
  ];

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }, [notificationRender]);

  function hanldeClickLink(link, aa) {
    console.log(link, aa);
    // setSupplierstatus(link);
    // setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }

  useEffect(() => {
    /* tenderdetailde revize açıklaması gelmiyor sayfa yenilenirse selectValue sıfırlanıyor ve not görünmüyor */
    if (tenderDetail) {
      setRevisedNote(tenderDetail.confirmDescription);
    }
  }, [tenderDetail]);

  async function confirmFunction() {
    if (offerConfirm === "confirm") {
      setApprovedLoading(true);

      const response = await updateMethod(
        `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${tenderId}&status=Onaylandı&confirmDescription=$Onaylandı`,
        null,
        token
      );

      if (response) {
        setApprovedLoading(false);

        handleCloseConfirmModal();
        setTenderDetailUseFetchRender(!suppliersUseFetchRender);

        toast.info("İhale sayfsına yönlendiriliyorsunuz", {
          autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
        });

        setTimeout(() => {
          navigate("/tenders");
        }, 3000);
      }

      setApprovedLoading(false);
    } else if (offerConfirm === "delete") {
      console.log(selectValue, " ihale silindi!");
      setDeleteLoading(true);

      const response = await deleteMethod(
        `${process.env.REACT_APP_DELETE_TENDER_URL}?id=${tenderId}`,
        token
      );

      if (response) {
        setDeleteLoading(false);

        handleCloseConfirmModal();

        toast.info("İhale sayfsına yönlendiriliyorsunuz", {
          autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
        });

        setTimeout(() => {
          navigate("/tenders");
        }, 3000);
      }
      setDeleteLoading(false);
    }
  }

  async function handleRevisedTender(revisedDescription) {
    console.log(
      `${tenderId} idli İhale Revize edildi \n Revize açıklaması: ${revisedDescription}`
    );

    setRevisedLoading(true);

    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${tenderId}&status=Revize&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      setTenderDetailUseFetchRender(!suppliersUseFetchRender);
      handleCloseRevisedModal();

      toast.info("İhale sayfsına yönlendiriliyorsunuz", {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/tenders");
      }, 3000);
    }

    setRevisedLoading(false);
  }

  useEffect(() => {
    if (demoTenders) {
      console.log(demoTenders);
      const tenderDetail = demoTenders.filter(
        (tender) => Number(tender.tenderId) === Number(tenderId)
      );
      console.log(tenderDetail);
      setDemoTenderDetail(tenderDetail[0]);
    }
  }, [demoTenders]);

  function demoRevisedTender() {
    const filteredDemoTenders = demoTenders.filter(
      (tender) => Number(tender.tenderId) !== Number(tenderId)
    );
    setDemoTenders(filteredDemoTenders);

    setRevisedLoading(false);

    toast.success("İhale revize edildi", {
      autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
    });

    toast.info("İhale sayfsına yönlendiriliyorsunuz", {
      autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
    });

    setTimeout(() => {
      navigate("/tenders");
    }, 3000);

    setTenderDetailUseFetchRender(!suppliersUseFetchRender);
    handleCloseRevisedModal();
  }

  return (
    <div className="manager-tender-detail-container">
      <div className="manager-tender-detail-container-top">
        {approvedLoading || revisedLoading || deleteLoading ? (
          <LoadingCircular />
        ) : openConfirmModal ? (
          <ConfirmModal
            confirmFunc={confirmFunction}
            cancelButtonText={"Vazgeç"}
            confirmButtonText={
              offerConfirm === "confirm"
                ? "Kabul Ediyorum"
                : offerConfirm === "delete"
                ? "Sil"
                : ""
            }
            handleCloseModal={handleCloseConfirmModal}
            text={
              offerConfirm === "confirm"
                ? `İhaleyi onaylamak ve yayına almak istediğinize emin misiniz?`
                : offerConfirm === "delete"
                ? `İhaleyi silmek istediğinize emin misiniz?`
                : ""
            }
          />
        ) : openRevisedModal ? (
          <RevisedModal
            confirmText={revisedLoading ? "Loading..." : "Revize et"}
            cancelText={"Vazgeç"}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedTender}
            // confirmFunc={demoRevisedTender}
          />
        ) : (
          <div className="top-box">
            <div className="tender-detail-title">
              <i className="fa-solid fa-ticket-simple"></i>
              <h4>İhale Detayı</h4>
            </div>

            <div className="tender-edit-buttons">
              {tenderDetail && Number(tenderDetail.status) === 3 ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-note-sticky"></i>}
                    tooltip={"Revize Notu"}
                    onClick={() => {
                      setOfferConfirm("revised-note");
                      handleOpenRevisedNote();
                    }}
                    // disabled={selectValueId || selectValue ? false : true}
                  />

                  <Link to={`/tenders/tender-edit/${tenderId}`}>
                    <WhiteButton
                      icon={<i className="fa-solid fa-pen-to-square"></i>}
                      tooltip={"Düzenle"}
                      // disabled={selectValueId || selectValue ? false : true}
                    />
                  </Link>

                  <WhiteButton
                    icon={<i className="fa-solid fa-trash-can"></i>}
                    tooltip={"Sil"}
                    onClick={() => {
                      setOfferConfirm("delete");
                      handleOpenConfirmModal();
                    }}
                    // disabled={selectValueId || selectValue ? false : true}
                  />
                </>
              ) : (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
                    tooltip={"Yenile"}
                    onClick={() => {
                      setSuppliersUseFetchRender(!suppliersUseFetchRender);
                      setTenderDetailUseFetchRender(
                        !tenderDetailUseFetchRender
                      );
                    }}
                    className={"button-white-active"}
                  />
                  <WhiteButton
                    icon={<i className="fa-solid fa-trash"></i>}
                    tooltip={"Sil"}
                    onClick={() => {
                      setOfferConfirm("delete");
                      handleOpenConfirmModal();
                    }}
                  />

                  <WhiteButton
                    icon={<i className="fa-solid fa-arrows-rotate"></i>}
                    tooltip={"Revize İste"}
                    onClick={() => {
                      setOfferConfirm("revised");
                      handleOpenRevisedModal();
                    }}
                  />

                  <WhiteButton
                    icon={<i className="fa-solid fa-check"></i>}
                    tooltip={"Onayla"}
                    onClick={() => {
                      setOfferConfirm("confirm");
                      handleOpenConfirmModal();
                    }}
                  />
                </>
              )}
            </div>
            {openRevisedNote && (
              <RevisedDescriptionModal
                revisedNote={revisedNote}
                openRevisedNote={openRevisedNote}
                handleCloseRevisedNote={handleCloseRevisedNote}
              />
            )}
          </div>
        )}
      </div>

      <div
        className={`manager-tender-detail-container-bottom ${
          approvedLoading ||
          deleteLoading ||
          revisedLoading ||
          openConfirmModal ||
          openRevisedModal
            ? "disabled-box"
            : ""
        }`}
      >
        <div className="manager-tender-detail">
          {tenderDetailError ? (
            <ErrorComponent error={tenderDetailError} />
          ) : tenderDetailLoading ? (
            <Loading />
          ) : tenderDetail !== null ? (
            <TenderDetailCard tenderDetail={tenderDetail} manager={true} />
          ) : null}
          {/* {demoTenderDetail && (
            <TenderDetailCard tenderDetail={demoTenderDetail} manager={true} />
          )} */}
        </div>

        <div className="manager-tender-detail-table">
          {suppliersError ? (
            <ErrorComponent error={suppliersError} />
          ) : suppliersLoading ? (
            <Loading />
          ) : suppliers !== null ? (
            <StandartTable
              type="ofCompany"
              // searchText={searchText}
              // setSearchText={setSearchText}
              selectValueId={null}
              selectValue={null}
              handleClickValue={hanldeClickLink}
              useFetchRender={suppliersUseFetchRender}
              setUseFetchRender={setSuppliersUseFetchRender}
              total={suppliersTotal}
              page={suppliersPage}
              setPage={setSuppliersPage}
              rowsPerPage={suppliersRowsPerPage}
              setRowsPerPage={setSuppliersRowsPerPage}
              columns={columns}
              data={suppliers}
            />
          ) : null}

          {/* <StandartTable
            type="ofCompany"
            // searchText={searchText}
            // setSearchText={setSearchText}
            selectValueId={null}
            selectValue={null}
            handleClickValue={hanldeClickLink}
            useFetchRender={suppliersUseFetchRender}
            setUseFetchRender={setSuppliersUseFetchRender}
            total={suppliersTotal}
            page={suppliersPage}
            setPage={setSuppliersPage}
            rowsPerPage={suppliersRowsPerPage}
            setRowsPerPage={setSuppliersRowsPerPage}
            columns={columns}
            data={demoSuppliers}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderDetailManager);
