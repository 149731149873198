import "./userDetailCard.css";

import React, { useEffect, useState } from "react";
import DetailText from "../DetailText/DetailText";
import { GetContextValue } from "../../context/GetContext";
import { AuthContextValue } from "../../context/AuthContext";
import SelectRoles from "../form_components/Select/SelectRoles";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { CrudContextValue } from "../../context/CrudContext";

function UserDetailCard({ userDetail, useFetchRender, setUseFetchRender }) {
  const { userRole, token } = AuthContextValue();
  const {
    usersStatisticsError,
    usersStatisticsLoading,
    usersStatistics,
    companyDetail,
    roleList,
    roleListLoading,
    roleListError,
  } = GetContextValue();
  const { updateMethod } = CrudContextValue();

  const [roleData, setRoleData] = useState("");
  const [roleChangeLoading, setRoleChangeLoading] = useState(false);

  const [offerTotal, setOfferTotal] = useState(0);
  const [offerSuccess, setOfferSuccess] = useState(0);
  const [offerUnSuccessful, setOfferUnSuccessful] = useState(0);
  const [offerWaitingApp, setOfferWaitingApp] = useState(0);
  const [tenderTotal, setTenderTotal] = useState(0);
  const [tenderActive, setTenderActive] = useState(0);
  const [tenderOver, setTenderOver] = useState(0);

  useEffect(() => {
    if (usersStatistics) {
      usersStatistics.map((user) => {
        if (user.userId === userDetail.userId) {
          setOfferTotal(user.statistics.offerTotal);
          setOfferSuccess(user.statistics.offerSuccess);
          setOfferUnSuccessful(user.statistics.offerUnSuccessful);
          setOfferWaitingApp(user.statistics.offerWaitingApp);
          setTenderTotal(user.statistics.tenderTotal);
          setTenderActive(user.statistics.tenderActive);
          setTenderOver(user.statistics.tenderOver);
        }
      });
    }

    if (userDetail) {
      console.log(userDetail);
      setRoleData(userDetail.roleName);
    }
  }, [userDetail, usersStatistics]);

  async function handleChangeUserRole() {
    console.log(roleData);
    if (roleList) {
      const filterRoles = roleList.find((role) => role.roleName === roleData);

      console.log(filterRoles);

      setRoleChangeLoading(true);

      await updateMethod(
        `${process.env.REACT_APP_PUT_UPDATE_USER_ROLE_URL}?userId=${userDetail.userId}&roleId=${filterRoles.roleId}`,
        null,
        token
      );

      setRoleChangeLoading(false);

      setUseFetchRender(!useFetchRender);
    }
  }

  return (
    <div className="user-detail-card-container">
      <div className="user-detail-card-top-box">
        <div className="image-box">
          <img src={userDetail.image} alt={userDetail.image} />

          <img
            src={userDetail.image}
            alt={userDetail.image}
            className="image-detail"
          />
        </div>
        <div className="user-detail-name-role">
          <h4 className="user-name">
            <strong>
              {userDetail.firstName}
              {userDetail.lastName}
            </strong>
          </h4>

          <div className="user-role">
            {userRole === "Manager" || userRole === "Admin" ? (
              <div className="user-role-change-box">
                <div className="select-box">
                  <select
                    value={roleData}
                    onChange={(e) => setRoleData(e.target.value)}
                    className="select-filter-roles"
                  >
                    <option value="">Roles</option>
                    {roleListError ? (
                      <option>API error</option>
                    ) : roleListLoading ? (
                      <option>Loading...</option>
                    ) : roleList !== null ? (
                      roleList.map((role) => {
                        return (
                          <option key={role.roleId} value={role.roleName}>
                            {role.roleName}
                          </option>
                        );
                      })
                    ) : (
                      <option>NULL</option>
                    )}
                  </select>

                  <small>Rol değiştir</small>
                </div>

                <WhiteButton
                  icon={<i className="fa-solid fa-floppy-disk"></i>}
                  text={roleChangeLoading ? "Loading..." : "Kaydet"}
                  onClick={handleChangeUserRole}
                />
              </div>
            ) : (
              <p>{userDetail.roleName}</p>
            )}
          </div>
        </div>
      </div>
      <div className="user-detail-card-bottom-box">
        <DetailText title={"E-Posta:"} text={userDetail.email} />
        <DetailText title={"Telefon"} text={userDetail.phoneNumber} />
        <DetailText title={"Üyelik Tarihi:"} text={userDetail.createdAt} />

        {companyDetail?.companyType === 1 && (
          <>
            <DetailText title={"Aktif İhale:"} text={`${tenderActive} Adet`} />
            <DetailText title={"Biten İhale:"} text={`${tenderOver} Adet`} />
            <DetailText title={"Toplam İhale:"} text={`${tenderTotal} Adet`} />
          </>
        )}
        {companyDetail?.companyType === 2 && (
          <>
            <DetailText
              title={"Başarılı Teklif:"}
              text={`${offerSuccess} Adet`}
            />
            <DetailText
              title={"Başarısız Teklif:"}
              text={`${offerUnSuccessful} Adet`}
            />
            <DetailText
              title={"Bekleyen Teklif:"}
              text={`${offerWaitingApp} Adet`}
            />
            <DetailText title={"Toplam Teklif:"} text={`${offerTotal} Adet`} />
          </>
        )}

        {companyDetail?.companyType === 3 && (
          <>
            <DetailText title={"Aktif İhale:"} text={`${tenderActive} Adet`} />
            <DetailText title={"Biten İhale:"} text={`${tenderOver} Adet`} />
            <DetailText title={"Toplam İhale:"} text={`${tenderTotal} Adet`} />
            <DetailText
              title={"Başarılı Teklif:"}
              text={`${offerSuccess} Adet`}
            />
            <DetailText
              title={"Başarısız Teklif:"}
              text={`${offerUnSuccessful} Adet`}
            />
            <DetailText
              title={"Bekleyen Teklif:"}
              text={`${offerWaitingApp} Adet`}
            />
            <DetailText title={"Toplam Teklif:"} text={`${offerTotal} Adet`} />
          </>
        )}

        {/* <DetailText title={"Performans:"} text={userDetail.description} /> */}
      </div>
    </div>
  );
}

export default UserDetailCard;
