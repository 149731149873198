import React, { useState } from "react";
import InputStandart from "../../../components/form_components/Inputs/InputStandart/InputStandart";
import { GetContextValue } from "../../../context/GetContext";
import Select from "react-select";
import UploadImageBox from "../../../components/UploadImageBox/UploadImageBox";
import DocumentUploader from "../../../components/DocumanUploder/DocumanUploader";
import InputPrice from "../../../components/form_components/Inputs/InputPrice/InputPrice";
import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { useNavigate } from "react-router-dom";

function TenderAddStepOne({ onNext, formData, step }) {
  const navigate = useNavigate();

  const { categoryList, categoryListLoading, categoryListError, mySuppliers } =
    GetContextValue();

  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [stepOneForm, setStepOneForm] = useState({
    description: formData.description ? formData.description : "",
    productName: formData.productName ? formData.productName : "",
    currency: formData.currency ? formData.currency : "TRY",
    amount: formData.amount ? formData.amount : 0,
    maxPrice: formData.maxPrice ? formData.maxPrice : 0,
    minPrice: formData.minPrice ? formData.minPrice : 0,
    expireDate: formData.expireDate ? formData.expireDate : "",
    deadline: formData.deadline ? formData.deadline : "",
    categoryId: formData.categoryId ? formData.categoryId : [0],
    unitType: formData.unitType ? formData.unitType : 1,
    customer: formData.customer ? formData.customer : "string",
    images: formData.images ? formData.images : [],
    note: formData.note ? formData.note : "",
  });
  /* form hata durumları */
  const [stepOneFormErrors, setStepOneFormErrors] = useState({
    productNameError: false,
    currencyError: false,
    amountError: false,
    maxPriceError: false,
    minPriceError: false,
    expireDateError: false,
    deadlineError: false,
    categoryIdError: false,
    unitTypeError: false,
  });

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleChangeCategory = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setStepOneForm({
      ...stepOneForm,
      categoryId: selectedOptions.map((opt) => opt.value),
    });
  };

  const categoryOptions = categoryList.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));
  const [selectedUnitType, setSelectedUnitType] = useState({
    label: "Adet",
    value: 1,
  });

  const handleChangeUnitType = (selectedOptions) => {
    setSelectedUnitType(selectedOptions);
    setStepOneForm({
      ...stepOneForm,
      unitType: selectedOptions.value,
    });
  };
  const unitTypeOptions = [
    { label: "Adet", value: 1 },
    { label: "Ton", value: 2 },
    { label: "Kilo", value: 3 },
    { label: "Gram", value: 4 },
    { label: "Metre", value: 5 },
    { label: "Santimetre", value: 6 },
    { label: "Milimetre", value: 7 },
    { label: "Litre", value: 8 },
    { label: "Inc", value: 9 },
  ];

  const [currency, setCurrency] = useState("TRY"); // default currency
  const [currencySymbol, setCurrencySymbol] = useState("₺"); // default currency symbol

  const handleCurrencyChange = (e) => {
    const { value } = e.target;

    setCurrency(value);
    setStepOneForm({
      ...stepOneForm,
      currency: value,
    });

    if (value === "TRY") {
      setCurrencySymbol("₺");
    } else if (value === "USD") {
      setCurrencySymbol("$");
    } else if (value === "EUR") {
      setCurrencySymbol("£");
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "minPrice" || name === "maxPrice") {
      const formattedVal = value.replace(/[^\d]/g, ""); // Sadece sayı karakterlerini al
      const numVal = Number(formattedVal).toLocaleString();

      setStepOneForm({
        ...stepOneForm,
        [name]: numVal,
      });
    } else {
      setStepOneForm({
        ...stepOneForm,
        [name]: value,
      });
    }
  }

  const handleNext = () => {
    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!stepOneForm.productName) {
      inputErrors.productNameError = true;
    }

    if (!stepOneForm.currency) {
      inputErrors.currencyError = true;
    }

    if (!stepOneForm.amount) {
      inputErrors.amountError = true;
    }

    if (!stepOneForm.maxPrice) {
      inputErrors.maxPriceError = true;
    }

    if (!stepOneForm.minPrice) {
      inputErrors.minPriceError = true;
    }

    if (!stepOneForm.expireDate) {
      inputErrors.expireDateError = true;
    }

    if (!stepOneForm.deadline) {
      inputErrors.deadlineError = true;
    }

    if (!stepOneForm.categoryId) {
      inputErrors.categoryIdError = true;
    }

    if (!stepOneForm.unitType) {
      inputErrors.unitTypeError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setStepOneFormErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
    } else {
      // parseInt(priceString.replace(/[,.]/g, "")); // virgül ve nokta işaretleri kaldırıldıktan sonra integer'a dönüştürüldü
      const offerData = {
        ...stepOneForm,
        // currency: currency,
        amount: parseInt(stepOneForm.amount.replace(/[,.]/g, "")),
        minPrice: parseInt(stepOneForm.minPrice.replace(/[,.]/g, "")),
        maxPrice: parseInt(stepOneForm.maxPrice.replace(/[,.]/g, "")),
      };

      onNext(offerData);
    }
  };

  return (
    <div className="step-one-container">
      <div className="step-one-top">
        <div className="title-box">
          <WhiteButton
            icon={<i className="fa-solid fa-chevron-left"></i>}
            text={"Geri"}
            onClick={() => navigate(-1)}
          />

          <div className="title">
            <i className="fa-solid fa-plus"></i>
            <h4>İhale Oluştur</h4>
          </div>
        </div>

        <WhiteButton
          className={`${step !== 1 ? "display-none" : ""}`}
          text={<i className="fa-solid fa-chevron-right"></i>}
          icon={step === 1 ? "İleri" : ""}
          onClick={handleNext}
          disabled={step !== 1}
        />
      </div>

      <div className="step-one-form-container">
        <div className="input-standart">
          <label htmlFor="category">* Kategori : </label>
          <Select
            required
            isMulti
            options={categoryOptions}
            value={selectedCategories}
            onChange={handleChangeCategory}
            name="category"
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Kategori..."
            menuPlacement="bottom"
            menuPosition="fixed"
            menuShouldScrollIntoView={true}
            styles={{
              menu: (base) => ({
                ...base,
                height: "300px",
                border: stepOneFormErrors.categoryIdError
                  ? "2px solid red"
                  : "",
              }),
            }}
          />
        </div>

        <InputStandart
          required={true}
          errorBorder={stepOneFormErrors.productNameError}
          type={"text"}
          title={"Ürün/Hizmet"}
          name={"productName"}
          placeholder={"Ürün/Hizmet..."}
          onChange={handleChange}
          value={stepOneForm.productName}
        />

        <div className="input-standart">
          <label htmlFor={"description"} style={{ textIndent: "7px" }}>
            Açıklama :{" "}
          </label>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="2"
            placeholder={"Açıklama..."}
            onChange={handleChange}
            value={stepOneForm.description}
          />
        </div>

        <div className="input-two">
          <div className="input-standart">
            <label htmlFor={"amount"}>{"* Miktar"} : </label>
            <div className="input-select-box">
              <input
                required
                style={{
                  border: stepOneFormErrors.amountError ? "2px solid red" : "",
                }}
                type={"number"}
                title={"Miktar"}
                name={"amount"}
                placeholder={"Miktar..."}
                onChange={handleChange}
                value={stepOneForm.amount}
              />

              <select
                name="unitType"
                style={{
                  border: stepOneFormErrors.unitTypeError
                    ? "2px solid red"
                    : "",
                }}
                value={stepOneForm.unitType}
                onChange={handleChange}
              >
                {unitTypeOptions.map((unitType) => {
                  return (
                    <option key={unitType.value} value={unitType.value}>
                      {unitType.label}
                    </option>
                  );
                })}
              </select>
              {/* <Select
                options={unitTypeOptions}
                value={selectedUnitType}
                onChange={handleChangeUnitType}
                name="unitType"
                className="basic-multi-select"
                classNamePrefix="select"
                // placeholder="Unit Type..."
              /> */}
            </div>
          </div>
        </div>

        <div className="input-standart">
          <label>* Para birimi: </label>
          <select
            required
            value={currency}
            onChange={handleCurrencyChange}
            style={{
              border: stepOneFormErrors.currencyError ? "2px solid red" : "",
            }}
          >
            <option value="TRY">TRY</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>

        <InputPrice
          required={true}
          errorBorder={stepOneFormErrors.minPriceError}
          type={"text"}
          title={"Min.Bütçe"}
          name={"minPrice"}
          placeholder={"Min.Bütçe..."}
          currencySymbol={currencySymbol}
          onChange={handleChange}
          value={stepOneForm.minPrice}
        />

        <InputPrice
          required={true}
          errorBorder={stepOneFormErrors.maxPriceError}
          type={"text"}
          title={"Max.Bütçe"}
          name={"maxPrice"}
          placeholder={"Max.Bütçe..."}
          currencySymbol={currencySymbol}
          onChange={handleChange}
          value={stepOneForm.maxPrice}
        />

        <InputStandart
          required={true}
          errorBorder={stepOneFormErrors.expireDateError}
          type={"date"}
          title={"Bitiş Tarihi"}
          name={"expireDate"}
          placeholder={"Bitiş Tarihi..."}
          onChange={handleChange}
          value={stepOneForm.expireDate}
        />

        <InputStandart
          required={true}
          errorBorder={stepOneFormErrors.deadlineError}
          type={"date"}
          title={"Son Teslim Tarihi"}
          name={"deadline"}
          placeholder={"Son Teslim Tarihi..."}
          onChange={handleChange}
          value={stepOneForm.deadline}
        />

        <div className="input-standart documan">
          <label style={{ textIndent: "7px" }}>Fotoğraf : </label>
          <UploadImageBox
            images={images}
            setImages={setImages}
            formData={stepOneForm}
            setFormData={setStepOneForm}
          />
        </div>

        <div className="input-standart documan">
          <label style={{ textIndent: "7px" }}>Dökümanlar : </label>
          <DocumentUploader
            documents={documents}
            setDocuments={setDocuments}
            formData={stepOneForm}
            setFormData={setStepOneForm}
          />
        </div>

        <InputStandart
          labelClass="note-indent"
          type={"text"}
          title={"Not"}
          name={"note"}
          placeholder={"Not..."}
          onChange={handleChange}
          value={stepOneForm.note}
        />
      </div>
    </div>
  );
}

export default TenderAddStepOne;
