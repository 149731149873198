import "./sidebar.css";

import { Link, useLocation } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import DashboardIcon from "../Icons/DashboardIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import StarIcon from "../Icons/StarIcon";
import BuildingIcon from "../Icons/BuildingIcon";
import TicketIcon from "../Icons/TicketIcon";
import FactoryIcon from "../Icons/FactoryIcon";
import ReportIcon from "../Icons/ReportIcon";
import UsersIcon from "../Icons/UsersIcon";
import SettingIcon from "../Icons/SettingIcon";
import images from "../../assets/images/index";

import { AuthContextValue } from "../../context/AuthContext";
import { getFetch } from "../../hooks/getFetch";
import { useEffect, useState } from "react";
import { GetContextValue } from "../../context/GetContext";
import { Tooltip } from "antd";

function Sidebar({ t, openSideBar }) {
  const { pathname } = useLocation();
  const { userRole, loginUserCompanyId, token } = AuthContextValue();
  const { unReadCount } = GetContextValue();

  const [companyType, setCompanyType] = useState();

  // Aktif linkin kontrolü için bir fonksiyon oluşturun
  const isActiveLink = (link) => {
    const firstPath = pathname.split("/")[1]; // İlk rota

    return firstPath === link.split("/")[1];
  };

  useEffect(() => {
    async function getCompDetail() {
      await getFetch(
        `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${loginUserCompanyId}`,
        token
      ).then((response) =>response.data.data ? setCompanyType(response.data.data[0].companyType) : null);
    }

    if (loginUserCompanyId) {
      getCompDetail();
    }
  }, [loginUserCompanyId]);

  return (
    <div className="sidebar-container">
      <ul>
        <li>
          <Tooltip
            placement="top"
            title={openSideBar ? t("sidebar_homepage") : ""}
          >
            <Link to="/" className={isActiveLink("/") ? "active-link" : ""}>
              <DashboardIcon
                className={isActiveLink("/") ? "active-link" : ""}
              />
              {!openSideBar && t("sidebar_homepage")}
            </Link>
          </Tooltip>
        </li>

        <li className="notification-li">
          <Tooltip
            placement="top"
            title={openSideBar ? t("sidebar_notifications") : ""}
          >
            <Link
              to="/notifications"
              className={isActiveLink("/notifications") ? "active-link" : ""}
            >
              <NotificationIcon
                className={isActiveLink("/notifications") ? "active-link" : ""}
              />

              {!openSideBar && t("sidebar_notifications")}
              {unReadCount && (
                <span className="notification-badge">{unReadCount}</span>
              )}
            </Link>
          </Tooltip>
        </li>

        <hr />

        {userRole === "Admin" && (
          <>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? "Reklam Paneli" : ""}
              >
                <Link
                  to="/banner-panel"
                  className={isActiveLink("/banner-panel") ? "active-link" : ""}
                >
                  <i className="fa-regular fa-images"></i>
                  {!openSideBar && "Reklam Paneli"}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? "Destek Talep" : ""}
              >
                <Link
                  to="/support-request"
                  className={
                    isActiveLink("/support-request") ? "active-link" : ""
                  }
                >
                  <i className="fa-solid fa-headset"></i>
                  {!openSideBar && "Destek Talep"}
                </Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? "Firma Listesi" : ""}
              >
                <Link
                  to="/company-list"
                  className={isActiveLink("/company-list") ? "active-link" : ""}
                >
                  {/* <StarIcon
                    className={
                      isActiveLink("/company-list") ? "active-link" : ""
                    }
                  /> */}
                  <i className="fa-solid fa-table-list"></i>
                  {!openSideBar && "Firma Listesi"}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={openSideBar ? "Başvuranlar" : ""}>
                <Link
                  to="/companies-applying"
                  className={
                    isActiveLink("/companies-applying") ? "active-link" : ""
                  }
                >
                  {/* <StarIcon
                    className={
                      isActiveLink("/companies-applying") ? "active-link" : ""
                    }
                  /> */}
                  <i className="fa-solid fa-house-circle-check"></i>
                  {!openSideBar && "Başvuranlar"}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={openSideBar ? "İhaleler" : ""}>
                <Link
                  to="/all-tender"
                  className={isActiveLink("/all-tender") ? "active-link" : ""}
                >
                  <TicketIcon
                    className={isActiveLink("/all-tender") ? "active-link" : ""}
                  />
                  {!openSideBar && "İhaleler"}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={openSideBar ? "Teklifler" : ""}>
                <Link
                  to="/all-offer"
                  className={isActiveLink("/all-offer") ? "active-link" : ""}
                >
                  <i
                    className={`fa-solid fa-certificate ${
                      isActiveLink("/offers") ? "active-link" : ""
                    }`}
                  />
                  {!openSideBar && "Teklifler"}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        {(companyType === 2 || companyType === 3) && (
          <>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? t("sidebar_opportunities") : ""}
              >
                <Link
                  to="/opportunities"
                  className={
                    isActiveLink("/opportunities") ? "active-link" : ""
                  }
                >
                  <StarIcon
                    className={
                      isActiveLink("/opportunities") ? "active-link" : ""
                    }
                  />
                  {!openSideBar && t("sidebar_opportunities")}
                </Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip placement="top" title={openSideBar ? "Tekliflerim" : ""}>
                <Link
                  to="/offers"
                  className={isActiveLink("/offers") ? "active-link" : ""}
                >
                  {/* <StarIcon
                    className={isActiveLink("/offers") ? "active-link" : ""}
                  /> */}
                  <i
                    className={`fa-solid fa-certificate ${
                      isActiveLink("/offers") ? "active-link" : ""
                    }`}
                  />
                  {!openSideBar && "Tekliflerim"}
                </Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? t("sidebar_main_companies") : ""}
              >
                <Link
                  to="/maincompanies"
                  className={
                    isActiveLink("/maincompanies") ? "active-link" : ""
                  }
                >
                  <BuildingIcon
                    className={
                      isActiveLink("/maincompanies") ? "active-link" : ""
                    }
                  />
                  {!openSideBar && t("sidebar_main_companies")}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        {(companyType === 1 || companyType === 3) && userRole !== "Admin" && (
          <>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? t("sidebar_tenders") : ""}
              >
                <Link
                  to="/tenders"
                  className={isActiveLink("/tenders") ? "active-link" : ""}
                >
                  <TicketIcon
                    className={isActiveLink("/tenders") ? "active-link" : ""}
                  />
                  {!openSideBar && t("sidebar_tenders")}
                </Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                placement="top"
                title={openSideBar ? t("sidebar_suppliers") : ""}
              >
                <Link
                  to="/suppliers"
                  className={isActiveLink("/suppliers") ? "active-link" : ""}
                >
                  <FactoryIcon
                    className={isActiveLink("/suppliers") ? "active-link" : ""}
                  />
                  {!openSideBar && t("sidebar_suppliers")}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        <hr />

        <li>
          <Tooltip
            placement="top"
            title={openSideBar ? t("sidebar_reports") : ""}
          >
            <Link
              to="/reports"
              className={isActiveLink("/reports") ? "active-link" : ""}
            >
              <ReportIcon
                className={isActiveLink("/reports") ? "active-link" : ""}
              />
              {!openSideBar && t("sidebar_reports")}
            </Link>
          </Tooltip>
        </li>
        <li>
          <Tooltip
            placement="top"
            title={openSideBar ? t("sidebar_users") : ""}
          >
            <Link
              to="/users"
              className={isActiveLink("/users") ? "active-link" : ""}
            >
              <UsersIcon
                className={isActiveLink("/users") ? "active-link" : ""}
              />
              {!openSideBar && t("sidebar_users")}
            </Link>
          </Tooltip>
        </li>

        {/* <li>
            <Link to="/company-profile-edit" className={isActiveLink("/") ? "active-link" : ""}>
              <UsersIcon className={isActiveLink("/") ? "active-link" : ""} />
              {!openSideBar && "Firma Profili"}
            </Link>
          </li> */}
        {userRole === "Manager" && (
          <li>
            <Tooltip placement="top" title={openSideBar ? "Firma Profili" : ""}>
              <Link
                to={`/company-list/company-profile/${loginUserCompanyId}`}
                className={
                  isActiveLink(
                    `/company-list/company-profile/${loginUserCompanyId}`
                  )
                    ? "active-link"
                    : ""
                }
              >
                {/* <UsersIcon
                  className={
                    isActiveLink(
                      `/company-list/company-profile/${loginUserCompanyId}`
                    )
                      ? "active-link"
                      : ""
                  }
                /> */}
                <i
                  className={
                    isActiveLink(
                      `/company-list/company-profile/${loginUserCompanyId}`
                    )
                      ? "active-link  fa-solid fa-warehouse"
                      : "fa-solid fa-warehouse"
                  }
                ></i>
                {!openSideBar && "Firma Profili"}
              </Link>
            </Tooltip>
          </li>
        )}

        <hr />

        {userRole !== "Admin" && (
          <li>
            <Tooltip placement="top" title={openSideBar ? "Reklam Ver" : ""}>
              <Link
                to="/banner-company"
                className={isActiveLink("/banner-company") ? "active-link" : ""}
              >
                <i className="fa-regular fa-images"></i>
                {!openSideBar && "Reklam Ver"}
              </Link>
            </Tooltip>
          </li>
        )}
        {userRole !== "Admin" && (
          <li>
            <Tooltip placement="top" title={openSideBar ? "Destek Talep" : ""}>
              <Link
                to="/support-request"
                className={
                  isActiveLink("/support-request") ? "active-link" : ""
                }
              >
                <i className="fa-solid fa-headset"></i>
                {!openSideBar && "Destek Talep"}
              </Link>
            </Tooltip>
          </li>
        )}

        <li>
          <Tooltip placement="top" title={openSideBar ? "Nasıl Çalışır ?" : ""}>
            <Link
              to="/how-it-work"
              className={isActiveLink("/how-it-work") ? "active-link" : ""}
            >
              <i
                className={
                  isActiveLink(
                    `/company-list/company-profile/${loginUserCompanyId}`
                  )
                    ? "active-link  fa-solid fa-question"
                    : "fa-solid fa-question"
                }
              ></i>
              {!openSideBar && "Nasıl Çalışır"}
            </Link>
          </Tooltip>
        </li>

        <li>
          <Tooltip
            placement="top"
            title={openSideBar ? t("sidebar_settings") : ""}
          >
            <Link
              to="/settings"
              className={isActiveLink("/settings") ? "active-link" : ""}
            >
              <SettingIcon
                className={isActiveLink("/settings") ? "active-link" : ""}
              />
              {!openSideBar && t("sidebar_settings")}
            </Link>
          </Tooltip>
        </li>
      </ul>

      <div className="sidebar-logo">
        <img src={images.companyLogo} alt="company-logo" />
      </div>
    </div>
  );
}

export default withNamespaces()(Sidebar);
