import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import Loading from "../../components/Loading/Loading";
import useFetch from "../../hooks/UseFetch";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function RevisedOpportunities({
  t,
  tableFilter,
  searchText,
  tableRender,
  notificationRender,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    // notificationRender,
  } = GetContextValue();

  const [revisedOpportunitiesPage, setRevisedOpportunitiesPage] = useState(1);
  const [revisedOpportunitiesRowsPerPage, setRevisedOpportunitiesRowsPerPage] =
    useState(10);

  const opportunitiesStatus = 3;

  const {
    setUseFetchRender: setRevisedOpportunitiesUseFetchRender,
    useFetchRender: revisedOpportunitiesUseFetchRender,
    error: revisedOpportunitiesError,
    loading: revisedOpportunitiesLoading,
    data: revisedOpportunities,
    total: revisedOpportunitiesTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_SUPPLIER_LIST_URL}?SortType=${tableFilter}&Status=${opportunitiesStatus}&CompanyId=${loginUserCompanyId}&Page=${revisedOpportunitiesPage}&PageSize=${revisedOpportunitiesRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: "Foto",
    },
    {
      key: "result",
      header: "Durum",
    },
    {
      key: "companyName",
      header: "Yayınlayan",
    },
    {
      key: "categories",
      header: "Kategori",
    },
    {
      key: "productName",
      header: "Ürün/Hizmet",
    },
    {
      key: "amount",
      header: "Miktar",
    },
    {
      key: "distance",
      header: "Konum",
    },
    {
      key: "duration",
      header: "Mesafe",
    },
    {
      key: "createdAt",
      header: "Tarih",
    },
    {
      key: "remainingTime",
      header: "Kalan Süre",
    },
  ];

  useEffect(() => {
    setRevisedOpportunitiesUseFetchRender(!revisedOpportunitiesUseFetchRender);
  }, [tableRender, notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }
  console.log(revisedOpportunities);
  return (
    <>
      {revisedOpportunitiesError ? (
        <ErrorComponent error={revisedOpportunitiesError} />
      ) : revisedOpportunitiesLoading ? (
        <Loading />
      ) : revisedOpportunities !== null && revisedOpportunities.length > 0 ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={revisedOpportunitiesUseFetchRender}
          setUseFetchRender={setRevisedOpportunitiesUseFetchRender}
          total={revisedOpportunitiesTotal}
          page={revisedOpportunitiesPage}
          setPage={setRevisedOpportunitiesPage}
          rowsPerPage={revisedOpportunitiesRowsPerPage}
          setRowsPerPage={setRevisedOpportunitiesRowsPerPage}
          columns={columns}
          data={revisedOpportunities}
        />
      ) : (
        <div className="table-null-text">{t("message_no_opportunities")}</div>
      )}
    </>
  );
}

export default withNamespaces()(RevisedOpportunities);
