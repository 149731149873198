import "./carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useRef } from "react";
import Slider from "react-slick";
import ModalCarousel from "../Modals/ModalCarousel/ModalCarousel";

// const images = [
//   "https://picsum.photos/id/1018/400/300",
//   "https://picsum.photos/id/1025/400/300",
//   "https://picsum.photos/id/1035/400/300",
//   "https://picsum.photos/id/1045/400/300",
//   "https://picsum.photos/id/1055/400/300",
// ];

const Carousel = ({ images }) => {
  const [modalCarouselImageIndex, setModalCarouselImageIndex] = useState(0);

  const [openModalCarousel, setOpenModalCarousel] = useState(false);
  const handleOpenModalCarousel = () => setOpenModalCarousel(true);
  const handleCloseModalCarousel = () => setOpenModalCarousel(false);

  const handleNext = () => {
    if (modalCarouselImageIndex < images.length - 1) {
      setModalCarouselImageIndex(modalCarouselImageIndex + 1);
    } else {
      setModalCarouselImageIndex(0);
    }
  };

  const handlePrev = () => {
    if (modalCarouselImageIndex > 0) {
      setModalCarouselImageIndex(modalCarouselImageIndex - 1);
    } else {
      setModalCarouselImageIndex(images.length - 1);
    }
  };

  const [firstImage, setFirstImage] = useState(0);
  const [secondImage, setSecondImage] = useState(1);

  function handleClikNext() {
    if (secondImage < images.length - 1) {
      setFirstImage(firstImage + 1);
      setSecondImage(secondImage + 1);
    }
  }

  function handleClikPrev() {
    if (firstImage > 0) {
      setFirstImage(firstImage - 1);
      setSecondImage(secondImage - 1);
    }
  }

  function handleClickImage(clickImageIndex) {
    setModalCarouselImageIndex(clickImageIndex);

    handleOpenModalCarousel();
  }

  return (
    <div className="offer-detail-card-images-container">
      {images.length <= 1 ? (
        <div className="slider-image-box">
          <img
            className="slider-img"
            src={
              images[firstImage].includes("http")
                ? images[firstImage]
                : `http://sosocrm.oetech.online/api/Images/images/${images[firstImage]}`
            }
            alt="offer-img"
            onClick={() => handleClickImage(firstImage)}
          />
        </div>
      ) : (
        <>
          <div className="slider-image-box">
            <img
              className="slider-img"
              src={
                images[firstImage].includes("http")
                  ? images[firstImage]
                  : `http://sosocrm.oetech.online/api/Images/images/${images[firstImage]}`
              }
              alt="offer-img"
              onClick={() => handleClickImage(firstImage)}
            />
          </div>

          <div className="slider-image-box">
            <img
              className="slider-img"
              src={
                images[secondImage].includes("http")
                  ? images[secondImage]
                  : `http://sosocrm.oetech.online/api/Images/images/${images[secondImage]}`
              }
              alt="offer-img"
              onClick={() => handleClickImage(secondImage)}
            />
          </div>
          <button onClick={handleClikNext} className="next-btn">
            {" "}
            {">"}{" "}
          </button>
          <button onClick={handleClikPrev} className="prev-btn">
            {" "}
            {"<"}{" "}
          </button>
        </>
      )}
      {openModalCarousel && (
        <ModalCarousel
          images={images}
          openModalCarousel={openModalCarousel}
          handleCloseModalCarousel={handleCloseModalCarousel}
        />
      )}
    </div>
  );
};

export default Carousel;
// {openModalCarousel && (
//   <div className={`images-detail-container`}>
//     <div className="images-detail-main">
//       <div
//         className="images-detail-close"
//         onClick={handleCloseModalCarousel}
//       >
//         X
//       </div>

//       <div className="modal-image-box">
//         <img
//           src={
//             images[modalCarouselImageIndex].includes("http")
//               ? images[modalCarouselImageIndex]
//               : `http://sosocrm.oetech.online/api/Images/images/${images[modalCarouselImageIndex]}`
//           }
//           alt="offer-img"
//           className="modal-img"
//         />
//       </div>

//       <button className="images-detail-button-prev" onClick={handlePrev}>
//         <i className="fa-solid fa-caret-left"></i>
//       </button>
//       <button className="images-detail-button-next" onClick={handleNext}>
//         <i className="fa-solid fa-caret-right"></i>
//       </button>
//     </div>
//   </div>
// )}
