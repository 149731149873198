import { Button, Modal } from "antd";
import "./styles/supportRequest/supportRequest.css";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { useState } from "react";
import Pagination from "../Table/Pagination/Pagination";
import SupportEditBox from "../EditBox/SupportEditBox";
import SupportRequestForm from "./SupportRequestForm";

function SupportRequest({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  setSupportRequestUseFetchRender,
  supportRequestUseFetchRender,
  supportRequest,
  supportRequestError,
  supportRequestLoading,
  tableFilter,
  setTableFilter,
  searchText,
  setSearchText,
}) {
  const [photo, setPhoto] = useState(null);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  const [addSupportModal, setAddSupportModal] = useState(false);
  const handleOpenAddSuportModal = () => setAddSupportModal(true);
  const handleCloseAddSuportModal = () => setAddSupportModal(false);

  const handleOpenPhotoModal = (photo) => {
    setPhoto(photo);
    setPhotoModalOpen(true);
  };
  const handleClosePhotoModal = () => {
    setPhotoModalOpen(false);
  };

  return (
    <div className="support-request-container">
      <div className="support-edit-box">
        <SupportEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          useFetchRender={supportRequestUseFetchRender}
          setUseFetchRender={setSupportRequestUseFetchRender}
          handleOpenAddSuportModal={handleOpenAddSuportModal}
        />
      </div>

      <div className="support-request-main">
        {supportRequestError ? (
          <ErrorComponent error={supportRequestError} />
        ) : supportRequestLoading ? (
          <LoadingCircular />
        ) : supportRequest !== null && supportRequest.length > 0 ? (
          supportRequest.map((ticket, index) => {
            const status =
              ticket.status === 1
                ? "Bekliyor"
                : ticket.status === 2
                ? "İnceleniyor"
                : ticket.status === 3
                ? "Çözüldü"
                : ticket.status === 4
                ? "Çözülemedi"
                : ticket.status === 5
                ? "Sonuçlandı"
                : 1;
            const color =
              ticket.status === 1
                ? "#fff1b8"
                : ticket.status === 2
                ? "#ffd8bf"
                : ticket.status === 3
                ? "#d9f7be"
                : ticket.status === 4
                ? "#ffccc7"
                : ticket.status === 5
                ? "#b5f5ec"
                : 1;

            return (
              <div
                className="ticket-box"
                key={index}
                style={{ backgroundColor: color }}
              >
                <h3>
                  {" "}
                  <strong>Talep Başlık :</strong> {ticket.title}
                  <span className="date">{ticket.createdDate}</span>
                </h3>

                <hr />

                <p>
                  {" "}
                  <strong>Talep No :</strong> {ticket.supportNumber}
                </p>
                <p>
                  {" "}
                  <strong>Talep Açıklama :</strong> {ticket.body}
                </p>

                <p>
                  <strong>Talep Fotoğraf :</strong>{" "}
                  <Button
                    onClick={() => handleOpenPhotoModal(ticket.images[0])}
                  >
                    Fotoğrafı göster
                  </Button>
                </p>

                <p>
                  <strong>Durum : </strong> {status}
                </p>

                {ticket.description && (
                  <div className="admin-reply">
                    <span className="date">{ticket.approvedDate}</span>
                    <h4>Admin Cevabı:</h4>
                    <p>{ticket.description}</p>
                  </div>
                )}
              </div>
            );
          })
        ) : null}
        {photoModalOpen && (
          <div
            className="photo-modal-container"
            onClick={handleClosePhotoModal}
          >
            <div className="photo-box">
              <button className="close-button" onClick={handleClosePhotoModal}>
                x
              </button>
              <img className="ticket-image" src={photo} alt="Ticket" />
            </div>
          </div>
        )}
      </div>

      <div className="pagination">
        <Pagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={total}
        />
      </div>

      <Modal
        title="Talep Oluştur"
        style={{ top: 20 }}
        open={addSupportModal}
        onCancel={handleCloseAddSuportModal}
        footer={null}
      >
        <SupportRequestForm
          setSupportRequestUseFetchRender={setSupportRequestUseFetchRender}
          supportRequestUseFetchRender={supportRequestUseFetchRender}
          handleCloseModal={handleCloseAddSuportModal}
        />
      </Modal>
    </div>
  );
}

export default SupportRequest;
