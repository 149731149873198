import "./styles/companyProfile/companyProfile.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import CompanyProfileEditBox from "../../components/EditBox/CompanyProfileEditBox";
import useFetch from "../../hooks/UseFetch";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import CompanyTenders from "./CompanyTenders";
import CompanyOffers from "./CompanyOffers";
import Loading from "../../components/Loading/Loading";
import CompanyDetailCard from "../../components/CompanyDetailCard/CompanyDetailCard";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import CompanyUsers from "./CompanyUsers";
import { GetContextValue } from "../../context/GetContext";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import MainCompanyTenders from "./MainCompanyTenders";
import SupplierOffers from "./SupplierOffers";

function CompanyProfile() {
  const { companyId } = useParams();

  const { token, userRole, loginUserCompanyId } = AuthContextValue();
  const { deleteMethod, createMethod } = CrudContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    companyUsersIds,
    notificationRender,
  } = GetContextValue();

  const [pageLink, setPageLink] = useState("");

  const [tableRender, setTableRender] = useState(false);

  const [deleteCompanyLoading, setDeleteCompanyLoading] = useState(false);

  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] = useState(false);
  const handleOpenDeleteCompanyModal = () => setOpenDeleteCompanyModal(true);
  const handleCloseDeleteCompanyModal = () => setOpenDeleteCompanyModal(false);

  const {
    useFetchRender: companyDetailUseFetchRender,
    setUseFetchRender: setCompanyDetailUseFetchRender,
    error: companyDetailError,
    loading: companyDetailLoading,
    data: companyDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${companyId}&Page=1&PageSize=10`,
    token
  );

  useEffect(() => {
    if (companyDetail && companyDetail[0]) {
      if (loginUserCompanyId === companyId || userRole === "Admin") {
        setPageLink(
          companyDetail[0]?.companyType === 1 ||
            companyDetail[0]?.companyType === 3
            ? "tenders"
            : "offers"
        );
      } else {
        if (companyDetail[0].companyType === 1) {
          setPageLink("main-company-tenders");
        } else if (companyDetail[0].companyType === 2) {
          setPageLink("supplier-offers");
        } else {
          setPageLink("main-company-tenders");
        }
      }
    }
  }, [companyDetail]);

  useEffect(() => {
    setCompanyDetailUseFetchRender(!companyDetailUseFetchRender);
  }, [companyId, notificationRender]);

  function hanldeClickLink(link) {
    setPageLink(link);
  }

  async function handleDeleteCompany() {
    console.log(companyId, " companyId firma silindi!");
    setDeleteCompanyLoading(true);

    await deleteMethod(
      `${process.env.REACT_APP_DELETE_COMPANY_URL}?id=${companyId}`,
      token
    );

    setDeleteCompanyLoading(false);

    handleCloseDeleteCompanyModal();

    // if (
    //   responseDeleteCompany &&
    //   !responseDeleteCompany.cathError &&
    //   !responseDeleteCompany.isError
    // ) {
    //   navigate(-1);
    // }
  }

  return (
    <div className="company-detail-container">
      <div className="company-detail-container-top">
        {openDeleteCompanyModal ? (
          <ConfirmModal
            text={`${companyDetail[0]?.companyName} adlı firmayı silmek istediğinize emin misiniz?`}
            cancelButtonText={"Vazgeç"}
            confirmButtonText={"Sil"}
            handleCloseModal={handleCloseDeleteCompanyModal}
            confirmFunc={handleDeleteCompany}
          />
        ) : (
          <CompanyProfileEditBox
            title={"Firma Profili"}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            useFetchRender={companyDetailUseFetchRender}
            setUseFetchRender={setCompanyDetailUseFetchRender}
            handleOpenDeleteCompanyModal={handleOpenDeleteCompanyModal}
          />
        )}
      </div>
      <div className="company-detail-container-main">
        <div className="left-container">
          {companyDetailError ? (
            <ErrorComponent error={companyDetailError} />
          ) : companyDetailLoading ? (
            <Loading />
          ) : (
            companyDetail !== null && (
              <CompanyDetailCard companyDetail={companyDetail[0]} />
            )
          )}
        </div>
        <div className="right-container">
          <div className="right-container-top">
            <div className="right-container-page-link">
              {companyId === loginUserCompanyId || userRole === "Admin" ? (
                <>
                  {companyDetail && (companyDetail[0]?.companyType === 1 ||
                    companyDetail[0]?.companyType === 3) && (
                    <button
                      onClick={() => hanldeClickLink("tenders")}
                      className={
                        pageLink === "tenders"
                          ? "link-active"
                          : "link-not-active"
                      }
                    >
                      İhaleler
                    </button>
                  )}
                  {companyDetail && (companyDetail[0]?.companyType === 2 ||
                    companyDetail[0]?.companyType === 3) && (
                    <button
                      onClick={() => hanldeClickLink("offers")}
                      className={
                        pageLink === "offers"
                          ? "link-active"
                          : "link-not-active"
                      }
                    >
                      Teklifler
                    </button>
                  )}

                  {(userRole === "Admin" || userRole === "Manager") && (
                    <button
                      onClick={() => hanldeClickLink("users")}
                      className={
                        pageLink === "users" ? "link-active" : "link-not-active"
                      }
                    >
                      Kişiler
                    </button>
                  )}
                </>
              ) : (
                <>
                  {(companyDetail[0]?.companyType === 1 ||
                    companyDetail[0]?.companyType === 3) && (
                    <button
                      onClick={() => hanldeClickLink("main-company-tenders")}
                      className={
                        pageLink === "main-company-tenders"
                          ? "link-active"
                          : "link-not-active"
                      }
                    >
                      İhaleler
                    </button>
                  )}

                  {(companyDetail[0]?.companyType === 2 ||
                    companyDetail[0]?.companyType === 3) && (
                    <button
                      onClick={() => hanldeClickLink("supplier-offers")}
                      className={
                        pageLink === "supplier-offers"
                          ? "link-active"
                          : "link-not-active"
                      }
                    >
                      Teklifler
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="right-container-edit-buttons">
              <WhiteButton
                icon={<i className="fa-solid fa-arrow-rotate-right" />}
                tooltip={"Yenile"}
                onClick={() => setTableRender(!tableRender)}
              />
              <Link
                to={
                  (selectValueId || selectValue) && pageLink === "tenders"
                    ? `/tenders/tender-detail/${selectValueId}`
                    : pageLink === "offers"
                    ? `/offers/offer-detail/${selectValueId}`
                    : pageLink === "users"
                    ? `/users/user-profile/${selectValue?.userId}`
                    : pageLink === "main-company-tenders"
                    ? `/opportunities/tender-detail-supplier/${selectValueId}`
                    : pageLink === "supplier-offers"
                    ? `/tenders/tender-offer-detail/${selectValue?.offerId}`
                    : false
                }
              >
                <WhiteButton
                  icon={<i className="fa-solid fa-info"></i>}
                  tooltip={"Detay"}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </Link>
            </div>
          </div>

          <div className="right-container-table">
            {pageLink === "tenders" ? (
              <CompanyTenders id={companyId} tableRender={tableRender} />
            ) : pageLink === "offers" ? (
              <CompanyOffers id={companyId} tableRender={tableRender} />
            ) : pageLink === "users" ? (
              <CompanyUsers id={companyId} tableRender={tableRender} />
            ) : pageLink === "main-company-tenders" ? (
              <MainCompanyTenders id={companyId} tableRender={tableRender} />
            ) : pageLink === "supplier-offers" ? (
              <SupplierOffers id={companyId} tableRender={tableRender} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
