import "../style/detailCardStyle/detailCardStyle.css";

import React from "react";
import { Link } from "react-router-dom";
import { GetContextValue } from "../../../context/GetContext";
import Loading from "../../Loading/Loading";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";

function AdminsCard() {
  const { loginUserCompanyId, token } = AuthContextValue();

  /* USERS USEFETCH */
  const {
    setUseFetchRender: setUsersUseFetchRender,
    useFetchRender: usersUseFetchRender,
    error: usersError,
    loading: usersLoading,
    data: users,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?CompanyId=${loginUserCompanyId}&Page=1&PageSize=5`,
    token
  );

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-users"></i>
          <h5>Adminler</h5>
        </div>
        <div className="card-buttons">
          <Link to={"/users"}>
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={"Detay"}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {usersError ? (
            <ErrorComponent error={usersError} />
          ) : usersLoading ? (
            <Loading />
          ) : users !== null && users.length > 0 ? (
            users.map((user) => {
              return (
                <li key={user.userId}>
                  <p>
                    {user.firstName} {user.lastName}
                  </p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">Kullanıcı Yok!</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default AdminsCard;
