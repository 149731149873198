import "./mediumTable.css";
import React from "react";

function formatDuration(duration) {
  if (duration < 0) {
    return "Bitti";
  }

  const days = Math.floor(duration / (24 * 60 * 60 * 1000));
  duration -= days * 24 * 60 * 60 * 1000;

  const hours = Math.floor(duration / (60 * 60 * 1000));
  duration -= hours * 60 * 60 * 1000;

  const minutes = Math.floor(duration / (60 * 1000));
  duration -= minutes * 60 * 1000;

  if (days === 0) {
    if (hours === 0) {
      return `${minutes} dk`;
    } else {
      return `${hours} saat`;
    }
  }

  return `${days} gün`;
}

function statusText(statusParam) {
  return (
    <code
      style={{
        color:
          statusParam === "3" || statusParam === "4"
            ? "red"
            : statusParam === "1"
            ? "orange"
            : statusParam === "2"
            ? "green"
            : "",
      }}
    >
      {statusParam === "2" ? (
        <i className="fa-solid fa-check" />
      ) : statusParam === "3" ? (
        <i className="fa-solid fa-arrow-rotate-left" />
      ) : statusParam === "1" ? (
        <i className="fa-solid fa-clock" />
      ) : statusParam === "4" ? (
        <i className="fa-solid fa-circle-exclamation"></i>
      ) : null}{" "}
      {statusParam === "3"
        ? "Revize İstendi"
        : statusParam === "1"
        ? "Bekliyor"
        : statusParam === "2"
        ? "Onaylandı"
        : statusParam === "4"
        ? "Reddedildi"
        : ""}
    </code>
  );
}

function MediumTable({
  type,

  selectValueId,
  selectValue,
  handleClickValue,

  columns,
  data,
}) {
  return (
    <div className="medium-table-box">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              {columns.map((column, i) => {
                return (
                  <th key={i} title={column.header}>
                    {column.header}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              // verilen type değerine göre tabloya id ataması
              const id =
                type === "opportunities" || type === "tender"
                  ? row.tenderId
                  : type === "offer" || type === "waiting"
                  ? row.offerId
                  : row.id;

              const productName =
                row && row.productName
                  ? row.productName
                  : row.tenderDetail && row.tenderDetail[0].productName;

              const remainingTime =
                row &&
                row.tenderDetail &&
                new Date(row.tenderDetail[0].expireDate) - Date.now();

              const opportunitiesRemainingTime =
                row.expireDate &&
                new Date(new Date(row.expireDate) - Date.now());

              const passedDayOffer =
                type === "opportunities" || type === "tender"
                  ? formatDuration(Date.now() - new Date(row.createdAt))
                  : type === "offer" || type === "waiting"
                  ? formatDuration(Date.now() - new Date(row.offerCreatedAt))
                  : "null";

              return (
                <tr
                  key={id}
                  onClick={() => handleClickValue(id, row)}
                  className={id === selectValueId ? "table-row-select" : ""}
                >
                  {columns.map((column, i) => {
                    return (
                      <td
                        key={i}
                        className={
                          id === selectValueId ? "table-row-select-text" : ""
                        }
                        title={row[column.key]}
                      >
                        {column.key === "productName" ? (
                          productName
                        ) : column.key === "remainingTime" ? (
                          formatDuration(
                            type === "opportunities" || type === "tender"
                              ? opportunitiesRemainingTime
                              : remainingTime
                          )
                        ) : column.key === "result" ||
                          column.key === "status" ? (
                          statusText(row[column.key])
                        ) : column.key === "passedDay" ? (
                          passedDayOffer
                        ) : column.key === "minPrice" ||
                          column.key === "maxPrice" ||
                          column.key === "totalPrice" ||
                          column.key === "total" ? (
                          <code>
                            {row["currency"] && row["currency"] !== "string"
                              ? Number(row[column.key]).toLocaleString(
                                  row["currency"],
                                  {
                                    style: "currency",
                                    currency: row["currency"],
                                  }
                                )
                              : Number(row[column.key]).toLocaleString()}
                          </code>
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MediumTable;
