import "./errorComponent.css";

const ErrorComponent = ({ error }) => {
  return (
    <div className="error-box-container">
      {error.code ? (
        <>
          <div className="error-title">
            <h5>Hata Kodu</h5>
            <p>{error.code}</p>
          </div>
          <div className="error-message">
            <h5>Mesaj</h5>
            <code>{JSON.stringify(error.message)}</code>
          </div>
        </>
      ) : (
        <>
        <div className="error-message">
            <h5>Mesaj</h5>
            <code>{error}</code>
          </div>
        </>
      )}
    </div>
  );
};

export default ErrorComponent;
